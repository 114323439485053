import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import onMediaChanged from "@assets/js/on-media-changed";
import getViewportSize from "@assets/js/get-viewport-size";
import ViewportSizes from "@assets/js/viewport-sizes";
import { useLogoMustContract } from "@store/global";

import "./logo-animation.scss";

const LogoAnimation = ({ isInverted = false }) => {
  // const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const [logoMustContract] = useLogoMustContract();

  // ===== RESPONSIVE ===== //

  const [isMobile, setMobile] = useState(false);
  const [, setClosed] = useState(true);

  onMediaChanged((newViewport) => {
    switch (newViewport.name) {
      case ViewportSizes.SMALL.name:
      case ViewportSizes.MEDIUM.name:
        setMobile(true);
        break;
      case ViewportSizes.LARGE.name:
      case ViewportSizes.XLARGE.name:
      default:
        setMobile(false);
        break;
    }
  });

  useEffect(() => {
    const currentViewport = getViewportSize();
    setMobile(
      currentViewport.name === ViewportSizes.SMALL.name ||
        currentViewport.name === ViewportSizes.MEDIUM.name
    );
    onMediaChanged(currentViewport);
    const listen = history.listen((location, action) => {
      setClosed(true);
    });
    setClosed(false);
    return listen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let animationFactor = 0.5;

  let hansenVariant = {
    open: {
      width: "150px",
      paddingLeft: "16px",
      transition: { duration: animationFactor * 1, ease: [0.75, 0, 0.4, 1] },
    },
    closed: {
      width: "22px",
      paddingLeft: "6px",
      transition: { duration: animationFactor * 1.3, ease: [0.75, 0, 0.4, 1] },
    },
  };
  let fritzVariant = {
    open: { width: "92px", transition: { duration: animationFactor * 1.5 } },
    closed: {
      width: "20px",
      transition: { duration: animationFactor * 1.2 },
    },
  };

  let dotVariants = {
    open: { opacity: 0, transition: { duration: 0, delay: 0 } },
    closed: {
      opacity: 1,
      transition: {
        duration: animationFactor * 0.8,
        delay: animationFactor * 0.8,
      },
    },
  };

  return (
    <>
      <motion.div
        initial="closed"
        animate={!isMobile && logoMustContract ? "closed" : "open"}
        className="logo-animation"
      >
        <div className="logo-animation__outer">
          <div className="logo-animation__wrapper">
            <motion.div
              initial="open"
              variants={fritzVariant}
              className="logo-animation__fritz"
            >
              {/* <svg
                className="logo-animation__fritz-svg"
                viewBox="0 0 723 121"
                width="723"
                height="121"
                xmlns="http://www.w3.org/2000/svg">
                <defs><path id="a" d="M0 0h722v120.119H0z"/></defs>
                <g transform="translate(.5)" fill="none" fillRule="evenodd">

                  <path
                    className={`${isInverted ? "logo-animation--inverted" : ""} `}
                    d="M722 3.397v113.385h-27.92l-56.62-96.878h-1.08l.31 96.878h-17.28V3.397h27.93l56.61 96.879h1.08l-.31-96.879H722zm-129.15 97.649v15.736h-84.23V3.397h83.61v15.736h-65.87v31.778h60.16v15.118h-60.16v35.017h66.49zM380.236 85.469l17.739-6.633c3.085 14.656 12.495 25.917 36.716 25.917 21.129 0 30.699-7.712 30.699-17.74 0-31.007-80.837-4.327-80.837-53.074C384.553 13.42 403.992 0 431.452 0 462 0 478.04 16.044 481.59 34.402l-17.74 6.631c-3.09-14.5-11.42-25.607-32.709-25.607-18.973 0-28.538 7.714-28.538 17.74 0 31.008 80.837 4.327 80.837 53.076 0 20.517-19.44 33.937-49.06 33.937-34.553 0-50.597-16.042-54.144-34.71zM355.922 3.397v113.385h-27.923l-56.614-96.878h-1.08l.308 96.878h-17.278V3.397h27.923l56.614 96.879h1.081l-.31-96.879h17.279zM195.236 64.178l-17.895-47.206-18.05 47.206h35.945zm6.015 15.889h-47.976l-13.885 36.715h-18.048L165.924 3.397h23.448l44.584 113.385h-18.821l-13.884-36.715zm-99.226-76.67v113.385H84.284V66.647H18.413v50.135H.672V3.397h17.741v46.896h65.871V3.397h17.741z"/>
                </g>
              </svg> */}

              <svg
                className="logo-animation__fritz-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 471 120"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="M470.61 100.584v16.198h-89.937v-12.341l65.287-84.846h-63.745V3.397h86.852v12.342l-65.287 84.845zM367.556 19.596h-42.422v97.186h-17.741V19.596h-41.798V3.397h101.961zm-137.134 97.186h17.74V3.397h-17.74zm-70.658-62.014c15.272 0 23.139-5.863 23.139-17.895 0-12.187-7.867-18.05-23.139-18.05h-37.796v35.945zm43.039 62.014h-18.82l-3.703-25.299c-2.159-14.963-8.33-22.059-22.214-22.059h-36.098v47.358h-17.741V3.397h56.616c26.224 0 40.571 12.187 40.571 32.088 0 14.5-7.867 23.603-19.591 27.305 8.793 3.547 14.038 10.798 16.198 24.066zM17.68 19.441v32.395h60.164v15.426H17.68v49.52H-.06V3.397h83.612v16.044z"
                  fillRule="evenodd"
                />
              </svg>
            </motion.div>

            <motion.div
              initial="open"
              variants={hansenVariant}
              className={` logo-animation__hansen ${
                isMobile ? "padding-reset" : ""
              }`}
            >
              <svg
                className="logo-animation__hansen-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 723 120"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="M723 3.397v113.385h-27.92l-56.62-96.878h-1.08l.31 96.878h-17.28V3.397h27.93l56.61 96.879h1.08l-.31-96.879zm-129.15 97.649v15.736h-84.23V3.397h83.61v15.736h-65.87v31.778h60.16v15.118h-60.16v35.017zM381.236 85.469l17.739-6.633c3.085 14.656 12.495 25.917 36.716 25.917 21.129 0 30.699-7.712 30.699-17.74 0-31.007-80.837-4.327-80.837-53.074C385.553 13.42 404.992 0 432.452 0 463 0 479.04 16.044 482.59 34.402l-17.74 6.631c-3.09-14.5-11.42-25.607-32.709-25.607-18.973 0-28.538 7.714-28.538 17.74 0 31.008 80.837 4.327 80.837 53.076 0 20.517-19.44 33.937-49.06 33.937-34.553 0-50.597-16.042-54.144-34.71zM356.922 3.397v113.385h-27.923l-56.614-96.878h-1.08l.308 96.878h-17.278V3.397h27.923l56.614 96.879h1.081l-.31-96.879zM196.236 64.178l-17.895-47.206-18.05 47.206zm6.015 15.889h-47.976l-13.885 36.715h-18.048L166.924 3.397h23.448l44.584 113.385h-18.821zm-99.226-76.67v113.385H85.284V66.647H19.413v50.135H1.672V3.397h17.741v46.896h65.871V3.397z"
                  fillRule="evenodd"
                />
              </svg>
            </motion.div>

            <motion.div
              initial="open"
              variants={dotVariants}
              className="logo-animation__dot-a"
            >
              <svg
                className="logo-animation__dot-a-svg"
                viewBox="0 0 21 120"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="m0 96.33h20.17v20.17h-20.17z"
                  fillRule="evenodd"
                />
              </svg>
            </motion.div>
            <motion.div
              initial="open"
              variants={dotVariants}
              className="logo-animation__dot-b"
            >
              <svg
                className="logo-animation__dot-b-svg"
                viewBox="0 0 21 120"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="m0 96.33h20.17v20.17h-20.17z"
                  fillRule="evenodd"
                />
              </svg>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default LogoAnimation;
