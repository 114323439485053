const viewports = {
  SMALL: {
    id: 0,
    name: "SMALL",
    boundLow: 0,
    boundHigh: 559.999,
  },
  MEDIUM: {
    id: 1,
    name: "MEDIUM",
    boundLow: 560,
    boundHigh: 1023.999,
  },
  LARGE: {
    id: 2,
    name: "LARGE",
    boundLow: 1024,
    boundHigh: 1399.999,
  },
  XLARGE: {
    id: 3,
    name: "XLARGE",
    boundLow: 1400,
    boundHigh: 100000,
  },
};
export default viewports;
