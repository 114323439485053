import { INTERNATIONAL } from "@constants";

export default function getFormattedDeliveryTime(stockList, friendlyName, noDeliveryText, language, commerceMarket) {
    if (stockList && stockList[friendlyName]?.BestDeliveryDate) {
        return `${getFormattedDate(
            stockList[friendlyName].BestDeliveryDate,
            language,
            commerceMarket
        )} – ${getFormattedDate(
            stockList[friendlyName].LatestDeliveryDate,
            language,
            commerceMarket
            )}`;
    } else {
        return noDeliveryText;
    }
};

export const getFormattedDate = (isoDate, language, commerceMarket) => {
    let locale = commerceMarket?.toLowerCase() === INTERNATIONAL ? process.env.REACT_APP_COMMERCE_FALLBACK_LOCALE :`${language}-${commerceMarket?.toUpperCase()}`;
    let date = new Date(isoDate);
    let options = { year: "numeric", month: "numeric", day: "numeric"};
    return date.toLocaleDateString(
        locale,
        options
    );
};