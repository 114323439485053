export default {
  default: {
    fields: {
      hasArrow: {
        value: false,
      },
      isInverted: {
        value: false,
      },
      style: {
        value: "solid",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Lorem ipsum",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
  outlined: {
    fields: {
      hasArrow: {
        value: false,
      },
      isInverted: {
        value: false,
      },
      style: {
        value: "ghost",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Lorem ipsum",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
  inverted: {
    fields: {
      hasArrow: {
        value: false,
      },
      isInverted: {
        value: true,
      },
      style: {
        value: "solid",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Lorem ipsum",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
  outlinedInverted: {
    fields: {
      hasArrow: {
        value: false,
      },
      isInverted: {
        value: true,
      },
      style: {
        value: "ghost",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Lorem ipsum",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
  outlinedArrow: {
    fields: {
      hasArrow: {
        value: true,
      },
      isInverted: {
        value: false,
      },
      style: {
        value: "ghost",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Find store",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
  link: {
    fields: {
      hasArrow: {
        value: true,
      },
      isInverted: {
        value: false,
      },
      style: {
        value: "link",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Lorem ipsum",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
  linkInverted: {
    fields: {
      hasArrow: {
        value: true,
      },
      isInverted: {
        value: true,
      },
      style: {
        value: "link",
      },
      link: {
        value: {
          href: "#loremipsum",
          text: "Lorem ipsum",
          linktype: "external",
          anchor: "anchor",
          class: "class",
          title: "title",
          querystring: "querystring",
          id: "id",
        },
      },
    },
  },
};
