import React, { Fragment } from "react";
import PropTypes from "prop-types";
import shallow from "zustand/shallow";
import useNotificationStore from "@store/notifications";
import NotificationServiceMessage from "../../notifications/NotificationServiceMessage";
import NotificationItemAdded from "../../notifications/NotificationItemAdded";
import NotificationGeolocation from "../../notifications/NotificationGeolocation";
import NotificationSiteMode from "../../notifications/NotificationSiteMode";
import { loader } from "graphql.macro";
import { NOTIFICATION } from "@constants";
import { useQuery } from "react-apollo";
import { log } from "@assets/js/utils";

import "./notifications-controller.scss";

const gqlQuery = loader("./globalAlertQuery.graphql");

/**
 * GraphQL
 * text:String
 * autoRemove:Number (seconds)
 * PinAlert: Bool
 * textColor:String
 * backgroundColor:String
 * */

const NotificationsController = () => {
  const [activeNotifications, setActiveNotificationData] = useNotificationStore(
    (state) => [state.activeNotifications, state.setActiveNotificationData],
    shallow
  );

  // fetch global notifications
  useQuery(gqlQuery, {
    variables: {
      contextItem: process.env.REACT_APP_QUERY_VARIABLES_NOTIFICATIONS,
    },
    errorPolicy: "all",
    onCompleted: (response) => {
      if (response.item && response.item.globalAlertShowNotification.value) {
        setActiveNotificationData(response.item);
      }
    },
  });

  return (
    <>
      <div className="notifications-controller">
        {activeNotifications.map((notification) => {
          return (
            <Fragment key={notification.id}>
              {notification.type === NOTIFICATION.ITEM_ADDED && (
                <NotificationItemAdded {...notification} />
              )}
              {notification.type === NOTIFICATION.SERVICE_MESSAGE && (
                <NotificationServiceMessage {...notification} />
              )}
              {notification.type === NOTIFICATION.SITE_MODE && (
                <NotificationSiteMode />
              )}
              {/* {notification.type === NOTIFICATION.GEOLOCATION && (
              <NotificationGeolocation />
            )} */}
            </Fragment>
          );
        })}
        <NotificationGeolocation position={NOTIFICATION.GEO_TOP} />
      </div>
    </>
  );
};
NotificationsController.propTypes = {
  children: PropTypes.array,
};

export default NotificationsController;
