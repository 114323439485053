import create from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";
import { NOTIFICATION } from "@constants";

const store = create(
  devtools((set, get) => ({
    activeNotifications: [],
    setActiveNotification: (data) =>
      set((state) => ({
        activeNotifications: [...state.activeNotifications, data],
      })),
    setActiveNotificationData: (data) => {
      const an = get().activeNotifications;
      if (!an.includes(data.id)) {
        set((state) => ({
          activeNotifications: [
            ...state.activeNotifications,
            {
              bodyText: data.globalAlertMessage.value,
              textColor: data.globalAlertTextColor.value,
              id: data.id,
              backgroundColorHex: data.globalAlertBackgroundColor.value,
              timer: data.globalAlertDuration.value,
              type: NOTIFICATION.SERVICE_MESSAGE,
            },
          ],
        }));
      }
    },

    removeNotification: (id) =>
      set((state) => ({
        activeNotifications: produce(state.activeNotifications, (draft) => {
          const index = draft.findIndex((notification) => notification.id === id);
          if (index !== -1) draft.splice(index, 1);
        }),
      })),

    // currentLanguage: LANGUAGE.ENGLISH,
    // setCurrentLanguage: (value) => set({ currentLanguage: value }),
    // currentCountry: COUNTRY.DENMARK,
    // setCurrentCountry: (value) => set({ currentCountry: value }),
  }))
);

export const useNotification = () => [
  store((store) => store.activeNotifications),
  store((store) => store.setActiveNotificationData),
  store((store) => store.removeNotification),
];

export default store;
