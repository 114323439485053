import React, { useState, useEffect, useRef } from "react";
import useNotificationStore from "@store/notifications";
import { BUTTONSTYLE } from "@constants";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import ActionButton from "../../ActionButton";
import { opacityVariant } from "@assets/js/animation-variations";

import "./notification-item-added.scss";

const NotificationItemAdded = ({
  timer = 2000,
  backgroundColorHex,
  title,
  bodyText,
  bodyText2,
  buttonText,
  clickHandler,
  id,
}) => {
  const removeNotification = useNotificationStore(
    (store) => store.removeNotification
  );

  const [isRunning, setIsRunning] = useState(true);
  const timeout = useRef(null);
  useEffect(() => {
    if (timer) {
      timeout.current = setTimeout(() => {
        setIsRunning(false);
      }, timer);
      return () => clearTimeout(timeout.current);
    }
  }, [timer]);

  const notificationAnimationDone = () => {
    if (!isRunning) {
      removeNotification(id);
    }
  };

  const onClose = () => {
    removeNotification(id);
  };

  const onClick = () => {
    onClose();
    if (clickHandler) clickHandler();
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate={isRunning ? "visible" : "hidden"}
          onAnimationComplete={notificationAnimationDone}
          className="notification notification-item-added"
          variants={opacityVariant}
          style={
            backgroundColorHex && {
              "--notification-item-added-bgc": backgroundColorHex,
            }
          }
        >
          <ActionButton
            customClass="notification__close-icon"
            buttonType={BUTTONSTYLE.NONE}
            icon="TimesSvg"
            clickHandler={() => {
              onClose(false);
            }}
          />
          <div className="notification-item-added__inner-wrapper">
            <h3 className="notification-item-added__headline">{title}</h3>
            {bodyText && (
              <p className="notification-item-added__bodytext">{bodyText}</p>
            )}
            {bodyText2 && (
              <p className="notification-item-added__bodytext">{bodyText2}</p>
            )}
            {clickHandler && buttonText && (
              <ActionButton
                className="notification-item-added__button"
                clickHandler={onClick}
                buttonType={BUTTONSTYLE.GHOST}
                text={buttonText}
                icon="ArrowSmallSvg"
              />
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

NotificationItemAdded.propTypes = {
  timer: PropTypes.number,
  title: PropTypes.string,
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
  pinAlert: PropTypes.bool,
  backgroundColorHex: PropTypes.string,
  basketComp: PropTypes.bool,
  wishlistComp: PropTypes.bool,
};

export default NotificationItemAdded;
