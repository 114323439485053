import React from "react";
import "./icons.scss";

const checkmarkLarge = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--checkmark-large ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.556 7L8 22.556l-7-7" stroke="#000" fill="none" />
  </svg>
);
export default checkmarkLarge;
