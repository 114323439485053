import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useNotificationStore from "@store/notifications";
import useGlobalStore from "@store/global";
import ActionButton from "../../ActionButton";
import { motion, AnimatePresence } from "framer-motion";
import { BUTTONSTYLE, SITE_MODE } from "@constants";
import { collapseVariant } from "@assets/js/animation-variations";
import "../notification.scss";

const NotificationSiteMode = ({ id }) => {
  const { t } = useTranslation();

  const [setCookie] = useCookies(["FH_mode"]);
  const [active, setActive] = useState(false);

  const setCurrentSiteMode = useGlobalStore(
    (store) => store.setCurrentSiteMode
  );
  const removeNotification = useNotificationStore(
    (store) => store.removeNotification
  );

  const switchMode = (mode) => {
    setCurrentSiteMode(mode);
    setCookie("FH_mode", mode, { path: "/" });
  };

  const setProModeAndClose = () => {
    switchMode(SITE_MODE.PROFESSIONAL);
    setActive(false);
  };
  const setPriModeAndClose = () => {
    switchMode(SITE_MODE.RESIDENTIAL);
    setActive(false);
  };
  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial="hidden"
          variants={collapseVariant}
          animate={active ? "visible" : "hidden"}
          className="notification notification--geolocation"
          onAnimationComplete={() => {
            if (!active) removeNotification(id);
          }}
        >
          <div className="notification__inner-wrapper">
            <h2 className="notification__headline">
              {t(
                "FritzHansen_Project_ConsumersAndProfessionals_Notifications_StateMessageHeadline"
              )}
            </h2>

            <p className="notification__bodytext">
              {t(
                "FritzHansen_Project_ConsumersAndProfessionals_Notifications_StateMessageBody"
              )}
            </p>

            <div className="notification__form-wrapper">
              <ActionButton
                customClass="notification-site-mode__button"
                buttonType={BUTTONSTYLE.SOLID}
                text={t(
                  "FritzHansen_Project_ConsumersAndProfessionals_Notifications_StatePrivateButton"
                )}
                isInverted={true}
                hasArrow={false}
                clickHandler={setPriModeAndClose}
              />
              <ActionButton
                customClass="notification-site-mode__button"
                buttonType={BUTTONSTYLE.SOLID}
                text={t(
                  "FritzHansen_Project_ConsumersAndProfessionals_Notifications_StateProfessionalButton"
                )}
                isInverted={true}
                hasArrow={false}
                clickHandler={setProModeAndClose}
              />
            </div>
          </div>
          <ActionButton
            buttonType={BUTTONSTYLE.NONE}
            icon="TimesSvg"
            customClass="notification__close-icon"
            clickHandler={() => setActive(false)}
          />
        </motion.div>
      </AnimatePresence>
    </>
  );
};
NotificationSiteMode.propTypes = {};

export default NotificationSiteMode;
