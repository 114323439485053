import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import shallow from "zustand/shallow";

import Collapsible from "../Collapsible";
import Checkbox from "../forms/Checkbox";
import ActionButton from "../ActionButton";

import urlParams from "@assets/js/url-params";
import { BUTTONSTYLE } from "@constants";
import { ChevronSvg, PlusSvg, MagnifierSvg } from "@assets/js/icons";

import usePLPStore, { useFacetList, useSortingCriteria, useGetProducts } from "@store/plp";
import useHeaderStore from "@store/header";
import { OVERLAY, DATALAYER_EVENTS } from "@constants";
import { log } from "@assets/js/utils";
import dataLayerPush from "@assets/js/gtm";

import "./product-filters-list.scss";

const ProductFiltersList = (props) => {

  const { t } = useTranslation();
  const texts = {
    clear: t("FritzHansen_Project_ConsumersAndProfessionals_PLP_ClearFilter"),
    see: t("FritzHansen_Project_ConsumersAndProfessionals_PLP_SeeFilter"),
    filterBy: t("FritzHansen_Project_ConsumersAndProfessionals_PLP_FilterBy")
  };

  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);
  const activeOverlay = useHeaderStore((store) => store.menuOverlayActive);
  const setMobileNavigationActive = useHeaderStore((store) => store.setMobileNavigationActive);
  
  const activeFacetId = usePLPStore((store) => store.activeFacetId, shallow);
  const currentFilters = usePLPStore((store) => store.currentFilters, shallow);
  const setCurrentFilters = usePLPStore((store) => store.setCurrentFilters, shallow);
  const setActiveFacetId = usePLPStore((store) => store.setActiveFacetId, shallow);
  const clearSearch = usePLPStore((store) => store.clearSearch, shallow);
  
  const [facetList] = useFacetList();
  const [,] = useSortingCriteria();
  const history = useHistory();

  const [count, getAppliedFilters] = usePLPStore(
    (state) => [state.count, state.getAppliedFilters],
    shallow
  );

  const { refreshProductList } = useGetProducts();

  const submitHandler = () => { 
    refreshProductList(true);
    setMenuOverlayActive(OVERLAY.NONE);
    setActiveFacetId(null);

    //remove page-queryparam
    urlParams.set({ page: null });

    log("currentFilters", currentFilters);

    let obj = {
      event: DATALAYER_EVENTS.FILTER,
      filterType: currentFilters["ModelTypeLoungeType_ss"]?.join(', ') || "",
      filterProductSeries: currentFilters["ModelTypeProductSeriesName_s"]?.join(', ') || "",
      filterDesigners: currentFilters["DesignerName_s"]?.join(', ') || ""
    }

    log("productFiltersList.submitHandler", obj);
    // Tracking
    dataLayerPush(obj);

    
    let paramObj = {};
    for (let [key, value] of Object.entries(currentFilters)) {
      paramObj[key] = value.join(";");
    }
    urlParams.set(Object.keys(paramObj).length ? paramObj : null);
  };

  const clearHandler = () => {
    // let newFacets = filters;
    //setFacetList(newFacets);
    // TODO: clean url
    setMenuOverlayActive(OVERLAY.NONE);
    clearSearch();
    setActiveFacetId(null);
    urlParams.set(null);
  };

  const activateSearchHandler = () => {  
    if (props?.menu?.mobile?.searchIconLink?.jss?.value?.href) {
      setMenuOverlayActive(OVERLAY.NONE);
      setMobileNavigationActive(false);
      history.push(props?.menu?.mobile?.searchIconLink?.jss?.value?.href);
    }
  };

  const onFacetFieldClick = ({ checked, facetFieldId, facetId }) => {
    setCurrentFilters({ facetFieldId, facetId }, checked);
    getAppliedFilters();
  };

  return (
    <div className="product-filters-list">
      <div className="product-filters-list__header">
        {props?.menu?.mobile?.searchIconLink?.jss?.value?.href && <span className="product-filters-list__header__search-icon" onClick={activateSearchHandler}>
          <MagnifierSvg width={15} height={15} />
        </span>}
        <h3>{texts.filterBy}</h3>
        <span className="product-filters-list__header__close-icon" onClick={clearHandler}>
          <PlusSvg width={13} height={13}/>
        </span>
      </div>
      <div className="product-filters-list__inner">
        <div className="product-filters-list__facets">
          {activeOverlay === OVERLAY.FILTERS &&
            facetList && facetList?.length && facetList?.map((facet, index) => {
              return (
                <Collapsible
                  key={facet.facetId}
                  preExpanded={facet.facetId === activeFacetId}
                  triggerComponent={
                    <>
                      <h3>{facet.name}</h3>
                      <ChevronSvg width={16} height={13} />
                    </>
                  }
                  contentComponent={
                    <div>
                      {facet?.facetFields?.map((facetField) => {
                        return (
                          <Checkbox
                            key={facetField.id}
                            id={facetField.id}
                            value={facetField.id}
                            name={facetField.name}
                            label={`${facetField.name}`}
                            checked={facetField.active}
                            labelLeft={false}
                            // disabled={facetField.count === 0}
                            changeHandler={(value) =>
                              onFacetFieldClick({
                                checked: value,
                                facetFieldId: facetField.id,
                                facetId: facet.facetId,
                              })
                            }
                          />
                        );
                      })}
                    </div>
                  }
                />
              );
            })}
        </div>
        <div className="product-filters-list__footer">
          <ActionButton
            text={texts.clear}
            clickHandler={clearHandler}
            buttonType={BUTTONSTYLE.GHOST}
          />
          <ActionButton
            text={`${texts.see} (${count})`}
            clickHandler={submitHandler}
            hasArrow={true}
            buttonType={BUTTONSTYLE.SOLID}
            isInverted={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductFiltersList;
