import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { disableScroll, enableScroll } from "@assets/js/scrolllock";
import { placeholderReplace } from "@assets/js/utils";
import ModalOverlay from "../../ModalOverlay";
import ActionButton from "../../ActionButton";

import { BUTTONSTYLE } from "@constants";
import useBasketStore from "@store/basket";
import { useActiveUniqueMaterial } from "@store/pdp";

import "./validate-basket-modal.scss";

const ValidateBasketModal = (props) => {
    const { t } = useTranslation();
    const texts = {
        priceValidationFailedHeader: t(
          "FritzHansen_Project_ConsumersAndProfessionals_Cart_PriceValidationFailedHeader"
        ),
        priceValidationFailedBody: t(
          "FritzHansen_Project_ConsumersAndProfessionals_Cart_PriceValidationFailedBody"
        ),
        uniqueMaterialUnavailable: t(
            "FritzHansen_Project_ConsumersAndProfessionals_PDP_UniqueMaterialUnavailable"
          ),
        okButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_Ok"),
    };
    
    const [activeUniqueMaterial] = useActiveUniqueMaterial();

    const productsWithInvalidPrice = useBasketStore((store) => store.productsWithInvalidPrice);
    const setProductsWithInvalidPrice = useBasketStore((store) => store.setProductsWithInvalidPrice);

    const uniqueMaterialUnavailable = useBasketStore((store) => store.uniqueMaterialUnavailable);
    const setUniqueMaterialUnavailable = useBasketStore((store) => store.setUniqueMaterialUnavailable);

    const [modalActive, setModalActive] = useState(false);

    useEffect(() => {
        if ((productsWithInvalidPrice || uniqueMaterialUnavailable) && document) {
            setModalActive(true);
            disableScroll();
        }
    }, [productsWithInvalidPrice, uniqueMaterialUnavailable]);

    const getProductsWithInvalidPriceList = () => {
        if(productsWithInvalidPrice){
            return productsWithInvalidPrice.split(',');
        }
        return [];
    };

    const okButtonHandler = () => {
        setModalActive(false);
        if (uniqueMaterialUnavailable) {
            setUniqueMaterialUnavailable(null);
        }
        if (productsWithInvalidPrice) {
            setProductsWithInvalidPrice(null);
        }
        enableScroll();
    };

    const uniqueMaterial = activeUniqueMaterial?.id ?? uniqueMaterialUnavailable;
    return (
        <ModalOverlay
            className="validate-basket-modal"
            openState={modalActive}
            closeHandler={okButtonHandler}
            content={
                <>
                    <h3>{texts.priceValidationFailedHeader}</h3>
                    {productsWithInvalidPrice && <>
                        <p>
                            {texts.priceValidationFailedBody}
                        </p>
                        <ul>
                            {getProductsWithInvalidPriceList().map(i => {
                                return <li>• {i}</li>
                            })}
                        </ul>
                    </>
                    }
                    {uniqueMaterialUnavailable && <p>
                        {placeholderReplace(texts.uniqueMaterialUnavailable, { uniqueMaterial })}
                    </p>}

                    <ActionButton
                        buttonType={BUTTONSTYLE.SOLID}
                        isInverted={true}
                        clickHandler={okButtonHandler}
                        text={texts.okButton}
                    />
                </>
            }
        />
    );
};
ValidateBasketModal.propTypes = {};

export default ValidateBasketModal;
