import React from "react";
import "./icons.scss";

const remove = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--remove ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x=".5"
      y=".5"
      width="16"
      height="16"
      rx="8"
      stroke="#000"
      fill="none"
    />
    <path stroke="#000" d="M12 8.5H5" />
  </svg>
);
export default remove;
