import { Cookies } from "react-cookie";
import { COOKIES } from "@constants";

/**
 *
 * @param {string} url link to loginservice
 * @param {string} title title of login window
 * @param {string} w of login window
 * @param {string} h of login window
 *
 */
function loginWindow({ url, title, w, h }) {
  return new Promise((resolve, reject) => {
    const cookies = new Cookies();

    if (!window || !document) return false;

    // Fixes dual-screen position
    let dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    let dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screen.top;

    let width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;

    let height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    let left = width / 2 - w / 2 + dualScreenLeft;

    let top = height / 2 - h / 2 + dualScreenTop;

    let newWindow = window.open(
      url,
      title,
      "titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no," +
        "width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );

    let intervalRef = setInterval(() => {
      if (newWindow && newWindow.closed) {
        clearInterval(intervalRef);
        const jwtCookie = cookies.get(COOKIES.jwt.name);
        if (jwtCookie) {
          resolve(jwtCookie);
        } else {
          reject("No jwt-cookie found");
        }
      }
    }, 100);
  });
}
export default loginWindow;
