import React, { Fragment, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import cuid from "cuid";
import SwiperCore, { Navigation } from "swiper";
import { enableScroll } from "@assets/js/scrolllock";
import { ArrowMediumSvg } from "@assets/js/icons";

import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";


import getImageSizes from "@assets/js/get-image-sizes";
import setImage from "@assets/js/set-image";

import { log } from "@assets/js/utils";

import { BUTTONSTYLE } from "@constants";

import {usePresentationContent} from "@store/presentation";
import isTrue from "@assets/js/is-true";

import './presentation-modal.scss';



SwiperCore.use([Navigation]);


const PresentationModal = () => {

  const autoPlayDuration = 10000;

  const carouselId = cuid();

  const [presentationContent,setPresentationContent] = usePresentationContent();

  const [slideCount, setSlideCount] = useState(0);
  const [modalActive, setModalActive] = useState(false);

  const swiperRef = useRef(null);
  const timeout = useRef(null);

  const [slideIndex,setSlideIndex] = useState(0);

  useEffect(() => {
    if (presentationContent) {
      setSlideCount(presentationContent.slides.length);
      setSlideIndex(0);
      setModalActive(true);
    }
  }, [presentationContent]);

  
  const closePresentation = () => {
    try { clearTimeout(timeout.current); } 
    catch (err) {}
    setSlideIndex(0);
    setPresentationContent(null);    
    setModalActive(false);
    enableScroll();
    document.body.style.overflow = 'auto';
  };
  
  
  const parseImageSrc = (item) => {
    const sizes = getImageSizes(item?.fields?.image);
    if (!item.fields) return "";
    return setImage(item.fields.image?.value.src, {
      width: sizes.width,
      height: sizes.height,
      src: item.fields.image?.value.src,
    });
  };
    
  const swiperInitHandler = spr => {
    log("PRES | SWIPER READY", spr)
    startAutoPlay(1);
  }
  
  const slideChangeHandler = event => {
    try { clearTimeout(timeout.current); } 
    catch (err) {}
    setSlideIndex(event.realIndex);
    
    log("PRES | CHANGE TO " + event.realIndex)
    startAutoPlay(event.realIndex+1);
  }
    
  const startAutoPlay = (nextSlideIndex) => {    
    try { clearTimeout(timeout.current); } 
    catch (err) {}
    
    log("PRES | TIMEOUT START | next="+nextSlideIndex)
    timeout.current = setTimeout(()=>autoPlayTimeout(nextSlideIndex), autoPlayDuration);
  }
  
  const autoPlayTimeout = (nextSlideIndex) => {
    clearTimeout(timeout.current);
    log("PRES | TIMEOUT DONE | next="+nextSlideIndex+" of "+slideCount)
    
    if ( nextSlideIndex <= slideCount - 1 && swiperRef) {
      swiperRef.current.swiper.slideTo(nextSlideIndex);
    }
  }


  return (
    <ModalOverlay
      className="presentation-modal"
      openState={modalActive}
      closeHandler={closePresentation}
      content={
        <>      

          <ActionButton
            customClass="presentation-modal__close"
            buttonType={BUTTONSTYLE.NONE}
            icon="TimesSvg"
            clickHandler={closePresentation}
          />

          {presentationContent && (
            <>
              <div className="carousel__nav">
                <button className="carousel__prev" id={`${carouselId}SliderPrev`}>
                  <ArrowMediumSvg />
                </button>
                <button className="carousel__next" id={`${carouselId}SliderNext`}>
                  <ArrowMediumSvg />
                </button>
              </div>
              
              {/* {presentationContent.headline && <h1 className="presentation-modal__headline">
                <span>
                {presentationContent.headline && presentationContent.headline.split('').map((letter,index)=>{
                  return (
                    <em key={`pCHLetter${index}`}>{letter}</em>
                  );
                })}
                  {isTrue(presentationContent.isDecade) && <strong>&rsquo;</strong>}
                </span>
              </h1>} */}

              <div className="presentation-modal__auto-gallery">
                <ul className="presentation-modal__progress">
                  {presentationContent.slides.map((slide, index) => {
                    return (
                      <li key={`PresProgressFrag${index}`} className={slideIndex === index ? 'is-active' : slideIndex >= index ? 'is-complete' : ''}>
                        <span></span>
                      </li>);
                  })}
                </ul>
                {modalActive && <div className="presentation-modal__slider">
                  <Swiper
                    ref={swiperRef}
                    freeMode={false}
                    slidesPerView={1}
                    spaceBetween={10}
                    allowTouchMove={false}
                    onSwiper={swiperInitHandler}
                    onSlideChange={slideChangeHandler}
                    navigation={{
                      nextEl: `#${carouselId}SliderNext`,
                      prevEl: `#${carouselId}SliderPrev`,
                      disabledClass: "is-disabled",
                    }}
                    >
                    <>
                    {presentationContent.slides.map((slide, index) => {
                      return (
                        <Fragment key={`PresSlideFrag${index}`}>
                          <SwiperSlide
                            key={`PresSlide${index}`}
                            className={`presentation-modal__slide ${isTrue(slide?.fields?.isInverted) ? 'is-inverted' : ''}`}
                          >
        
                            <div className="presentation-modal__slide__media">
                              <img src={parseImageSrc(slide?.fields?.media)} alt={slide?.fields?.title.value}/>
                            </div>
                            
                            <div className={`presentation-modal__slide__text presentation-modal__slide__text--position-${slide?.fields?.textPosition.value}`}>
                              <div>
                                <Text tag="h5" field={slide?.fields?.title} />
                                <Text tag="p" field={slide?.fields?.description} />
                              </div>
                            </div>
                            
                          </SwiperSlide>
                      </Fragment>);
                    })}
                    </>
                  </Swiper>
                </div>}

              </div>
            </>
          )} 
        </>}
    />
  );
}

export default PresentationModal;
