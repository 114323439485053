import React from "react";
import "./icons.scss";

const png = ({ className, isInverted }) => (

    <svg
        className={`svg-icon svg-icon--pdf ${className || ""} ${isInverted ? "is-inverted" : ""
            }`}

        viewBox="-300 -200 1250 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
    <g>
	<g>
		<path d="M151.951,414.103c-5.677,0-9.526,0.554-11.541,1.107v36.492c2.381,0.554,5.313,0.732,9.352,0.732
			c14.851,0,24.026-7.524,24.026-20.171C173.794,420.884,165.905,414.103,151.951,414.103z"/>
		<polygon points="353.668,141.89 353.711,141.906 353.711,141.89 		"/>
		<path d="M475.095,131.992c-0.032-2.526-0.844-5.021-2.568-6.993L366.324,3.694c-0.021-0.034-0.053-0.045-0.084-0.076
			c-0.633-0.707-1.36-1.29-2.141-1.804c-0.232-0.15-0.465-0.285-0.707-0.422c-0.686-0.366-1.393-0.67-2.131-0.892
			c-0.2-0.058-0.379-0.14-0.59-0.192C359.87,0.114,359.037,0,358.203,0H97.2C85.292,0,75.6,9.693,75.6,21.601v507.6
			c0,11.913,9.692,21.601,21.6,21.601H453.6c11.908,0,21.601-9.688,21.601-21.601V133.202
			C475.2,132.796,475.137,132.398,475.095,131.992z M190.107,460.867c-9.537,8.986-23.657,13.025-40.165,13.025
			c-3.673,0-6.982-0.189-9.537-0.554v44.196h-27.696V395.582c8.617-1.466,20.725-2.568,37.779-2.568
			c17.23,0,29.531,3.296,37.776,9.903c7.881,6.229,13.204,16.501,13.204,28.608C201.479,443.623,197.445,453.906,190.107,460.867z
			 M321.616,517.535h-29.341l-26.412-47.682c-7.349-13.205-15.412-29.152-21.477-43.644l-0.538,0.18
			c0.738,16.326,1.094,33.744,1.094,53.915v37.23h-25.671V393.932h32.643l25.684,45.293c7.325,13.025,14.671,28.424,20.161,42.367
			h0.554c-1.841-16.321-2.384-33.012-2.384-51.532v-36.123h25.687V517.535L321.616,517.535z M449.086,511.851
			c-8.617,2.933-24.943,6.972-41.27,6.972c-22.55,0-38.887-5.685-50.251-16.695c-11.364-10.632-17.613-26.769-17.429-44.925
			c0.18-41.075,30.08-64.552,70.601-64.552c15.958,0,28.255,3.111,34.304,6.048l-5.864,22.376
			c-6.797-2.933-15.229-5.316-28.803-5.316c-23.282,0-40.891,13.205-40.891,39.973c0,25.497,15.957,40.532,38.887,40.532
			c6.417,0,11.554-0.728,13.753-1.835v-25.861h-19.074v-21.821h46.032v65.105H449.086z M97.2,366.752V21.601h250.203v77.69h6.75
			v42.599h42.156v1.026h57.29l0.011,223.836H97.2z"/>
		<polygon points="184.328,269.687 226.178,269.687 226.178,227.093 184.022,227.093 184.022,184.491 226.178,184.491 
			226.178,141.89 184.022,141.89 184.022,99.291 141.42,99.291 141.42,141.89 183.579,141.89 183.579,184.491 141.42,184.491 
			141.42,227.093 183.579,227.093 183.579,269.687 141.739,269.687 141.739,312.293 183.898,312.293 183.898,354.887 
			226.481,354.887 226.481,312.293 184.328,312.293 		"/>
		<polygon points="269.401,269.687 311.249,269.687 311.249,227.093 269.093,227.093 269.093,184.491 311.249,184.491 
			311.249,141.89 269.093,141.89 269.093,99.291 226.481,99.291 226.481,141.89 268.64,141.89 268.64,184.491 226.481,184.491 
			226.481,227.093 268.64,227.093 268.64,269.687 226.8,269.687 226.8,312.293 268.958,312.293 268.958,354.887 311.555,354.887 
			311.555,312.293 269.401,312.293 		"/>
		<path d="M311.555,141.89h42.113c-3.691-1.716-6.265-5.432-6.265-9.774V99.291h-35.849V141.89z"/>
		<path d="M353.711,141.906v42.585h-42.156v42.602h42.156v42.594H311.86v42.602h42.167v42.599h42.593v-42.599h-42.15v-42.602h41.84
			v-42.594h-42.156v-42.602h42.156v-41.575h-38.106C356.59,142.916,355.082,142.539,353.711,141.906z"/>
	</g>
</g>
    </svg>
);
export default png;
