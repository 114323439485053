import { CUSTOM_PROPS } from "@constants";

let bodyFixed = false;
/**
 * Disables the ability to scroll in the body element
 * @public
 */
export const disableScroll = () => {
  if (!bodyFixed) {
    const root = document.querySelector(":root");
    const body = document.body;
    const scrollY = body.style.getPropertyValue(CUSTOM_PROPS.SCROLL_Y);
    body.style.top = `-${parseInt(scrollY)}px`;
    root.classList.add("is-fixed");
    bodyFixed = true;
  }
};
/**
 * Enables the ability to scroll in the body element
 * @public
 */
export const enableScroll = () => {
  const root = document.querySelector(":root");
  const body = document.body;
  const scrollY = body.style.top;
  if (bodyFixed) {
    root.classList.remove("is-fixed");
    body.style.top = "";
    window.scrollTo(0, parseInt(scrollY.replace("px", "") || "0") * -1);
    bodyFixed = false;
  }
};

const index = {
  disableScroll,
  enableScroll,
  bodyFixed,
};
export default index;
