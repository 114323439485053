import React from "react";
import "./icons.scss";

const play = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--play ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="29" 
    height="35" 
    viewBox="0 0 29 35" 
    xmlns="http://www.w3.org/2000/svg">
      <path d="M28.726 17.5605L0.797576 0.667268V34.4538L28.726 17.5605Z" fill="white"/>
  </svg>
);
export default play;
