import cuid from "cuid";
import { NOTIFICATION } from "@constants";
import notificationsStore from "@store/notifications";

/*
To be used in axios service calls. Three applications:
1. axios. [...method etc.] .catch(errorHandler);
2. axios. [...method etc.] .catch((error) => errorHandler(error, "ERROR ORIGIN TEXT"));

Example:
const response = await axios
    .get(
        `${path.join(
            process.env.REACT_APP_ROOTAPI,
            "basketapi/get/",
            basketId ? basketId : get().basketId
        )}`
    ).catch((error) => errorHandler(error, "BASKET GET"));


Can also be used outside axios in generic errors:
errorHandler("Error message", "Error origin");

If no response is found on error, then the error is regareded as non-server related and has a pink notification, otherwise the notification is yellow.
*/

function globalErrorHandler(error, origin) {
  const { setActiveNotification } = notificationsStore.getState();

  let description = error?.response?.status
    ? "HTTP " + error.response.status + ": "
    : "";
  description +=
    error?.response?.data?.ExceptionMessage ||
    error?.response?.data?.ExceptionType ||
    error?.response?.data?.Message ||
    error?.response?.data?.error ||
    error?.response?.statusText ||
    error?.response ||
    error ||
    "NO DESCRIPTION";

  let headline = error?.response ? "SERVER ERROR" : "GENERAL ERROR";

  const bodyText = `${origin ? origin + " | " : ""}${headline}\n${description}`;
  const backgroundColor = error?.response ? "#F19B1F" : "#FF8D8D";
  setActiveNotification({
    id: cuid(),
    bodyText: bodyText,
    backgroundColorHex: backgroundColor,
    textColor: "#000000",
    type: NOTIFICATION.SERVICE_MESSAGE,
    timer: 3000,
  });
}

export default globalErrorHandler;
