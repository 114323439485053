import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enableScroll, disableScroll } from "@assets/js/scrolllock";
import ModalOverlay from "../ModalOverlay";
import { log } from "@assets/js/utils"
import path from "@assets/js/browser-path";
import useContentStore from "@store/content";
import usePDPStore from "@store/pdp";

import "./qrcode-modal.scss";

const QRCodeModal = (props) => {
    const { t } = useTranslation();
    const texts = {
      headline: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_WebArModalHeadline"),
      body: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_WebArModalText"),
    };
    const [showQRCodeModal, setShowQRCodeModal] = useContentStore(
        (store) => [store.showQRCodeModal, store.setShowQRCodeModal, store.qrCodeConfigurationId, store.setQrCodeConfigurationId]
    );

    const activeConfigurationSKU = usePDPStore(
        (state) => state.activeConfigurationSKU
    );

    const [modalActive, setModalActive] = useState(false);

    const okButtonHandler = () => {
        setShowQRCodeModal(false);
        setModalActive(false);
        enableScroll();
    };
    
    const qrCodeUrl = activeConfigurationSKU ? path.join(process.env.REACT_APP_PRODUCTSAPI, "getQrCodeForAr", activeConfigurationSKU.split('_')[0], "5") : "";
    
    useEffect(() => {
        if (qrCodeUrl && showQRCodeModal && activeConfigurationSKU && document) {
            setModalActive(true);
            disableScroll();
        }
    }, [showQRCodeModal, activeConfigurationSKU]);
    
    return (
        <ModalOverlay
            className="qrcode-modal"
            openState={modalActive}
            closeHandler={okButtonHandler}
            content={
                <>
                <h3>{texts.headline}</h3>
                <p className="text">{texts.body}</p>
                <p className="qrcode"><img src={qrCodeUrl} /></p>
                </>
                
            }
        />
    );
};

export default QRCodeModal;
