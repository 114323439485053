import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";
import path from "@assets/js/browser-path";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";

import SearchForm from "../SearchForm";
import ProductPrice from "../../ProductPrice";
import ProductImage from "../../ProductImage";
import TagListButton from "../../TagListButton";

import MenuLink from "../../SiteHeader/MenuLink";

import useGlobalStore, { useCommerceMarket } from "@store/global";

import { SITE_MODE, DATALAYER_EVENTS, QUERY_PARAMETER, OVERLAY } from "@constants";

import { log } from "@assets/js/utils";
import dataLayerPush from "@assets/js/gtm";
import urlParams from "@assets/js/url-params";

import "./site-search.scss";

const navLinksQuery = loader("./navLinksQuery.graphql");

const SiteSearchController = ({ contentRef }) => {
  const { t } = useTranslation();
  const texts = {
    products: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Products"
    ),
    pages: t("FritzHansen_Project_ConsumersAndProfessionals_General_Pages"),
    lookingFor: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Search_YouMightBeLookingFor"
    ),
  };

  const CONTENT_FILTER = {
    PRODUCTS: "Products",
    PAGES: "Pages"
  }

  const mode = useGlobalStore((state) => state.currentSiteMode);
  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;
  const { data } = useQuery(navLinksQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [searchTerm, setSearchTerm] = useState(null);

  const [searchResultsData, setSearchResultsData] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [contentFilter, setContentFilter] = useState(null);

  const [commerceMarket] = useCommerceMarket();

  const fetchResults = (searchTerm, lang) => {
    //Check if there are any previous pending requests
    if (cancelToken != null) {
      cancelToken.cancel("Operation canceled due to new request.")
    }

    //Save the cancel token for the current request
    const newToken = axios.CancelToken.source()
    setCancelToken(newToken)

    return axios.get(
      path.join(
        process.env.REACT_APP_PRODUCTSAPI,
        "Search",
        lang,
        "cp",
        "suggest2",
        `?query=${searchTerm}&market=${commerceMarket}`
      ), { cancelToken: newToken.token }
    ).catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        log(thrown.message);
      } else {
        // handle error
      }
    })
  };

  const filterChangeHandler = (value) => {
    if (!value) {
      setContentFilter(null);
    }
    else if (value === CONTENT_FILTER.PAGES) {
      if (contentFilter === CONTENT_FILTER.PAGES) {
        setContentFilter(null)
      }
      else {
        setContentFilter(CONTENT_FILTER.PAGES);
      }
    }
    else {
      if (contentFilter === CONTENT_FILTER.PRODUCTS) {
        setContentFilter(null)
      }
      else {
        setContentFilter(CONTENT_FILTER.PRODUCTS);
      }
    }
  }

  const changeHandler = (target) => {
    async function loadResults(term) {
      const response = await fetchResults(term, sc_lang);

      if (response) {
        setSearchResultsData(response.data);

        urlParams.set({ q: term });
        let count = 0;
        if (response.data?.products) {
          count += response.data.products.count;
        }
        if (response.data?.pages) {
          count += response.data.pages.count;
        }
        let obj = {
          event: DATALAYER_EVENTS.SEARCH,
          searchResult: (response.data?.products?.count > 1 || response.data?.pages?.count > 1) ? "has result" : "has no result",
          searchTerm: term,
          searchResultNumber: count
        }
        log("SiteSearchController.changeHandler", obj);
        dataLayerPush(obj);
      }
    }

    let newVal = target.value;
    setSearchTerm(newVal);

    if (newVal.length > 1) {
      loadResults(newVal);
    } else {
      setSearchResultsData(null);
      urlParams.set({ q: null });
    }
  };

  // useEffect(() => {
  //   var queryParam = urlParams.get().get(QUERY_PARAMETER.QUERY);
  //   if (queryParam) {
  //     changeHandler({ value: queryParam });
  //   }
  // }, []);

  return (
    <div className="site-search">
      <div className="site-search__form">
        <SearchForm onChange={changeHandler} value={searchTerm} />
      </div>
      {searchResultsData && (
        <>
          <div className="site-search__filter-list">
            <ul>
              <li className="site-search__filter-item">
                <TagListButton
                  fields={{ id: CONTENT_FILTER.PRODUCTS, title: { value: CONTENT_FILTER.PRODUCTS }, active: contentFilter === CONTENT_FILTER.PRODUCTS }}
                  tagUpdateHandler={(tagId) => filterChangeHandler(tagId)}
                />
              </li>
              <li className="site-search__filter-item">
                <TagListButton
                  fields={{ id: CONTENT_FILTER.PAGES, title: { value: CONTENT_FILTER.PAGES }, active: contentFilter === CONTENT_FILTER.PAGES }}
                  tagUpdateHandler={(tagId) => filterChangeHandler(tagId)}
                />
              </li>
            </ul>
          </div>
          {contentFilter !== CONTENT_FILTER.PAGES && searchResultsData.products && (
            <div className="site-search__list">
              <h3 className="site-search__list__title">
                {searchResultsData.products.count} {texts.products}
              </h3>
              <ul>
                {searchResultsData.products.list.map((item, index) => {
                  return (
                    <SiteSearchProductTile
                      key={`siteSearchProductTile${item.url || index}`}
                      data={item}
                    />
                  );
                })}
              </ul>
            </div>
          )}

          {contentFilter !== CONTENT_FILTER.PRODUCTS && searchResultsData.pages && (
            <div className="site-search__list">
              <h3 className="site-search__list__title">
                {searchResultsData.pages.count} {texts.pages}
              </h3>
              <ul>
                {searchResultsData.pages.list.map((item, index) => {
                  return (
                    <SiteSearchPageTile
                      key={`siteSearchPageTile${index}${item.headline}`}
                      data={item}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </>
      )}

      <motion.div
        className="site-search__links"
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -30 }}
      >
        {data && (
          <div className="mega-menu__list">
            <span className="site-search__links-title">
              {data.menu?.title?.value}
            </span>
            <ul>
              {data.menu.navLinks &&
                data.menu.navLinks.targetItems.map((item) => {
                  return (
                    <li key={item.id}>
                      <div className="mega-menu__list-item">
                        <MenuLink
                          jss={item.link}
                          className="mega-menu__button"
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </motion.div>
    </div>
  );
};

const SiteSearchProductTile = ({ data }) => {
  return (
    <li className="site-search-tile site-search-tile--product">
      <a href={data.url} data-imageid={data.imageId}>
        <div className="site-search-tile__image">
          <ProductImage
            imageUrl={data.imageUrl}
            alt={data.displayName}
            imageId={data.imageId || ""}
            visibleByDefault={true}
          />
        </div>
        <div className="site-search-tile__text">
          <h4>{data.displayName}</h4>

          {data.description && <p>{data.description}</p>}
          {data.description1 && <p>{data.description1}</p>}
          {data.description2 && <p>{data.description2}</p>}

          {data.modelTypeId_s && (
            <ProductPrice modelTypeId={data.modelTypeId_s} />
          )}
        </div>
      </a>
    </li>
  );
};

const SiteSearchPageTile = ({ data }) => {
  return (
    <li className="site-search-tile site-search-tile--page">
      <a href={data.url}>
        {data.imageUrl && (
          <div className="site-search-tile__image">
            <img src={data.imageUrl} alt={data.headline} />
          </div>
        )}
        <div className="site-search-tile__text">
          <h4>{data.headline ? data.headline : data.url}</h4>
          <p>{data.description}</p>
        </div>
      </a>
    </li>
  );
};

SiteSearchController.propTypes = {};

export default SiteSearchController;
