import create from "zustand";
import { devtools } from "zustand/middleware";

const store = create(
  devtools((set) => ({
    presentationContent: null,
    setPresentationContent: (value) => set({ presentationContent: value }),        
  }), "Presentation"
));


export const usePresentationContent = () => [
    store((store) => store.presentationContent),
    store((store) => store.setPresentationContent),
];


export default store;
