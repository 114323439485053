import React from "react";
import { PlusSvg } from "@assets/js/icons";
import ProductPrice from "../../ProductPrice";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import "./mini-basket-addon.scss";

const MiniBasketAddon = ({
  Title,
  ImageUrl,
  Price, 
  PriceCurrency,
  VisualSize,
  addHandler = null
}) => {
  const { t } = useTranslation();
  const texts = {
    addToBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddToBasket"
    )
  }

  const productImageClassList = classnames({
    "addon-product-image": true,
    [`addon-product-image--${VisualSize?.toLowerCase()}`]: VisualSize
  });

  return (
    <div className="mini-basket-addon">
      {ImageUrl && <span className="mini-basket-addon__image">
        <div className={productImageClassList}>
          <img src={ImageUrl} alt={Title} />
        </div>
      </span>}

      <div className="mini-basket-addon__text">
        <h4>{Title}</h4>
        <h5><ProductPrice price={Price} priceCurrency={PriceCurrency} /></h5>
      </div>

      <button
        className="mini-basket-addon__button"
        onClick={() => { if (addHandler) addHandler() }}
      >
        <span>
          <PlusSvg />
        </span>
        <span>{texts.addToBasket}</span>
      </button>
    </div>
  );
};

export default MiniBasketAddon;
