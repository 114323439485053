import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import cuid from "cuid";
import axios from "axios";

import { OVERLAY } from "@constants";
import path from "@assets/js/browser-path";

import useGlobalStore from "@store/global";
import useHeaderStore from "@store/header";

import { ArrowSmallSvg, BoxSvg } from "@assets/js/icons";
import { useSubmissionContent } from "@store/portfolio";

import ProductPrice from "../ProductPrice";

import "./media-info-overlay.scss";
import ProductImage from "../ProductImage";

const fetchProducts = (productIds, lang) =>
  axios.get(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "Configuration",
      lang,
      "solrformat",
      "get",
      productIds
    )
  );

const MediaInfoOverlay = (props) => {
  const { t } = useTranslation();

  const texts = {
    saveImage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_SaveImage"
    ),
    downloadImage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_DownloadImage"
    ),
    productsFromImage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_ProductsFromImage"
    ),
    invalidConfiguration: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_InvalidConfiguration"
    ),
  };
  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );
  const [submissionContent] = useSubmissionContent();

  const iconProject = <BoxSvg />;
  const iconDownload = <ArrowSmallSvg />;

  const [productData, setProductData] = useState(null);

  useEffect(() => {
    async function loadProducts() {
      const productIdsString =
        submissionContent.portfolioItem.imageProductIds.join("|");
      const response = await fetchProducts(productIdsString, sc_lang);
      let productList = [];

      response.data.forEach((item, index) => {
        if (!item || (!item.configurationId_s && !item.heroConfigurationId_s)) {
          productList.push({
            errorHeadline: `${texts.invalidConfiguration}:`,
            errorBodytext: `${submissionContent.portfolioItem.imageProductIds[index]}`,
          });
        } else {
          productList.push({
            configurationId:
              item.configurationId_s || item.heroConfigurationId_s,
            headline: item.modelGroupName_s || item.modelGroupId_s,
            description: item.materialSets_ss
              .map((item) => item.split("|")[0])
              .join("\n"),
            imageUrl: item.imageUrl_s,
            href: item.urlSlug_s,
          });
        }
      });

      setProductData(productList);
    }
    if (submissionContent?.portfolioItem?.imageProductIds) loadProducts();
  }, [sc_lang, submissionContent, texts.invalidConfiguration]);

  const saveToProjectHandler = () => {
    setMenuOverlayActive(OVERLAY.PORTFOLIO_SUBMISSION);
  };

  return (
    <div className="media-info-overlay">
      {submissionContent && (
        <Fragment key={submissionContent.id}>
          <div className="media-info-overlay__image">
            <LazyLoadImage
              placeholderSrc={submissionContent.imageSrcLow} // use normal <img> attributes as props
              src={submissionContent.imageSrcHigh} // use normal <img> attributes as props
              effect="blur"
            />
          </div>

          <div className="media-info-overlay__cta">
            <button
              onClick={saveToProjectHandler}
              className="media-info-overlay__button"
            >
              <span className="icon">{iconProject}</span>
              <span className="label">{texts.saveImage}</span>
            </button>

            <a
              href={submissionContent.downloadHref}
              target="_blank"
              className="media-info-overlay__button"
              rel="noreferrer"
            >
              <span className="icon">{iconDownload}</span>
              <span className="label">{texts.downloadImage}</span>
            </a>
          </div>

          {productData && (
            <div className="media-info-overlay__products">
              <h4>{texts.productsFromImage}</h4>
              {productData.map((item) => {
                return <MediaInfoOverlayProduct key={cuid()} {...item} />;
              })}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

const MediaInfoOverlayProduct = ({
  configurationId,
  imageUrl,
  description,
  headline,
  href,
  errorHeadline,
  errorBodytext,
}) => {
  const { t } = useTranslation();

  const texts = {
    seeProduct: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_SeeProduct"
    ),
  };
  return (
    <div
      className={`media-info-overlay-product ${
        !configurationId ? "has-error" : ""
      }`}
    >
      {configurationId ? (
        <>
          {imageUrl ? (
            <div className="media-info-overlay-product__image">
              <div className="product-image">
                <img src={imageUrl} alt={headline} />
              </div>
            </div>
          ) : (
            <div className="media-info-overlay-product__image">
              <ProductImage imageId={configurationId} />
            </div>
          )}

          <div className="media-info-overlay-product__text">
            {headline && <h3>{headline}</h3>}
            {description && <p>{description}</p>}
            <ProductPrice configurationId={configurationId} />

            {href && (
              <a className="nav-button is-link" href={href}>
                <span className="text">{texts.seeProduct}</span>
                <span className="icon">
                  <ArrowSmallSvg />
                </span>
              </a>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="error">
            <h5>{errorHeadline}</h5>
            <p>{errorBodytext}</p>
          </div>
        </>
      )}
    </div>
  );
};
MediaInfoOverlay.propTypes = {};

export default MediaInfoOverlay;
