import React, { useEffect, useState, useRef } from "react";
import ActionButton from "../../ActionButton";
import { BUTTONSTYLE } from "@constants";
import PropTypes from "prop-types";
import useNotificationStore from "@store/notifications";
import { motion, AnimatePresence } from "framer-motion";
import { collapseVariant } from "@assets/js/animation-variations";
import classnames from "classnames";

import "../notification.scss";

const NotificationServiceMessage = ({
  timer,
  pinAlert,
  backgroundColorHex,
  bodyText,
  textColor,
  id,
  linkText,
  clickHandler,
}) => {
  const removeNotification = useNotificationStore(
    (store) => store.removeNotification
  );

  const enableButton = linkText && clickHandler; 
  
  const classList = classnames({
    "notification__bodytext": true,
    "button-is-active": enableButton
  });
  
  const [isRunning, setIsRunning] = useState(true);
  
  let timeout = useRef(null);

  useEffect(() => {
    if (timer) {
      timeout.current = setTimeout(() => {
        setIsRunning(false);
      }, timer);
      return () => clearTimeout(timeout.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationAnimationDone = () => {
    if (!isRunning) {
      removeNotification(id);
    }
  };
  return (
    <AnimatePresence>
      <motion.div
        className="notification notification--service-message"
        style={{
          "--notification-bgc": backgroundColorHex,
          "--notification-c": textColor,
        }}
        variants={collapseVariant}
        animate={isRunning ? "visible" : "hidden"}
        onAnimationComplete={notificationAnimationDone}
      >
        <ActionButton
          buttonType={BUTTONSTYLE.NONE}
          icon="TimesSvg"
          clickHandler={() => setIsRunning(false)}
        />

        <div className={classList}>
          {bodyText}
          {/* {linkUrl && (
            <span>
              <a href={linkUrl} className="notifications-service-message__link">
                {linkText}
              </a>
            </span>
          )} */}
        </div>
        {enableButton && (
            <ActionButton
              customClass="notification__button"
              clickHandler={clickHandler}
              buttonType={BUTTONSTYLE.NONE}
              text={linkText}
              icon="ArrowSmallSvg"
            />
          )}
      </motion.div>
    </AnimatePresence>
  );
};

NotificationServiceMessage.propTypes = {
  // link: PropTypes.object,
  timer: PropTypes.number,
  pinAlert: PropTypes.bool,
  backgroundColorHex: PropTypes.string,
};

export default NotificationServiceMessage;
