import React from "react";
import "./icons.scss";

const doc = ({ className, isInverted }) => (

    <svg
        className={`svg-icon svg-icon--pdf ${className || ""} ${isInverted ? "is-inverted" : ""
            }`}

        viewBox="-300 -200 1250 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
    <g>
	<path d="M486.2,196.124h-13.164V132.59c0-0.396-0.064-0.795-0.116-1.196c-0.021-2.523-0.824-5-2.551-6.963L364.656,3.677
		c-0.031-0.031-0.064-0.042-0.085-0.075c-0.629-0.704-1.364-1.29-2.141-1.796c-0.231-0.154-0.462-0.283-0.704-0.418
		c-0.672-0.366-1.386-0.671-2.121-0.892c-0.199-0.055-0.377-0.134-0.576-0.188C358.229,0.118,357.4,0,356.562,0H96.757
		C84.893,0,75.256,9.649,75.256,21.502v174.616H62.093c-16.972,0-30.733,13.753-30.733,30.73v159.812
		c0,16.961,13.761,30.731,30.733,30.731h13.163V526.79c0,11.854,9.637,21.501,21.501,21.501h354.777
		c11.853,0,21.502-9.647,21.502-21.501V417.392H486.2c16.966,0,30.729-13.764,30.729-30.731V226.854
		C516.93,209.872,503.166,196.124,486.2,196.124z M96.757,21.502h249.053v110.006c0,5.943,4.818,10.751,10.751,10.751h94.973v53.864
		H96.757V21.502z M354.739,298.02c0,48.877-29.634,78.505-73.208,78.505c-44.229,0-70.106-33.392-70.106-75.849
		c0-44.677,28.528-78.069,72.537-78.069C329.736,222.607,354.739,256.88,354.739,298.02z M64.345,373.432V227.037
		c12.384-1.995,28.525-3.102,45.562-3.102c28.305,0,46.657,5.089,61.033,15.921c15.48,11.503,25.216,29.861,25.216,56.174
		c0,28.53-10.392,48.21-24.764,60.373c-15.704,13.05-39.591,19.238-68.786,19.238C85.125,375.642,72.746,374.536,64.345,373.432z
		 M451.534,520.962H96.757v-103.57h354.777V520.962z M453.16,348.447c10.174,0,21.455-2.223,28.085-4.867l5.093,26.315
		c-6.196,3.108-20.127,6.409-38.258,6.409c-51.528,0-78.069-32.063-78.069-74.526c0-50.853,36.267-79.171,81.375-79.171
		c17.47,0,30.751,3.538,36.726,6.638l-6.861,26.754c-6.851-2.872-16.362-5.531-28.309-5.531c-26.758,0-47.55,16.147-47.55,49.316
		C405.387,329.642,423.082,348.447,453.16,348.447z"/>
	<path d="M160.322,297.137c0.221-30.968-17.917-47.331-46.88-47.331c-7.52,0-12.396,0.661-15.265,1.329v97.532
		c2.868,0.665,7.52,0.665,11.724,0.665C140.417,349.548,160.322,332.739,160.322,297.137z"/>
	<path d="M247.032,300.004c0,29.202,13.714,49.765,36.269,49.765c22.782,0,35.827-21.68,35.827-50.646
		c0-26.768-12.824-49.758-36.048-49.758C260.311,249.371,247.032,271.043,247.032,300.004z"/>
</g>
    </svg>
);
export default doc;
