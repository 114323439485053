import React, { Fragment } from "react";
import PropTypes from "prop-types";
import shallow from "zustand/shallow";
import { useTranslation } from "react-i18next";
import getFormattedPrice from "@assets/js/get-formatted-price";

import useBasketStore from "@store/basket";
import useGlobalStore, { useCommerceMarket } from "@store/global";

import "./mini-basket-calculation.scss";

const MiniBasketCalculation = ({
  totalWithoutDiscount,
  discountTotal,
  subTotal,
  taxTotal,
  orderTotal,
  shippingTotal,
  voucherCode,
  currency = "DKK",
}) => {
  const { t } = useTranslation();

  const [commerceMarket] = useCommerceMarket();
  const [language] = useGlobalStore((state) => [state.language], shallow);

  const basketId = useBasketStore((store) => store.basketId);
  const lineItems = useBasketStore((store) => store.lineItems);
  const removeVoucher = useBasketStore((store) => store.removeVoucher);

  const texts = {
    totalWithoutDiscount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_TotalWithoutDiscount"
    ),
    total: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Total"),
    totalWithoutVat: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_TotalWithoutVat"),
    subtotal: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Subtotal"),
    vat: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Vat"),
    shipping: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Shipping"),
    voucherCode: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_VoucherCode"
    ),
    discountLine: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_DiscountLine"),
    items: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Items"),
    remove: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Remove"),
  };

  const quantitySum = lineItems.reduce((a, v) => (a = a + v.Quantity), 0);

  const removeVoucherHandler = () => {
    removeVoucher({ basketId: basketId, voucherCode: voucherCode });
  };

  return (
    <div className="mini-basket-calculation">
      <div className="mini-basket-calculation__row">
        <div>
          <h5>{texts.totalWithoutDiscount}</h5>
          <p>{`(${quantitySum} ${texts.items})`}</p>
        </div>
        <div>
          <h5>
            {getFormattedPrice(
              language,
              commerceMarket,
              currency,
              totalWithoutDiscount
            )}
          </h5>
        </div>
      </div>

      {discountTotal > 0 && (
        <>
          <div className="mini-basket-calculation__row">
            <div className="mini-basket-calculation__voucher">
              {voucherCode ? (<>
                <h5>{texts.voucherCode}</h5>
                <div className="mini-basket-calculation__voucher-info">
                  <span>({voucherCode})</span>
                  <button onClick={removeVoucherHandler}>{texts.remove} ⨉</button>
                </div>
              </>) : (
                <h5>{texts.discountLine}</h5>
              )}
            </div>
            <div>
              <h5>
                -{" "}
                {getFormattedPrice(
                  language,
                  commerceMarket,
                  currency,
                  discountTotal
                )}
              </h5>
            </div>
          </div>
          <div className="mini-basket-calculation__row">
            <div>
              <h5>{texts.subtotal}</h5>
            </div>
            <div>
              <h5>
                {getFormattedPrice(
                  language,
                  commerceMarket,
                  currency,
                  subTotal
                )}
              </h5>
            </div>
          </div>
        </>
      )}

      <div className="mini-basket-calculation__row">
        <div>
          <h5>{texts.shipping}</h5>
        </div>
        <div>
          <h5>
            {getFormattedPrice(
              language,
              commerceMarket,
              currency,
              shippingTotal
            )}
          </h5>
        </div>
      </div>

      <div className="mini-basket-calculation__row mini-basket-calculation__row--result">
        <div>
          <h5>{taxTotal > 0 ? texts.total : texts.totalWithoutVat}</h5>
          {/* Keep for later implemention<p>{fields.result.value.remark && fields.result.value.remark}</p> */}
        </div>

        <div>
          <h5>
            {getFormattedPrice(language, commerceMarket, currency, orderTotal)}
          </h5>
        </div>
      </div>

      <div className="mini-basket-calculation__row">
        <div>
          <h5>{texts.vat}</h5>
        </div>
        <div>
          <h5>
            {getFormattedPrice(language, commerceMarket, currency, taxTotal)}
          </h5>
        </div>
      </div>
    </div>
  );
};

MiniBasketCalculation.propTypes = {
  subTotal: PropTypes.number.isRequired,
  taxTotal: PropTypes.number.isRequired,
  discountTotal: PropTypes.number.isRequired,
  orderTotal: PropTypes.number.isRequired,
  shippingTotal: PropTypes.number.isRequired,
  currency: PropTypes.string,
};

export default MiniBasketCalculation;
