import axios from "axios";
import globalErrorHandler from "@assets/js/global-error-handler";
import globalStore from "@store/global";
import { log } from "@assets/js/utils";

async function refreshTokenHandler() {
    const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_ROOTAPI}account/getRefreshToken`,
    }).catch((error) => {
        globalStore.getState().setUserAuthenticated(false, null);
    });

    if (response) {
        if (response?.id === "RequestTokenExpiredUrl") {
            globalStore.getState().setUserAuthenticated(false, null);
        }
        else {
            globalStore.getState().setUserAuthenticated(true, response);
        }
    }
    else {
        globalStore.getState().setUserAuthenticated(false, null);
    }
}

export default refreshTokenHandler;
