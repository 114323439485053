import React from "react";
import "./icons.scss";
const globe = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--globe ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`} 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 16 16">
      <path d="M8 0c4.414 0 8 3.586 8 8 0 4.34-3.47 7.872-7.781 7.99a2.086 2.086 0 0 1-.438 0C3.47 15.872 0 12.34 0 8c0-4.414 3.586-8 8-8Zm.333.719v3.614h2.532a9.474 9.474 0 0 0-.573-1.614c-.55-1.15-1.246-1.834-1.959-2Zm-.666 0c-.713.166-1.409.85-1.959 2a9.471 9.471 0 0 0-.573 1.614h2.532V.72Zm2.208.198c.387.403.733.909 1.02 1.51.27.562.501 1.207.678 1.906h2.77A7.328 7.328 0 0 0 9.876.917Zm-3.75 0a7.328 7.328 0 0 0-4.469 3.416h2.771c.177-.7.408-1.344.677-1.906A5.763 5.763 0 0 1 6.125.917ZM14.698 5h-2.98c.164.829.253 1.73.272 2.667h3.333A7.298 7.298 0 0 0 14.698 5ZM11.03 5H8.333v2.667h2.99c-.02-.946-.12-1.847-.292-2.667ZM7.667 5H4.969a14.64 14.64 0 0 0-.292 2.667h2.99V5ZM4.28 5H1.302a7.3 7.3 0 0 0-.625 2.667H4.01c.02-.938.108-1.838.271-2.667Zm11.042 3.333H11.99A15.426 15.426 0 0 1 11.719 11h2.979a7.3 7.3 0 0 0 .625-2.667Zm-4 0h-2.99V11h2.698c.172-.819.272-1.72.292-2.667Zm-3.656 0h-2.99c.02.948.12 1.848.292 2.667h2.698V8.333Zm-3.657 0H.677c.041.947.258 1.847.625 2.667h2.98a15.43 15.43 0 0 1-.272-2.667Zm10.344 3.334h-2.781a10.213 10.213 0 0 1-.677 1.896 5.845 5.845 0 0 1-1.031 1.53 7.33 7.33 0 0 0 4.49-3.426Zm-3.49 0h-2.53v3.625c.712-.169 1.408-.871 1.958-2.021a9.4 9.4 0 0 0 .573-1.604Zm-3.197 0H5.135c.16.594.347 1.132.573 1.604.55 1.15 1.246 1.852 1.959 2.02v-3.624Zm-3.24 0H1.646a7.33 7.33 0 0 0 4.49 3.427 5.845 5.845 0 0 1-1.032-1.531c-.269-.563-.5-1.199-.677-1.896Z"/>
</svg>
);
export default globe;
