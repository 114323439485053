import create from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";
import axios from "axios";
import Cookies from "universal-cookie";
import path from "@assets/js/browser-path";
import { dataLayerCommercePush } from "@assets/js/gtm";
import globalErrorHandler from "@assets/js/global-error-handler";
import { log } from "@assets/js/utils";
import { COOKIES, STATUS } from "@constants";

//import basket from "@assets/js/icons/basket";

export const basketDataProxy = (data) => {
  let priceView = {
    totalWithoutDiscount: data.TotalWithoutDiscount,
    subTotal: data.SubTotal,
    taxTotal: data.TaxTotal,
    discountTotal: data.DiscountTotal,
    orderTotal: data.OrderTotal,
    shippingTotal: data.ShippingTotal,
    vat: data.Vat,
    currency: data.Currency,
  };

  if (data?.OrderProperties?.voucherCodes) {
    let codesSplit = data.OrderProperties.voucherCodes.split(",");
    codesSplit = codesSplit.filter((item) => item !== "");
    priceView.voucherCode = codesSplit[0];
  }

  return {
    pendingRemovalProducts: [],
    lineItems: data.LineItems,
    priceView: priceView,
    loadStatus: STATUS.LOADING_IDLE,
    hasProducts: data.LineItems.length > 0,
    basketProperties : data.OrderProperties,
  };
};

const store = create(
  devtools(
    (set, get) => ({
      loadStatus: STATUS.LOADING_IDLE,

      basketId: null,
      lineItems: null,

      basketProperties: null,

      pendingRemovalProducts: [],

      productsWithInvalidPrice: null,
      setProductsWithInvalidPrice: (value) => set({ productsWithInvalidPrice: value }),

      uniqueMaterialUnavailable: null,
      setUniqueMaterialUnavailable: (value) => set({ uniqueMaterialUnavailable: value }),

      availableStockExceeded: false,
      setAvailableStockExceeded: (value) => set({ availableStockExceeded: value }),
      
      availableStockQuantity: -1,
      setAvailableStockQuantity: (value) => set({ availableStockQuantity: value }),
      
      priceView: null,

      getBasket: async (commerceMarket, basketId = null) => {
        if (process.env.NODE_ENV === "development") log("BASKET | getBasket");
        const response = await axios
          .get(
            `${path.join(
              process.env.REACT_APP_ROOTAPI,
              "basketapi/get/",
              basketId ? basketId : get().basketId
            )}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET GET"));

        if (response) {
          set({
            ...basketDataProxy(response.data),
          });
        } else {
          if (process.env.NODE_ENV === "development")
            log("BASKET | getBasket failed - basket not found");

          get().createBasket(commerceMarket);
        }
      },

      createBasket: async (commerceMarket, languageReloadUrl = null) => {
        log("BASKET | createBasket");

        // const commerceMarketSupportsEcom = globalStore.getState()
        //   .commerceMarketSupportsEcom;
        // if (!commerceMarketSupportsEcom) return false;

        const response = await axios
          .post(
            `${path.join(
              process.env.REACT_APP_ROOTAPI,
              "basketapi/createemptybasket"
            )}?marketCountryCode=${commerceMarket}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET CREATE"));
        if (response) {
          const cookies = new Cookies();
          cookies.set(COOKIES.basket.name, response.data.BasketId, {
            maxAge: 7 * 24 * 3600,
            path: "/",
          });

          if (languageReloadUrl) {
            log("SET URL",languageReloadUrl)
            window.location.pathname = languageReloadUrl;
          }
          set({
            basketId: response.data.BasketId,
          });
        }
      },

      addProduct: async (
        payload,
        successHandler = null,
        errorHandler = null
      ) => {
        if (process.env.NODE_ENV === "development") log("BASKET | addProduct");
        set({ loadStatus: STATUS.LOADING });
        const basketId = payload.basketId || get().basketId || null;
        const quantity = payload.quantity || 1;
        const uniqueId = payload.uniqueId || '';

        if (basketId) {
          const response = await axios
            .post(
              `${path.join(
                process.env.REACT_APP_ROOTAPI,
                "basketapi/add/",
                basketId
              )}?productId=${payload.productId}&quantity=${quantity}&uniqueId=${uniqueId}`
            )
            .catch((error) => globalErrorHandler(error, "BASKET ADD"));

          if (response) {
            set(basketDataProxy(response.data));
            if ( 
              response.data.ValidationErrors && 
              response.data.ValidationErrors[0] && 
              response.data.ValidationErrors[0].ErrorType){
              switch(response.data.ValidationErrors[0].ErrorType){
                case "StockExeeded":
                  let availableStockQuantity = response.data.ValidationErrors[0].Data?.AllowedQuantity;       
                  set({availableStockExceeded: true});
                  set({availableStockQuantity: availableStockQuantity});
                  if (errorHandler) errorHandler(true);
                  break;
                case "UniqueMaterialNotAvailable":
                  set({uniqueMaterialUnavailable: true});
                  if (errorHandler) errorHandler(false);
              } 
            } 
            else {
              if (successHandler) successHandler(!!payload.uniqueId);
            }
          } else {
            if (errorHandler) errorHandler();
          }
        } else {
          if (errorHandler) errorHandler();
          globalErrorHandler("No basket id found", "BASKET ADD");
        }
      },

      setQuantity: async (body, errorHandler = null, successHandler = null) => {
        let currentQuantity;
        
        set((state) => ({
          loadStatus: STATUS.LOADING,
          lineItems: produce(state.lineItems, (draft) => {
            const lineItem = draft.find((item) => item.OrderLineId === body.OrderLineId);
            currentQuantity = lineItem.Quantity;
            lineItem.Quantity = body.Quantity;
          })
        }));

        const response = await axios.post(
          `${process.env.REACT_APP_ROOTAPI}basketapi/UpdateQuantity/${body.basketId}?orderLineId=${body.OrderLineId}&quantity=${body.Quantity}`
        );
        if (response) {
          set(basketDataProxy(response.data));
          
          if ( 
            response.data.ValidationErrors && 
            response.data.ValidationErrors[0] && 
            response.data.ValidationErrors[0].ErrorType &&
            response.data.ValidationErrors[0].ErrorType === "StockExeeded"
          ) {
            let availableStockQuantity = response.data.ValidationErrors[0].Data?.AllowedQuantity;
            set({availableStockExceeded: true});
            set({availableStockQuantity: availableStockQuantity});
            if ( errorHandler ) errorHandler(availableStockQuantity);
          }
          else if (successHandler){
            successHandler(body.Quantity, body.OldQuantity);
          } 
        } else {
          if (errorHandler) errorHandler();
          globalErrorHandler("Quantity update fail", "QUANTITY SET");


          set((state) => ({
            loadStatus: STATUS.LOADING,
            lineItems: produce(state.lineItems, (draft) => {
              const lineItem = draft.find((item) => item.OrderLineId === body.OrderLineId);
              lineItem.Quantity = currentQuantity;
            })
          }));
        }
      },

      removeLine: async ({ basketId, OrderLineId, gtm, currency, successHandler, errorHandler }) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios
          .post(
            `${process.env.REACT_APP_ROOTAPI}basketapi/Remove/${basketId}?orderLineId=${OrderLineId}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET REMOVE"));
        if (response) {
          set(basketDataProxy(response.data));
          // GTM Tracking
          dataLayerCommercePush("removeFromCart", {
            currencyCode: currency,
            remove: {
              products: [
                gtm
              ]
            },
          });
          if (successHandler) successHandler();
        } else {
          if (errorHandler) errorHandler();
        }
      },
      /**
       *
       * @param {Object} body
       * @param {Function} errorHandler
       * @param {Function} successHandler
       */
      submitVoucher: async (body, errorHandler, successHandler) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios
          .post(
            `${process.env.REACT_APP_ROOTAPI}basketapi/AddVoucher?basketId=${body.basketId}&voucherCode=${body.voucherCode}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET ADD VOUCHER"));
        if (response) {
          if (response.data.Item2 === false) {
            // globalErrorHandler("VOUCHER WRONG!", "BASKET ADD VOUCHER");
            if (errorHandler) errorHandler();
          } else {
            set(basketDataProxy(response.data.Item1));
            if (successHandler) successHandler();
          }
        }
      },
      /**
       *
       * @param {Object} body
       */
      removeVoucher: async (body) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios.post(
          `${process.env.REACT_APP_ROOTAPI}basketapi/RemoveVoucher?basketId=${body.basketId}&voucherCode=${body.voucherCode}`
        );
        set(basketDataProxy(response.data));
      },
      /**
       *
       * @param {String} newCommerceMarket
       * @param {String} basketId
       * @param {String} languageReloadUrl
       */
      changeBasketMarketCountry: async (
        newCommerceMarket,
        _basketId,
        languageReloadUrl 
      ) => {


        let basketId = get().basketId;

        log("changeMarketCountry",newCommerceMarket,basketId,languageReloadUrl)

        if (newCommerceMarket && !basketId) {
          get().createBasket(newCommerceMarket, languageReloadUrl);
        }
        else if (newCommerceMarket && basketId) {
          const response = await axios
            .post(
              path.join(
                process.env.REACT_APP_ROOTAPI,
                "basketapi/changemarketcountry/",
                `?basketId=${basketId}&marketCountryCode=${newCommerceMarket}&force=false`
              ),
              {}
            )
            .catch((error) =>
              globalErrorHandler(error, "BASKET CHANGE MARKET")
            );

          if (response) {
            log("CMC  -   response",response)
            
            if (response?.data?.Item2?.length > 0) {
              if (languageReloadUrl) {
                //no need to show modal, just continue to the resetBasket call
                get().resetBasket(
                  newCommerceMarket,
                  basketId,
                  languageReloadUrl
                );
              } else {
                set({ pendingRemovalProducts: response.data.Item2 });
              }
            } else {
              log("SET URL???",languageReloadUrl)
              if (languageReloadUrl) {
                //no need to update basket, will happen automatically
                log("SET URL",languageReloadUrl)
                window.location.pathname = languageReloadUrl;
                // alert("RELOAD! " + languageReloadUrl);
              } else {
                set(basketDataProxy(response.data.Item1));
              }
            }
          }
        } else {
          globalErrorHandler(
            "No commerce market or basket ID",
            "BASKET CHANGE MARKET"
          );
        }
      },

      updateBasketOnCheckout : (basket) =>{
        set(basketDataProxy(basket));
      },

      resetBasket: async (newCommerceMarket, basketId, languageReloadUrl) => {
        const response = await axios
          .post(
            path.join(
              process.env.REACT_APP_ROOTAPI,
              "basketapi/changemarketcountry/",
              `?basketId=${basketId}&marketCountryCode=${newCommerceMarket}&force=true`
            ),
            {}
          )
          .catch((error) => globalErrorHandler(error, "BASKET RESET"));

        if (languageReloadUrl) {
          //no need to update basket, will happen automatically
          log("SET URL",languageReloadUrl)
          window.location.pathname = languageReloadUrl;
          // alert("RELOAD! " + languageReloadUrl);
        } else if (response?.data?.Item1) {
          //items need to be removed
          set(basketDataProxy(response.data.Item1));
        }
      },
    }),
    "Basket"
  )
);

export default store;
