import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";

import axios from "axios";

import MiniBasketProduct from "../MiniBasketProduct";
import MiniBasketAddon from "../MiniBasketAddon";
import MiniBasketCalculation from "../MiniBasketCalculation";
import ActionButton from "@components/ActionButton";
import { log } from "@assets/js/utils";

import { PlusSvg, CheckmarkLargeSvg } from "@assets/js/icons";
import useBasketStore from "@store/basket";
import useGlobalStore, { useCommerceMarket } from "@store/global";
import useHeaderStore from "@store/header";
import { useUniqueMaterialIsUnavailable, useActiveUniqueMaterial } from "@store/pdp" 
import { SITE_MODE, OVERLAY } from "@constants";

import "./mini-basket.scss";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const checkoutStartQuery = loader("./checkoutStartQuery.graphql");

const MiniBasketController = ({ noFooter, notification, notificationDisabledMarkets, shownOnBasketPage = false }) => {
  const [commerceMarket] = useCommerceMarket();
  const [, setUniqueMaterialIsUnavailable] =  useUniqueMaterialIsUnavailable();
  const [activeUniqueMaterial, setActiveUniqueMaterial] = useActiveUniqueMaterial();
  const setProductsWithInvalidPrice = useBasketStore((state) => state.setProductsWithInvalidPrice);
  const lineItems = useBasketStore((state) => state.lineItems);
  const priceView = useBasketStore((state) => state.priceView);
  const hasProducts = useBasketStore((state) => state.hasProducts);
  const basketId = useBasketStore((state) => state.basketId);
  const setMenuOverlayActive = useHeaderStore(
    (state) => state.setMenuOverlayActive
  );
  const getBasket = useBasketStore((state) => state.getBasket);

  const setUniqueMaterialUnavailable = useBasketStore(
    (store) => store.setUniqueMaterialUnavailable
  );

  const history = useHistory();
  const submitVoucher = useBasketStore((store) => store.submitVoucher);

  //const basketData = useBasketStore((store) => store.basketData);
  const { t } = useTranslation();

  const texts = {
    goToCheckout: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_GoToCheckout"
    ),
    cartHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_Headline"
    ),
    cartIsEmpty: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_CartIsEmpty"
    ),
    noProdsAddedText: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_NoProdsAdded"
    ),
    addVoucherCode: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_AddVoucher"
    ),
    assembly: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_AssemblyUponDelivery"
    ),
    warranty: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Warranty"),
    easyReturns: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_EasyReturns"
    ),
    apply: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Apply"),
    voucherConfirmed: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_VoucherConfirmed"
    ),
    voucherError: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_VoucherError"
    ),
    addToBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddToBasket"
    )
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const VOUCHER_STEP = {
    AVAILABLE: "AVAILABLE",
    INPUT: "INPUT",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    HIDE: "HIDE"
  };

  const [voucherHandlerStep, setVoucherHandlerStep] = useState(
    priceView?.discountTotal > 0 && priceView?.voucherCode ? VOUCHER_STEP.SUCCESS : 
      priceView?.discountTotal > 0 ? VOUCHER_STEP.HIDE : VOUCHER_STEP.AVAILABLE
  );

  const addProductToBasket = useBasketStore((state) => state.addProduct);

  const [voucherText, setVoucherText] = useState("");
  const [removeButtonsLocked, setRemoveButtonsLocked] = useState(false);

  const mode = useGlobalStore((state) => state.currentSiteMode);
  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;

  const { data } = useQuery(checkoutStartQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const showVoucherInput = () => {
    setVoucherHandlerStep(VOUCHER_STEP.INPUT);
  };
  const voucherInputHandler = (val) => {
    setVoucherText(val);
    setVoucherHandlerStep(VOUCHER_STEP.INPUT);
  };
  const voucherSubmitHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();

    submitVoucher(
      { basketId: basketId, voucherCode: voucherText },
      voucherErrorHandler,
      voucherSuccessHandler
    );
  };

  const voucherErrorHandler = (event) => {
    setVoucherHandlerStep(VOUCHER_STEP.ERROR);
  };
  const voucherSuccessHandler = (event) => {
    setVoucherHandlerStep(VOUCHER_STEP.SUCCESS);
  };
  const voucherClickHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const voucherKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      voucherSubmitHandler(event);
    }
  };

  const notificationIsDisabled = () => {
    if(!notificationDisabledMarkets){
      return false;
    }
    var markets = notificationDisabledMarkets.split(',');
    return markets.includes(commerceMarket);
  }

  const basketClickHandler = (event) => {
    if (
      voucherHandlerStep === VOUCHER_STEP.INPUT ||
      voucherHandlerStep === VOUCHER_STEP.ERROR
    )
      setVoucherHandlerStep(VOUCHER_STEP.AVAILABLE);
  };

  const checkoutStartHandler = (e) => {
    e.preventDefault();

    if (data && data?.menu?.checkoutStartLink?.jss?.value?.href) {

      //validate basket
      axios.get(`${process.env.REACT_APP_ROOTAPI}checkout/validatebasket/${basketId}`)
      .then(response => {
        setMenuOverlayActive(OVERLAY.NONE);
        history.push(data?.menu?.checkoutStartLink?.jss?.value?.href);
      })
        .catch(error => {
          if (error.response.status === 409) {
            setMenuOverlayActive(OVERLAY.NONE);
            if (error.response.data.UniqueMaterials) {
              setUniqueMaterialUnavailable(error.response.data.UniqueMaterials.split(','));
              setUniqueMaterialIsUnavailable(false);
            }
            if (error.response.data.Lines) {
              setProductsWithInvalidPrice(error.response.data.Lines);
            }

            if (activeUniqueMaterial && error.response.data.UniqueMaterials.split(',').includes(activeUniqueMaterial.id)) {
              setActiveUniqueMaterial(undefined);
            }
            //reload basket since lines may have been removed.
            getBasket(commerceMarket, basketId);
          }
        });

      //validate unique materials
      // const linesWithUniqueMaterialId = lineItems?.filter((lineItem) => { return lineItem.UniqueMaterialId });
      // if (linesWithUniqueMaterialId && linesWithUniqueMaterialId.length > 0) {
      //   axios.get(`${process.env.REACT_APP_ROOTAPI}checkout/validatebasket/${basketId}`)
      //   .then(response => {
      //     setMenuOverlayActive(OVERLAY.NONE);
      //     history.push(data?.menu?.checkoutStartLink?.jss?.value?.href);
      //   })
      //     .catch(error => {
      //       if(error.response.status === 409){
      //         setMenuOverlayActive(OVERLAY.NONE);
      //         setUniqueMaterialUnavailable(error.response.data.split(','));
      //         //reload basket since lines may have been removed.
      //         getBasket(commerceMarket, basketId);
      //         setUniqueMaterialIsUnavailable(false);
      //         if(activeUniqueMaterial && error.response.data.split(',').includes(activeUniqueMaterial.id)){
      //           setActiveUniqueMaterial(undefined);
      //         }
      //       }
      //     });
      // }
      // else
      // {
      //   setMenuOverlayActive(OVERLAY.NONE);
      //   history.push(data?.menu?.checkoutStartLink?.jss?.value?.href);
      // }
    }
  };

  useEffect(() => {
    if (priceView?.discountTotal === 0 && (voucherHandlerStep === VOUCHER_STEP.SUCCESS || voucherHandlerStep === VOUCHER_STEP.HIDE)){
      setVoucherHandlerStep(VOUCHER_STEP.AVAILABLE);
    }
    if(priceView?.discountTotal > 0 && priceView?.voucherCode){
      setVoucherHandlerStep(VOUCHER_STEP.SUCCESS);
    }
    if(priceView?.discountTotal > 0 && !priceView?.voucherCode){
      setVoucherHandlerStep(VOUCHER_STEP.HIDE);
    }  
  }, [priceView, VOUCHER_STEP, voucherHandlerStep]);

  ////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="mini-basket" onClick={basketClickHandler}>
        <div
          className={`mini-basket__headline ${
            !hasProducts ? "mini-basket__headline--no-products" : ""
          }`}
        >
          {hasProducts && <h2>{texts.cartHeadline}</h2>}
          {!hasProducts && (
            <>
              <h2>{texts.cartIsEmpty}</h2>
              <p>{texts.noProdsAddedText}</p>
            </>
          )}
        </div>
        {hasProducts && (<>
          {!notificationIsDisabled() && notification && <div className="mini-basket__notice">
            <RichText field={{ value: notification }} />
          </div>}
          <div className="mini-basket__products">
            {lineItems &&
              lineItems.map((item, index) => {
                return (
                  <Fragment key={`product${shownOnBasketPage}${index}`}>
                    <MiniBasketProduct                 
                      {...item}
                      currency={priceView.currency}
                      basketId={basketId}
                      removeButtonsLocked={removeButtonsLocked}
                      setRemoveButtonsLocked={setRemoveButtonsLocked}
                    />
                    {item.AddOnProducts && item.AddOnProducts.map((item, index) => {
                      return <MiniBasketAddon
                          key={`minibasketaddon${index}`}
                          {...item}
                          addHandler={() => {
                            addProductToBasket({
                              productId: item.ConfigurationId,
                              quantity: item.MinimumQuantity,
                            });
                          }}
                        />
                    })}
                  </Fragment>
                );
              })}
          </div>
        </>
        )}
        {/* Waiting for data for the addon part
        <div className="mini-basket__addon">
          <MiniBasketAddon fields={fields.addon.fields} />
      </div> */}
        {hasProducts && (
          <div className="mini-basket__calculation">
            <MiniBasketCalculation {...priceView} />
          </div>
        )}
        {hasProducts && (
          <div className="mini-basket__cta-row">
            {voucherHandlerStep === VOUCHER_STEP.AVAILABLE && (
              <button className="icon-link" onClick={showVoucherInput}>
                <PlusSvg />
                {texts.addVoucherCode}
              </button>
            )}

            {(voucherHandlerStep === VOUCHER_STEP.INPUT ||
              voucherHandlerStep === VOUCHER_STEP.ERROR) && (
              <div className="voucher">
                <div className="voucher__input" onClick={voucherClickHandler}>
                  <input
                    type="text"
                    autoFocus
                    onChange={(event) => {
                      voucherInputHandler(event.target.value);
                    }}
                    onKeyDown={(e) => voucherKeyDownHandler(e)}
                  />
                  <button type="submit" onClick={voucherSubmitHandler}>
                    {texts.apply}
                  </button>
                </div>
                {voucherHandlerStep === VOUCHER_STEP.ERROR && (
                  <p>{texts.voucherError}</p>
                )}
              </div>
            )}

            {voucherHandlerStep === VOUCHER_STEP.SUCCESS && priceView?.voucherCode && (
              <button className="icon-link">
                <CheckmarkLargeSvg />
                {texts.voucherConfirmed}
              </button>
            )}

            {data?.menu?.checkoutStartLink?.jss?.value?.href && (
              <ActionButton
                href={data.menu.checkoutStartLink.jss.value.href}
                clickHandler={checkoutStartHandler}
                isInverted
                buttonType="solid"
                text={texts.goToCheckout}
                hasArrow={true}
              />
            )}
          </div>
        )}
        {!noFooter && (
          <div className="mini-basket__footer">
            <nav className="mini-basket__usp">
              <span className="mini-basket__usp-item">{texts.assembly}</span>
              <span className="mini-basket__usp-item-separator">|</span>
              <span className="mini-basket__usp-item">{texts.warranty}</span>
              <span className="mini-basket__usp-item-separator">|</span>
              <span className="mini-basket__usp-item">{texts.easyReturns}</span>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};

export default MiniBasketController;
