import React from "react";

import { BUTTONSTYLE } from "@constants";
import useGlobalStore from "@store/global";
import ActionButton from "../ActionButton";

import "./country-select-button.scss";

const CountrySelectButton = ({ clickHandler }) => {
  const marketInfo = useGlobalStore((store) => store.marketInfo);

  const commerceMarket = useGlobalStore((store) => store.commerceMarket);
  const sc_lang = useGlobalStore((store) => store.sc_lang);

  const getButtonLabel = () => {
    if (marketInfo && commerceMarket && sc_lang) {
      const foundMarket = marketInfo.markets.values.find(
        (item) => item?.commerceMarket?.value?.toLowerCase() === commerceMarket
      );
      const foundLang = marketInfo.languages.values.find(
        (item) =>
          item?.sitecoreLanguage?.value?.languageCode?.toLowerCase() ===
          sc_lang.toLowerCase()
      );      
      return `${foundMarket?.countryName.value} / ${foundMarket?.currency.value} – ${foundLang?.name.value}`;
    }
  };

  return (
    <div className="country-select-button" onClick={clickHandler}>
      <ActionButton
        buttonType={BUTTONSTYLE.NONE}
        text={getButtonLabel()}
        icon="ChevronSvg"
      />
    </div>
  );
};

export default CountrySelectButton;
