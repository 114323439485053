import React, { useEffect, useState } from "react";
import ModalOverlay from "../ModalOverlay";
import { enableScroll, disableScroll } from "@assets/js/scrolllock";
import ActionButton from "../ActionButton";
import { useMyFritzHansenRegisterProductModal, useOwnedProductsUrl, useFailedProductsList } from "@store/myfritzhansen";
import { useTranslation } from "react-i18next";

const MyFritzHansenRegisterProductModal = (fields) =>{
    const [modalActive, setModalActive] = useState(false);
    const [showMyFritzHansenRegisterProductModal, setShowMyFritzHansenRegisterProductModal] = useMyFritzHansenRegisterProductModal();
    const [failedProductsList, setfailedProductsList] = useFailedProductsList();
    const [ownedProductsUrl] = useOwnedProductsUrl();

    const { t } = useTranslation();
    const texts = {
        ok: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_RegisterProduct_Ok"),
        title: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_RegisterProduct_Title"),
        text: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_RegisterProduct_SubText"),
        failed: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_RegisterProduct_Failed"),
      };    
   
    const cancelButtonHandler = () => {
        setShowMyFritzHansenRegisterProductModal(false);
        setModalActive(false);
        enableScroll();
        setfailedProductsList([]);
    };

    useEffect(() => {
        if (showMyFritzHansenRegisterProductModal) {
            setModalActive(true);
            disableScroll();
        }
    }, [showMyFritzHansenRegisterProductModal]);

    return (
        <ModalOverlay
            className="qrcode-modal"
            openState={modalActive}
            closeHandler={cancelButtonHandler}
            content={
                <>
                    <h3>{texts.title}</h3>           
                    {failedProductsList && failedProductsList.length!=0 ?
                    <p className="text">{texts.failed}</p> : <p className="text">{texts.text}</p>
                    }
                    {failedProductsList.map((item, index) => {
                        return (
                            <p>{item}</p>
                        );
                    })}
                    <div className="button-modal">
                        <ActionButton
                            text={texts.ok}
                            buttonType="ghost"
                            isDisabled={false}
                            clickHandler={cancelButtonHandler}
                        />
                        {ownedProductsUrl?.href && 
                        <ActionButton
                            text={ownedProductsUrl.title}
                            buttonType="ghost"
                            isDisabled={false}
                            clickHandler={cancelButtonHandler}
                            href={ownedProductsUrl.href}
                        />
                        }
                    </div>
                </>         
            }
        />
    );
}

export default MyFritzHansenRegisterProductModal