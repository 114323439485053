import React, { useEffect, useState } from "react";
import ModalOverlay from "../ModalOverlay";
import { enableScroll, disableScroll } from "@assets/js/scrolllock";
import useMyFhStore, { useMyFritzHansenInfoModal , useActiveProductData } from "@store/myfritzhansen";
import { useTranslation } from "react-i18next";
import { useLanguage, useCommerceMarket} from "@store/global";
import InputField from "../forms/InputField";
import Select from "../forms/Select";
import { log } from "@assets/js/utils";
import "./showinfo-modal.scss";
import Textarea from "../forms/Textarea";
import {getFormattedDate} from "@assets/js/get-formatted-delivery-time";

const MyFritzHansenInfoModal = () =>{
    const [modalActive, setModalActive] = useState(false);
    const [showMyFritzHansenInfoModal, setShowMyFritzHansenInfoModal] = useMyFritzHansenInfoModal();
    const [activeProductData,setActiveProductData] = useActiveProductData();
    const updateComment = useMyFhStore((store) => store.updateComment);
    const reportStolen = useMyFhStore((store) => store.reportStolen);
    const reportFound = useMyFhStore((store) => store.reportFound);
    const [language] = useLanguage();
    const [commerceMarket] = useCommerceMarket();

    const { t } = useTranslation();

    const texts = {
        yes: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Yes"),
        no: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_No"),
        title: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_DeleteProductModal_Title"),
        text: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_DeleteProductModal_Text"),
        productInformationHeader: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ProductInformationHeader"),
        productStatusHeader: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ProductStatusHeader"),
        productStatusLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ProductStatusLabel"),
        productStatusFieldLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ProductStatusFieldLabel"),
        lastStatusUpdateLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_LastStatusUpdateLabel"),
        identificationNumberLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_IdentificationNumberLabel"),
        modelGroupLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ModelGroupLabel"),
        materialLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_Material"),
        registeredDateLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_RegisteredDateLabel"),
        commentsLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_CommentsLabel"),
        reportStolenLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ReportStolenLabel"),
        productRetrievedLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ProductRetrievedLabel"),
        changeStatusLabel: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_ProductInfoModal_ChangeProductStatusLabel")
      };    
    
    const cancelButtonHandler = () => {
        setShowMyFritzHansenInfoModal(false);
        setActiveProductData(null);
        setModalActive(false);
        enableScroll();
    };

    useEffect(() => {
        if (showMyFritzHansenInfoModal) {
            setModalActive(true);
            disableScroll();
        }
    }, [showMyFritzHansenInfoModal]);

    const updateComments = (value) =>{
        updateComment(true, activeProductData.registeredProductId, value)
    }

    const getProductStatuses = () => {
        var productStatuses = [];
        productStatuses.push({
            id: "0",
            optionLabel: texts.productStatusFieldLabel,
            optionValue: 0
        });
        if (activeProductData?.productStatus?.id === 3) {
            productStatuses.push({
                id: "1",
                optionLabel: texts.reportStolenLabel,
                optionValue: 4,
            });
        }
        if (activeProductData?.productStatus?.id === 4) {
            productStatuses.push({
                id: "2",
                optionLabel: texts.productRetrievedLabel,
                optionValue: 3,
            });
        }
        return productStatuses;
    }

    const updateProductStatus = (target) => {
        if (target === "4")
            reportStolen(true, activeProductData?.id);

        if (target === "3")
            reportFound(true, activeProductData?.id);
    }

    return (
        <ModalOverlay
            className="qrcode-modal"
            secondClassName={"showinfo-modal"}
            openState={modalActive}
            closeHandler={cancelButtonHandler}
            content={
                <>
                    <h3>{texts.productInformationHeader}</h3>
                    <div className="showinfo-container">
                        <p className="showinfo-title">{texts.identificationNumberLabel}</p>
                        <p className="showinfo-value">{activeProductData?.registeredProduct?.fhidNumber}</p>
                    </div>     
                    <div className="showinfo-container">
                        <p className="showinfo-title">{texts.modelGroupLabel}</p>
                        <p className="showinfo-value">{activeProductData?.registeredProduct?.modelGroupName}</p>
                    </div>  
                    {activeProductData?.registeredProduct?.material?.englishName &&
                        < div className="showinfo-container">
                            <p className="showinfo-title">{texts.materialLabel}</p>
                            <p className="showinfo-value">{activeProductData?.registeredProduct?.material?.englishName}</p>
                        </div>
                    }
                    <div className="showinfo-container">
                        <p className="showinfo-title">{texts.registeredDateLabel}</p>
                        <p className="showinfo-value">{getFormattedDate(activeProductData?.registeredProduct?.registeredDate,language,commerceMarket,true)}</p>
                    </div>
                    <div className="showinfo-container">
                        <p className="showinfo-title">{texts.commentsLabel}</p>
                        <Textarea
                            id="Comments"
                            name="comments"
                            hasValue={activeProductData?.dealerNote}
                            type="text"
                            label={null}
                            disabled={false}
                            blurHandler={updateComments}
                            value={activeProductData?.dealerNote || ''}
                        />
                    </div>
                    <div className="showinfo-container">
                        <p className="showinfo-title">{texts.productStatusLabel}</p>
                        <p className="showinfo-value">{activeProductData?.productStatus?.name}</p>
                    </div>    
                    <div className="showinfo-container">
                        <Select
                            defaultLabel={texts.productStatusFieldLabel}
                            id="ChangeProductStatus"
                            name="changeProductStatus"
                            hasValue={true}
                            type="text"
                            disabled={false}
                            changeHandler={updateProductStatus}
                            options={getProductStatuses()}
                            placeHolder={texts.productStatusFieldLabel}
                            isShowInfoModal={true}
                            hideDefaultLabenWhenSelection={true}
                        />
                    </div>
                    <div className="showinfo-container">
                        <p className="showinfo-title">{texts.lastStatusUpdateLabel}</p>
                        <p className="showinfo-value">{getFormattedDate(activeProductData?.statusDate, language, commerceMarket,true)}</p>
                    </div> 
                </>
                
            }
        />
    );
}

export default MyFritzHansenInfoModal