import React from "react";
import { useTranslation } from "react-i18next";

import "./search-form.scss";
import { MagnifierSvg } from "@assets/js/icons";

const SearchForm = ({ onChange, onActivate, value }) => {
  const { t } = useTranslation();
  const texts = {
    searchHere: t("FritzHansen_Project_ConsumersAndProfessionals_Search_SearchHere"),
  };

  const changeHandler = (event) => {
    if (onChange) onChange(event.target);
  };

  const activateHandler = () => {
    if (onActivate) onActivate();
  };

  return (
    <div className="search-form">
      <input type="text" autoFocus={onChange ? true : false} placeholder={texts.searchHere} onFocus={activateHandler} onChange={changeHandler} value={value ?? ""}/>

      <button onClick={activateHandler}>
        <MagnifierSvg />
      </button>
    </div>
  );
};

export default SearchForm;
