import React from "react";
import "./icons.scss";

const jpg = ({ className, isInverted }) => (

    <svg
        className={`svg-icon svg-icon--pdf ${className || ""} ${isInverted ? "is-inverted" : ""
            }`}

        viewBox="-300 -200 1250 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
     <g>
	<g>
		<path d="M475.095,131.992c-0.032-2.526-0.844-5.021-2.579-6.993L366.324,3.694c-0.031-0.034-0.062-0.045-0.084-0.076
			c-0.633-0.707-1.371-1.29-2.151-1.804c-0.232-0.15-0.464-0.285-0.707-0.422c-0.675-0.366-1.382-0.67-2.13-0.892
			c-0.201-0.058-0.38-0.14-0.58-0.192C359.87,0.114,359.037,0,358.193,0H97.2C85.292,0,75.6,9.693,75.6,21.601v507.6
			c0,11.913,9.692,21.601,21.6,21.601H453.6c11.908,0,21.601-9.688,21.601-21.601V133.202
			C475.2,132.796,475.137,132.398,475.095,131.992z M183.271,473.312c0,37.314-17.89,50.34-46.651,50.34
			c-6.803,0-15.747-1.165-21.576-3.111l3.309-23.909c4.082,1.36,9.326,2.341,15.159,2.341c12.445,0,20.216-5.643,20.216-26.051
			v-82.413h29.544V473.312z M289.997,461.458c-10.114,9.523-25.083,13.795-42.57,13.795c-3.892,0-7.391-0.189-10.114-0.58v46.85
			h-29.352V392.26c9.141-1.561,21.966-2.721,40.043-2.721c18.27,0,31.298,3.496,40.046,10.494
			c8.358,6.612,13.99,17.486,13.99,30.322C302.041,443.18,297.77,454.069,289.997,461.458z M430.344,515.489
			c-9.133,3.111-26.436,7.389-43.743,7.389c-23.898,0-41.206-6.028-53.245-17.683c-12.056-11.279-18.668-28.381-18.468-47.629
			c0.189-43.538,31.867-68.418,74.824-68.418c16.923,0,29.943,3.301,36.351,6.418l-6.218,23.715
			c-7.193-3.111-16.132-5.633-30.518-5.633c-24.68,0-43.337,13.996-43.337,42.378c0,27.016,16.906,42.957,41.207,42.957
			c6.803,0,12.244-0.785,14.575-1.951v-27.4h-20.218v-23.13h48.789V515.489z M97.2,366.752V21.601h250.192v110.515
			c0,5.961,4.842,10.8,10.801,10.8H453.6v223.836H97.2z"/>
		<path d="M249.56,411.888c-6.02,0-10.101,0.58-12.248,1.171v38.675c2.534,0.58,5.646,0.78,9.917,0.78
			c15.746,0,25.471-7.973,25.471-21.378C272.7,419.08,264.336,411.888,249.56,411.888z"/>
		<g>
			<path d="M246.04,243.448C202.906,200.301,161.077,367.2,161.077,367.2h143.759C304.836,367.2,289.195,286.622,246.04,243.448z"/>
			<path d="M350.673,287.978c-30.575,7.82-45.837,79.223-45.837,79.223h103.096C393.937,331.426,376.787,281.296,350.673,287.978z"
				/>
		</g>
		<path d="M161.088,204.989c14.773,0,26.747-11.965,26.747-26.747c0-14.778-11.973-26.733-26.747-26.733
			c-14.797,0-26.768,11.955-26.768,26.733C134.32,193.024,146.291,204.989,161.088,204.989z"/>
		<path d="M323.293,222.184c5.4,0,10.452-0.557,14.945-1.464c5.231,2.247,11.591,3.592,18.51,3.592
			c17.671,0,32.015-8.651,32.015-19.312c0-10.671-14.338-19.316-32.015-19.316c-6.645,0-12.793,1.215-17.919,3.301
			c-2.089-2.02-4.915-3.301-8.042-3.301h-4.636c-5.938,0-10.642,4.498-11.316,10.251c-14.354,1.49-24.976,6.669-24.976,12.873
			C289.854,216.201,304.82,222.184,323.293,222.184z"/>
	</g>
</g>
    </svg>
);
export default jpg;
