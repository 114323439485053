import React, { useState, useEffect } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Cookies from "universal-cookie";
import classNames from "classnames";
import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";
import MediaFocal from "../MediaFocal";
import { log } from "@assets/js/utils"
import { enableScroll, disableScroll } from "@assets/js/scrolllock";

import { BUTTONSTYLE, COOKIES } from "@constants";

import "./content-modal.scss";

const ContentModal = ({ fields }) => {
    const cookies = new Cookies();
    const showModal = () => {
        if(!fields?.ItemId){
            return false;
        }
        var contentModalCookie = cookies.get(COOKIES.contentModal.name);
        if (contentModalCookie) {
            return contentModalCookie !== fields.ItemId;
        }
        return true;
    }

    const [modalActive, setModalActive] = useState(showModal());

    const closeModalHandler = () => {
        setModalActive(false);

        if (fields?.ItemId) {
            cookies.set(COOKIES.contentModal.name,
                fields.ItemId,
                {
                    maxAge: 365 * 24 * 3600,
                    path: "/",
                }
            );
        }

        enableScroll();
    }

    useEffect(() => {
        disableScroll();
    }, []);

    let noMediaItemClass = classNames({
        "content-modal__item": true,
        "no-image": !fields?.Media?.fields
    });

    return (
        <ModalOverlay
            containerClassName="content-modal"
            className="content-modal"
            openState={modalActive}
            closeHandler={closeModalHandler}
            content={<>
                <ActionButton
                    buttonType={BUTTONSTYLE.NONE}
                    icon="TimesSvg"
                    clickHandler={closeModalHandler}
                    customClass="content-modal__close-icon"
                />
                <div className={noMediaItemClass}>
                    <div className="content-modal__left-item">
                        <Text tag="h3" field={fields?.Title} />
                        <Text tag="p" field={fields?.Body} className="content-modal__left-item__body" />
                        <div className="content-modal__left-item__buttons">
                            {fields?.Button1?.fields && <ActionButton
                                customClass="readmore-button"
                                text={fields?.Button1?.fields?.link?.value?.text}
                                buttonType={BUTTONSTYLE.SOLID}
                                href={fields?.Button1?.fields?.link?.value?.href}
                                clickHandler={closeModalHandler}
                                isInverted={true}
                            />}
                            {fields?.Button2?.fields && <ActionButton
                                customClass="readmore-button"
                                text={fields?.Button2?.fields?.link?.value?.text}
                                buttonType={BUTTONSTYLE.GHOST}
                                href={fields?.Button2?.fields?.link?.value?.href}
                                clickHandler={closeModalHandler}
                            />}
                        </div>
                    </div>
                </div>
                {fields?.Media?.fields && <div className="content-modal__item image-item">
                    <MediaFocal fields={fields?.Media?.fields} noSave={true} />
                </div>}
            </>
            }
        />
    );
};

export default ContentModal;
