import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import shallow from "zustand/shallow";
import axios from "axios";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { OVERLAY, BUTTONSTYLE } from "@constants";
import ActionButton from "../ActionButton";
import InputField from "../forms/InputField";
import path from "@assets/js/browser-path";

import useHeaderStore from "@store/header";
import useGlobalStore from "@store/global";
import usePDPStore from "@store/pdp";

import "./notify-me-overlay.scss";

const NotifyMeOverlay = (props) => {
  const { t } = useTranslation();

  const texts = {
    outOfStock: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_OutOfStock"
    ),
    signMeUp: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_SignMeUp"
    ),
    fieldEmail: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_FieldEmail"
    ),
    invalidEmail: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_InvalidEmail"
    ),
    send: t("FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_Send"),
    sending: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_Sending"
    ),
    serverError: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_ServerError"
    ),
    receiptHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_ReceiptHeadline"
    ),
    receiptBodytext: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_ReceiptBodytext"
    ),
    close: t("FritzHansen_Project_ConsumersAndProfessionals_NotifyMe_Close"),
  };
  const SUBMISSION_STEP = {
    INPUT: "INPUT",
    WAITING: "WAITING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
  };

  const [activeConfigurationSKU] = usePDPStore(
    (state) => [state.activeConfigurationSKU],
    shallow
  );
  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const setMenuOverlayActive = useHeaderStore(
    (state) => state.setMenuOverlayActive
  );
  const [submissionStep, setSubmissionStep] = useState(SUBMISSION_STEP.INPUT);

  const [emailInputValue, setEmailInputValue] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [serverError, setServerError] = useState(null);

  const changeHandler = (value) => {
    setEmailInputValue(value);
  };

  const submitHandler = () => {
    async function submitEmail() {
      setSubmissionStep(SUBMISSION_STEP.WAITING);

      const response = await axios
        .post(
          path.join(
            process.env.REACT_APP_ROOTAPI,
            "api",
            "EmailService",
            "NotifyMeSignup",
            `?lang=${sc_lang}&product=${activeConfigurationSKU}&email=${emailInputValue}`
          ),
          {}
        )
        .catch((error) => {
          setServerError(texts.serverError + ": " + error);
          setSubmissionStep(SUBMISSION_STEP.INPUT);
        });

      if (response) {
        setSubmissionStep(SUBMISSION_STEP.SUCCESS);
      } else {
        setServerError(texts.serverError);
      }
    }
    setServerError(null);

    if (emailChecksOut()) {
      setEmailError(null);
      if (sc_lang && activeConfigurationSKU) {
        submitEmail();
      }
    } else {
      setEmailError({ message: texts.invalidEmail });
    }
  };
  const closeHandler = () => {
    setMenuOverlayActive(OVERLAY.NONE);
  };

  const emailChecksOut = () => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const test = re.test(String(emailInputValue).toLowerCase());
    console.log("test", test);
    return test;
  };

  return (
    <div className="notify-me-overlay">
      {(submissionStep === SUBMISSION_STEP.INPUT ||
        submissionStep === SUBMISSION_STEP.WAITING) &&
        activeConfigurationSKU && (
          <div className="notify-me-overlay__form">
            <Text
              className="notify-me-overlay__headline"
              tag="h2"
              field={{ value: texts.outOfStock }}
            />
            <Text
              className="notify-me-overlay__bodytext"
              tag="p"
              field={{ value: texts.signMeUp }}
            />
            <div className="form__row">
              <InputField
                name="email"
                autocomplete="email"
                type="email"
                label={texts.fieldEmail}
                changeHandler={(value) => {
                  changeHandler(value);
                }}
                error={emailError}
                disabled={submissionStep === SUBMISSION_STEP.WAITING}
              />

              <ActionButton
                clickHandler={submitHandler}
                buttonType={BUTTONSTYLE.SOLID}
                text={
                  submissionStep === SUBMISSION_STEP.WAITING
                    ? texts.sending
                    : texts.send
                }
                isDisabled={submissionStep === SUBMISSION_STEP.WAITING}
              />
            </div>

            {serverError && (
              <p className="notify-me-overlay__error">{serverError}</p>
            )}
          </div>
        )}
      {submissionStep === SUBMISSION_STEP.SUCCESS && (
        <div className="notify-me-overlay__receipt">
          <Text
            className="notify-me-overlay__headline"
            tag="h2"
            field={{ value: texts.receiptHeadline }}
          />
          <Text
            className="notify-me-overlay__bodytext"
            tag="p"
            field={{ value: texts.receiptBodytext }}
          />
          <ActionButton
            clickHandler={closeHandler}
            buttonType={BUTTONSTYLE.SOLID}
            text={texts.close}
          />
        </div>
      )}
    </div>
  );
};
NotifyMeOverlay.propTypes = {};

export default NotifyMeOverlay;
