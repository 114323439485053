import React, { useEffect, useState, Fragment } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cuid from "cuid";
import classnames from "classnames";
import axios from "axios";
import shallow from "zustand/shallow";
import path from "@assets/js/browser-path";

import ProductPrice from "../../ProductPrice";
import ProductImage from "../../ProductImage";
import { BoxSvg, PlusSvg, HeartSmallSvg, CheckmarkLargeSvg } from "@assets/js/icons";
import LoginLink from "../../SiteHeader/LoginLink";

import { log } from "@assets/js/utils";
import dataLayerPush from "@assets/js/gtm";

import { placeholderReplace } from "@assets/js/utils";

import {
  OVERLAY,
  PORTFOLIO_TYPE,
  PORTFOLIO_WISHLIST_NAME,
  NOTIFICATION,
  SITE_MODE,
  DATALAYER_EVENTS
} from "@constants";

import usePortfolioStore, {
  usePortfoliosList,
  useSubmissionMode,
  useSubmissionContent,
} from "@store/portfolio";

import { useActiveProductData } from "@store/pdp";

import useNotificationStore from "@store/notifications";
import useGlobalStore from "@store/global";
import useHeaderStore from "@store/header";

import "./portfolio-submission.scss";

const fetchProduct = (productId, lang) => {
  // console.log(
  //   process.env.REACT_APP_PRODUCTSAPI,
  //   "Configuration",
  //   lang,
  //   "solrformat",
  //   "get",
  //   productId
  // );

  return axios.get(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "Configuration",
      lang,
      "solrformat",
      "get",
      productId
    )
  );
};

const portfolioLinkQuery = loader("../../../assets/js/gql/portfolioLinkQuery.graphql");

const PortfolioSubmissionController = (props) => {
  const [portfoliosList, , fetchPortfolios] = usePortfoliosList();
  const createPortfolio = usePortfolioStore((state) => state.createPortfolio);

  const userKey = useGlobalStore((store) => store.userKey);
  const userAuthenticated = useGlobalStore((store) => store.userAuthenticated);

  useEffect(() => {
    fetchPortfolios(userKey, userAuthenticated);
  }, [userKey, userAuthenticated, fetchPortfolios]);

  useEffect(() => {
    if (portfoliosList && portfoliosList.length === 0) {
      createPortfolio(userKey, userAuthenticated, PORTFOLIO_WISHLIST_NAME, true);
    }
  }, [portfoliosList, createPortfolio, userKey, userAuthenticated]);

  return <PortfolioSubmission />;
};

const PortfolioSubmission = (props) => {
  const [userKey, userAuthenticated, mode] = useGlobalStore(
    (store) => [store.userKey, store.userAuthenticated, store.currentSiteMode],
    shallow
  );
  const history = useHistory();
  const [portfoliosList, ,] = usePortfoliosList();
  const setActiveNotification = useNotificationStore((store) => store.setActiveNotification);

  const createPortfolio = usePortfolioStore((store) => store.createPortfolio);
  const addItem = usePortfolioStore((store) => store.addItem);
  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);

  const [submissionMode, setSubmissionMode] = useSubmissionMode();
  const [submissionContent, setSubmissionContent] = useSubmissionContent();
  const [activeProductData] = useActiveProductData();

  const [isCreating, setIsCreating] = useState(false);
  const [creationComplete, setCreationComplete] = useState(false);
  const [chosenPortfolioIndex, setChosenPortfolioIndex] = useState(-1);

  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [productData, setProductData] = useState(null);

  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;

  const { data: linkData } = useQuery(portfolioLinkQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const { t } = useTranslation();
  const texts = {
    saveToProject: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_SaveToProject"),
    createNewProject: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_CreateNewProject"),
    noPortfolios: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_NoPortfolios"),
    operationComplete: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_OperationComplete"
    ),
    addedToPortfolio: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_AddedToPortfolio"),
    uniqueMaterialAddedToPortfolio: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_UniqueMaterialAddedToPortfolio"),
    openPortfolio: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_OpenPortfolio"),
    wishList: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Wishlist"),
    noLoginNotice: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_NoLoginNotice"),
    loginButton: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_LoginButton")
  };

  const classList = classnames({
    "portfolio-submission": true,
    "portfolio-submission--media": submissionMode === PORTFOLIO_TYPE.MEDIA,
    "portfolio-submission--product": submissionMode === PORTFOLIO_TYPE.PRODUCT,
    "portfolio-submission--materials": submissionMode === PORTFOLIO_TYPE.MATERIAL,

    "has-chosen": chosenPortfolioIndex > -1,
    "is-creating": isCreating,
  });
  useEffect(() => {
    async function loadProduct() {
      const response = await fetchProduct(submissionContent.portfolioItem.productId, sc_lang);

      const item = response?.data[0];
      if (item) {
        setProductData({
          configurationId: item.configurationId_s,
          displayName_t: item.modelGroupName_s,
          fullName_t: item.displayName_t,
          imageUrl_s: item.imageUrl_s,
        });
      }
    }
    if (submissionContent?.portfolioItem?.productId) loadProduct();
  }, [sc_lang, submissionContent]);

  const portfolioClickHandler = (index, isAvailable) => {
    if (isCreating) {
      setIsCreating(false);
    }
    if (!isCreating && isAvailable) {
      setChosenPortfolioIndex(index);
      const chosenPortfolio = portfoliosList[index];
      addItem(chosenPortfolio.portfolioEditKey, addCompleteHandler, userAuthenticated);
    }
  };

  const addCompleteHandler = ({ data }) => {
    const name = data.isWishlist ? texts.wishList : data.name;
    setMenuOverlayActive(OVERLAY.NONE);
    let isUniqueMaterial = submissionContent?.portfolioItem?.isUniqueMaterial;

    let obj = {
      event: DATALAYER_EVENTS.PROJECTS,
      projectAction: data.isWishlist ? "add to wishlist" : "save to project",
    };

    if (submissionMode == PORTFOLIO_TYPE.MATERIAL) {
      obj.projectProductType = "Material";
      obj.projectProductName = `${submissionContent.portfolioItem?.materialSetId}, ${submissionContent.portfolioItem?.materialId}`;
    } else if (submissionMode == PORTFOLIO_TYPE.MEDIA) {
      obj.projectProductType = "Image";
      obj.projectProductCategory = submissionContent.portfolioItem?.imageUrl;
      obj.projectProductName = submissionContent.portfolioItem?.imageDescription;
    } else {
      obj.projectProductType = "Product";
      obj.projectProductName = `${activeProductData.dataLayerProductName}, ${activeProductData.dataLayerProductVariant}`;
      obj.projectProductBrand = activeProductData.dataLayerProductBrand;
      obj.projectProductCategory = activeProductData.dataLayerProductCategory;
      obj.projectProductModel = activeProductData.model?.label;
    }

    log("PortfolioSubmissionController.addCompleteHandler", obj);
    dataLayerPush(obj);

    setSubmissionMode(null);
    setSubmissionContent(null);
    setActiveNotification({
      id: cuid(),
      title: placeholderReplace(texts.addedToPortfolio, { name }),
      buttonText: placeholderReplace(texts.openPortfolio, { name }),
      bodyText: isUniqueMaterial ? texts.uniqueMaterialAddedToPortfolio : "",
      type: NOTIFICATION.ITEM_ADDED,
      clickHandler: () => {
        history.push(
          linkData
            ? `${linkData.menu.portfolioLink.jss.value.href}?pekey=${data.portfolioEditKey}`
            : "/"
        );
      },
      timer: 4000,
    });

  };

  const portfolioHoverHandler = (index, isAvailable) => {
    if (!isCreating && isAvailable) setChosenPortfolioIndex(index);
  };

  const listBlurHandler = (index) => {
    setChosenPortfolioIndex(-1);
  };

  const createClickHandler = () => {
    setIsCreating(true);
  };

  const createSaveHandler = (name) => { 
    createPortfolio(userKey, userAuthenticated, name, false, saveCompleteHandler);
  };

  const saveCompleteHandler = (status) => {
    setIsCreating(false);
    setCreationComplete(true);

    setTimeout(() => {
      setCreationComplete(false);
    }, 1000);

    dataLayerPush({
      event: DATALAYER_EVENTS.PROJECTS,
      projectAction: "create project"
    });
  };

  const itemAlreadyAdded = (portfolio) => {
    //check if item already is operationComplete
    let property = "";
    if (submissionMode === PORTFOLIO_TYPE.PRODUCT) {
      property = "productId";
    }
    if (submissionMode === PORTFOLIO_TYPE.MEDIA) {
      property = "imageId";
    }
    if (submissionMode === PORTFOLIO_TYPE.MATERIAL) {
      property = "materialId";
    }
    return (
      portfolio.portfolioItems.findIndex((item) => {
        let prop1 = item[property];
        let prop2 = submissionContent.portfolioItem[property];
        return prop1 === prop2;
      }) > -1
    );
  };

  return (
    <div className={classList}>
      {submissionContent && (
        <Fragment key={submissionContent.id}>
          {submissionMode === PORTFOLIO_TYPE.MEDIA && (
            <div className="portfolio-submission__preview">
              {/* <img src={submissionContent.imageSrc} alt="" /> */}
              <LazyLoadImage
                placeholderSrc={submissionContent.imageSrcLow} // use normal <img> attributes as props
                src={submissionContent.imageSrcHigh} // use normal <img> attributes as props
                effect="blur"
              />
            </div>
          )}

          {submissionMode === PORTFOLIO_TYPE.PRODUCT && (
            <>
              {productData && (
                <div className="portfolio-submission__preview">
                  <PortfolioSubmissionProduct {...productData} />
                </div>
              )}
            </>
          )}

          {submissionMode === PORTFOLIO_TYPE.MATERIAL && (
            <div className="portfolio-submission__preview">
              <LazyLoadImage
                placeholderSrc={submissionContent.imageSrcLow} // use normal <img> attributes as props
                src={submissionContent.imageSrcHigh} // use normal <img> attributes as props
                effect="blur"
              />
            </div>
          )}

          <div className="portfolio-submission__header">
            <h3>{texts.saveToProject}</h3>
            {creationComplete && (
              <span className="success">
                <CheckmarkLargeSvg /> {texts.operationComplete}
              </span>
            )}
          </div>

          <ul className="portfolio-submission-list" onMouseLeave={listBlurHandler}>
            {isCreating && <PortfolioSubmissionCreateItem saveHandler={createSaveHandler} />}

            {portfoliosList?.length > 0 ? (
              <>
                {portfoliosList.map((item, index) => {
                  return (
                    <PortfolioSubmissionListItem
                      key={`portfolioListItem${index}`}
                      data={item}
                      portfolioIndex={index}
                      clickHandler={portfolioClickHandler}
                      hoverHandler={portfolioHoverHandler}
                      isActive={index === chosenPortfolioIndex}
                      isAvailable={!itemAlreadyAdded(item)}
                    />
                  );
                })}
              </>
            ) : (
              <li>
                <p>{texts.noPortfolios}</p>
              </li>
            )}
          </ul>

          {userAuthenticated ? (<button className="portfolio-submission__create" onClick={createClickHandler}>
            <span className="icon">
              <PlusSvg />
            </span>
            <span className="label">{texts.createNewProject}</span>
          </button>)
            :
            (<>
              <LoginLink
                linkClass="portfolio-submission__create"
                action={OVERLAY.LOGIN}
                linkText={texts.loginButton}
                showArrow={false}
                popupUrl={placeholderReplace(
                  process.env.REACT_APP_IDENTITY_LINK,
                  {
                    locale: sc_lang,
                    redirectUri:
                      process.env.REACT_APP_IDENTITY_REDIRECT,
                  }
                )}
              />
              <div className="portfolio-submission__no-login-notice">{texts.noLoginNotice}</div>
            </>
            )}
        </Fragment>
      )}
    </div>
  );
};

const PortfolioSubmissionListItem = ({
  data,
  portfolioIndex,
  clickHandler,
  hoverHandler,
  isActive,
  isAvailable,
}) => {
  const { t } = useTranslation();
  const texts = {
    items: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Items"),
    wishList: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Wishlist"),
  };

  const iconWishlist = <HeartSmallSvg />;
  const iconProject = <BoxSvg />;

  const icon = data.isWishlist ? iconWishlist : iconProject;
  const name = data.name;
  const itemCount = data.portfolioItems.length;

  const classList = classnames({
    "portfolio-submission-list-item": true,
    "is-active": isActive,
    "is-unavailable": !isAvailable,
  });

  return (
    <li className={classList}>
      <button
        className="portfolio-submission-list-item__inner"
        onClick={() => {
          clickHandler(portfolioIndex, isAvailable);
        }}
        onMouseEnter={() => {
          hoverHandler(portfolioIndex, isAvailable);
        }}
      >
        <span className="icon">{icon}</span>
        <span className="name">{name === PORTFOLIO_WISHLIST_NAME ? texts.wishList : name}</span>
        <span className="count">
          {itemCount} {texts.items}
        </span>
        {!isAvailable && (
          <span className="action">
            <CheckmarkLargeSvg />
          </span>
        )}
        {isAvailable && (
          <span className="action">
            <PlusSvg />
          </span>
        )}
      </button>
    </li>
  );
};

const PortfolioSubmissionCreateItem = ({ saveHandler }) => {
  const { t } = useTranslation();
  const texts = {
    projectName: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_ProjectName"),
    save: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Save"),
    tooShortError: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_TooShort"),
  };

  const iconProject = <BoxSvg />;

  const icon = iconProject;

  const classList = classnames({
    "portfolio-submission-list-item": true,
    "portfolio-submission-list-item--create": true,
  });

  const [name, setName] = useState("");
  const [isValidating, setIsValidating] = useState(false);

  const saveClickHandler = () => {
    setIsValidating(true);
    if (name.length >= 3 && saveHandler) saveHandler(name);
  };
  const nameInputHandler = (e) => {
    setIsValidating(false);
    setName(e.target.value);
  };

  return (
    <div className={classList}>
      <div className="portfolio-submission-list-item__inner">
        <span className="icon">{icon}</span>
        <input
          className="input"
          type="text"
          placeholder={texts.projectName}
          onChange={nameInputHandler}
        />
        {isValidating && name.length < 5 && <span className="error">{texts.tooShortError}</span>}

        <button className="save" onClick={saveClickHandler}>
          {texts.save}
        </button>
      </div>
    </div>
  );
};

const PortfolioSubmissionProduct = ({ configurationId, displayName_t, fullName_t, imageUrl_s }) => {
  return (
    <div className="portfolio-submission-product">
      <div className="portfolio-submission-product__image">
        {configurationId && <ProductImage imageId={configurationId} imageUrl={imageUrl_s} />}
      </div>
      <div className="portfolio-submission-product__text">
        {displayName_t && <h3>{displayName_t}</h3>}
        <p>{fullName_t}</p>
        <ProductPrice configurationId={configurationId} />
      </div>
    </div>
  );
};

export default PortfolioSubmissionController;
