import React from "react";

import "./message-block.scss";
const MessageBlock = ({
  trumpet,
  headline,
  bodyText,
  buttonText,
  buttonHref,
  onClickHandler=null,
  ActionButton=null
}) => {
  return (
    <div className="message-block">
      <div className="message-block__inner">
        {trumpet && <h5>{trumpet}</h5>}
        {headline && <h1>{headline}</h1>}
        {bodyText && <p>{bodyText}</p>}
        {ActionButton && (
          <div className="mt3">
          { ActionButton }
          {buttonHref && buttonText && (
            <div className="mt1">
              <a className="nav-link" href={buttonHref} onClick={onClickHandler}>
                <span className="text">{buttonText}</span>
              </a>
            </div>
          )}
          </div>
        )}
        <div className="mt3">
          {buttonHref && buttonText && !ActionButton && (
            <a className="nav-button is-outlined" href={buttonHref} onClick={onClickHandler}>
              <span className="text">{buttonText}</span>
            </a>
          )}

        </div>
      </div>
    </div>
  );
};
MessageBlock.propTypes = {};

export default MessageBlock;
