import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

import Select from "../forms/Select";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE, COOKIES } from "@constants";
import { log } from "@assets/js/utils";

import useGlobalStore, {
  useMarketInfo,
  useCommerceMarket,
  useCommerceMarketSupportsEcom,
  useShowNotificationGeolocation,
  useUserManuallyChosenCountry,
  useLocationConflict,
  useNotInCommerceMarketText
} from "@store/global";

import useBasketStore from "@store/basket";
import useStockStore from "@store/stock";

import "./country-selector.scss";

const CountrySelector = ({
  backgroundColorHex,
  onUserInteractionComplete,
  preselectedLanguage,
  preselectedCountry,
}) => {
  const { t } = useTranslation();

  const texts = {
    geoLabelYourLocation: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorMessageYourLocation"
    ),
    geoLabelLanguage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorMessageLanguage"
    ),
  };

  const [marketInfo] = useMarketInfo();
  const [, setUserManuallyChosenCountry] = useUserManuallyChosenCountry();

  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const clearStockList = useStockStore((state) => state.clearStockList);

  const [commerceMarket, setCommerceMarket] = useCommerceMarket();
  const [commerceMarketSupportsEcom, setCommerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();

  const basketId = useBasketStore((store) => store.basketId);
  const changeBasketMarketCountry = useBasketStore(
    (store) => store.changeBasketMarketCountry
  );
  const[notInCommerceMarketText] = useNotInCommerceMarketText();
  const [, setShowNotificationGeolocation] = useShowNotificationGeolocation();
  const [locationConflict] = useLocationConflict();
  const [chosenValues, setChosenValues] = useState({
    sc_lang: preselectedLanguage || "",
    commerceMarket: preselectedCountry || "",
  });

  const setHideVatField = useGlobalStore((store) => store.setHideVatField);

  useEffect(() => {
    if (!preselectedLanguage && !preselectedCountry) {
      setChosenValues((state) => ({
        ...state,
        sc_lang: sc_lang,
        commerceMarket: commerceMarket,
      }));
    }
  }, [sc_lang, commerceMarket, preselectedLanguage, preselectedCountry]);

  const submitHandler = () => {
    log("submit", chosenValues);
    if (chosenValues.commerceMarket) {
      setUserManuallyChosenCountry(true);

      let marketChanged =
        commerceMarket.toLowerCase() !==
          chosenValues.commerceMarket.toLowerCase() || locationConflict;
      let languageChanged =
        sc_lang.toLowerCase() !== chosenValues.sc_lang.toLowerCase() ||
        locationConflict;

      let onlyMarketChanged = marketChanged && !languageChanged;
      let onlyLanguageChanged = !marketChanged && languageChanged;
      
      let bothChanged = marketChanged && languageChanged;

      let newMarketSupportsEcom = getSupportsEComm(
        chosenValues.commerceMarket.toLowerCase()
      );

      const pn = window.location.pathname;
      const pathname =
        pn.indexOf(`/${sc_lang}`) === 0
          ? pn.replace(`/${sc_lang}`, `/${chosenValues.sc_lang}`)
          : `/${chosenValues.sc_lang}${pn}`;
      let newLangUrl = pathname;

      log(
        "CountrySelector | new market=" +
          chosenValues.commerceMarket.toLowerCase()
      );
      log(
        "CountrySelector | new language=" + chosenValues.sc_lang.toLowerCase()
      );

      log("CountrySelector | marketChanged=" + marketChanged);
      log("CountrySelector | languageChanged=" + languageChanged);
      log("CountrySelector | onlyMarketChanged=" + onlyMarketChanged);
      log("CountrySelector | onlyLanguageChanged=" + onlyLanguageChanged);
      log("CountrySelector | bothChanged=" + bothChanged);

      log("CountrySelector | newMarketSupportsEcom=" + newMarketSupportsEcom);
      log("CountrySelector | newLangUrl=" + newLangUrl);

      const cookies = new Cookies();
      cookies.set(COOKIES.market.name,
        chosenValues.commerceMarket.toLowerCase(),
        {
          maxAge: 365 * 24 * 3600,
          path: "/",
        }
      );

        if (marketChanged) {
          //clear stockList of active SKU to force fetching data again            
          clearStockList();
          
          setCommerceMarket(chosenValues.commerceMarket.toLowerCase());
          setCommerceMarketSupportsEcom(newMarketSupportsEcom);
          setHideVatField(getHideVatField(chosenValues.commerceMarket.toLowerCase()));
        } 

      if (onlyMarketChanged && newMarketSupportsEcom) {
        //update basket, get new prices, carry on
        changeBasketMarketCountry(
          chosenValues.commerceMarket.toLowerCase(),
          basketId
        );
        setShowNotificationGeolocation(null);
      } else if (onlyLanguageChanged) {
        //just set url, sitecore and jss loads all the rest
        log("SET URL",newLangUrl)
        window.location.pathname = newLangUrl;
      } else if (bothChanged) {
        if (newMarketSupportsEcom) {
          //need to set basket to new market BEFORE reloading
          changeBasketMarketCountry(
            chosenValues.commerceMarket.toLowerCase(),
            basketId,
            newLangUrl
          );
        } else {
          //just reload with new language
          log("SET URL",newLangUrl)
          window.location.pathname = newLangUrl;
        }
      } else {
        // assuming NOTHING CHANGED
        setShowNotificationGeolocation(null);
      }

      if (onUserInteractionComplete) onUserInteractionComplete();
    }
  };

  const getHideVatField = (market) => {
    let newMarket = marketInfo.markets.values.find(
      (item) => item.commerceMarket.value.toLowerCase() === market
    );

    return newMarket && newMarket.hideVatNumber.value === true;
  }
  const getSupportsEComm = (market) => {
    let newMarket = marketInfo.markets.values.find(
      (item) => item.commerceMarket.value.toLowerCase() === market
    );

    log("CountrySelector | newMarket", newMarket);

    return newMarket && newMarket.marketSupportsEcom.value === true;
  };

  const getCurrentLanguageValue = () => {
    const searchVal = preselectedLanguage
      ? preselectedLanguage
      : sc_lang.toLowerCase();
    const foundLang = marketInfo.languages.values.find(
      (item) =>
        item.sitecoreLanguage.value.languageCode.toLowerCase() === searchVal
    );
    if (foundLang) {
      return {
        id: marketInfo.languages.values.indexOf(foundLang),
        optionLabel: foundLang.name.value,
        optionValue: foundLang.sitecoreLanguage.value.languageCode,
      };
    }
    return null;
  };

  const getCurrentCountryValue = () => {
    const searchVal = preselectedCountry ? preselectedCountry : commerceMarket;
    const foundMarket = marketInfo.markets.values.find(
      (item) =>
        item.commerceMarket.value.toLowerCase() === searchVal.toLowerCase()
    );

    if (foundMarket) {
      let optionLabel = foundMarket.countryName.value;
      if(!foundMarket.hideCurrencyInCountrySelector.value){
        optionLabel += '/' + foundMarket.currency.value;
      }

      return {
        id: marketInfo.markets.values.indexOf(foundMarket),
        optionLabel: optionLabel,
        optionValue: foundMarket.commerceMarket.value,
      };
    }
    return null;
  };

  return (
    <div
      className="country-selector"
      style={
        backgroundColorHex && {
          "--country-selector-bgc": backgroundColorHex,
        }
      }
    >
      <span className="select-wrapper">
        {!locationConflict && (
          <label className="notification__label">{texts.geoLabelYourLocation}</label>
        )}
        {marketInfo?.markets?.values && (
          <Select
            value={getCurrentCountryValue()}
            options={marketInfo.markets.values.map((market) => {
              let optionLabel = market.countryName.value;
              if (!market.hideCurrencyInCountrySelector.value) {
                optionLabel += '/' + market.currency.value;
              }
              return {
                id: market.id,
                optionLabel: optionLabel,
                optionValue: market.commerceMarket.value,
              }
            })}
            name="country"
            changeHandler={(value) => {
              setChosenValues((state) => ({ ...state, commerceMarket: value }));
            }}
          />
        )}
      </span>
      <span className="select-wrapper">
        {!locationConflict && (
          <label className="notification__label">{texts.geoLabelLanguage}</label>
        )}
        {marketInfo?.languages?.values && (
          <Select
            value={getCurrentLanguageValue()}
            options={marketInfo.languages.values.map((language) => ({
              id: language.id,
              optionLabel: language.name.value,
              optionValue: language.sitecoreLanguage.value.languageCode,
            }))}
            name="languages"
            changeHandler={(value) =>
              setChosenValues((state) => ({ ...state, sc_lang: value }))
            }
          />
        )}
      </span>
      <ActionButton
        buttonType={BUTTONSTYLE.SOLID}
        text={t(
          "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorButton"
        )}
        isInverted={true}
        icon="ArrowSvg"
        clickHandler={submitHandler}
      />
      {notInCommerceMarketText && !getSupportsEComm(
        chosenValues.commerceMarket.toLowerCase()
      ) &&(
      <div className="notInMarketText" dangerouslySetInnerHTML={{__html: notInCommerceMarketText}}>
      </div>
      )}
    </div>
  );
};
CountrySelector.propTypes = {
  backgroundColorHex: PropTypes.string,
};

export default CountrySelector;
