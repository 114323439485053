import create from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";
import { OVERLAY } from "@constants";

const store = create(
  devtools(
    (set, get) => ({
      menuIsInverted: false,
      setMenuIsInverted: (active) => set({ menuIsInverted: active }),

      menuOverlayActive: OVERLAY.NONE,
      setMenuOverlayActive: (id) => set({ menuOverlayActive: id }),

      menuOverlayInstant: false,
      setMenuOverlayInstant: (value) => set({ menuOverlayInstant: value }),

      closeMainMenu: () =>
        set({
          currentMainMenuItem: null,
          menuOverlayActive: OVERLAY.NONE,
          currentMegaMenuCategoryItem: null
        }),

      mobileNavigationActive: null,
      setMobileNavigationActive: (active) => set({ mobileNavigationActive: active }),

      currentMainMenuItem: null,
      setCurrentMainMenuItem: (item) => set({ currentMainMenuItem: item }),

      currentMainMenuItemTitle: null,
      setCurrentMainMenuItemTitle: (title) => set({ currentMainMenuItemTitle: title }),

      currentMegaMenuCategoryItem: null,
      setCurrentMegaMenuCategoryItem: (item) => set({ currentMegaMenuCategoryItem: item }),

      currentMenuOverlayMediaItem: null,
      setCurrentMenuOverlayMediaItem: (item) => set({ currentMenuOverlayMediaItem: item }),

      menuOverlayMediaQueue: [],
      pushMenuOverlayMediaQueue: (item) =>
        set({ menuOverlayMediaQueue: [...get().menuOverlayMediaQueue, item] }),
      clearMenuOverlayMediaQueue: () => set({ menuOverlayMediaQueue: [] }),

      globalTools: null,
      setGlobalTools: (value) => set({ globalTools: value }),

      activeMobileRootItems: [],
      setActiveMobileRootItems: (id) =>
        set((state) => {
          const d = state.activeMobileRootItems;
          const i = d.findIndex((item) => item === id);

          return {
            activeMobileRootItems:
              i > -1
                ? produce(d, (draft) => {
                    draft.splice(i, 1);
                  })
                : produce(d, (draft) => {
                    draft.push(id);
                  }),
          };
        }),
    }),
    "Header"
  )
);

/** Public  Exports */
// menu overlay
export const useMenuOverlayActive = () => [
  store((store) => store.menuOverlayActive),
  store((store) => store.setMenuOverlayActive),
];
export const useMenuOverlayInstant = () => [
  store((store) => store.menuOverlayInstant),
  store((store) => store.setMenuOverlayInstant),
];
export const useMenuOverlayMediaQueue = () => [
  store((store) => store.menuOverlayMediaQueue),
  store((store) => store.pushMenuOverlayMediaQueue),
  store((store) => store.clearMenuOverlayMediaQueue),
];

// menuIsInverted
export const useMenuIsInverted = () => [
  store((store) => store.menuIsInverted),
  store((store) => store.setMenuIsInverted),
];

// active mobileRootItem
export const useActiveMobileRootItem = () => [
  store((store) => store.activeMobileRootItems),
  store((store) => store.setActiveMobileRootItems),
];

export default store;
