import create from "zustand";
import { devtools } from "zustand/middleware";

const store = create(
  devtools((set) => ({
    videoType: null,
    setVideoType: (value) => set({ videoType: value }),
    
    videoId: null,
      setVideoId: (value) => set({ videoId: value }),
    }),
    
    "Video"
  )
);

export const useVideoType = () => [
    store((store) => store.videoType),
    store((store) => store.setVideoType),
];

export const useVideoId = () => [
    store((store) => store.videoId),
    store((store) => store.setVideoId),
];


export default store;
