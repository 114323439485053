import React from "react";
import { motion } from "framer-motion";
import useHeaderStore from "@store/header";
import useBasketStore from "@store/basket";
import { OVERLAY } from "@constants";

import "./basket-icon.scss";

const dotVariants = {
  active: { scale: 1 },
  inActive: { scale: 0 },
};

const BasketIcon = (props) => {
  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);
  const hasProducts = useBasketStore((store) => store.hasProducts);
  return (
    <button
      className="main-header__list-button"
      onClick={() => setMenuOverlayActive(OVERLAY.BASKET)}
    >
      <motion.svg
        animate={hasProducts ? "active" : "inActive"}
        className="svg-icon"
        width="15"
        height="19"
        viewBox="0 0 15 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11 5V0H4V5H0V19H15V5H11ZM5 1H10V5H5V1ZM14 18H1V6H4V10H5V6H10V10H11V6H14V18Z" />
        <motion.path
          d="M13 13.5C13 11.567 14.567 10 16.5 10C18.433 10 20 11.567 20 13.5C20 15.433 18.433 17 16.5 17C14.567 17 13 15.433 13 13.5Z"
          variants={dotVariants}
        />
      </motion.svg>
    </button>
  );
};

export default BasketIcon;
