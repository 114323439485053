import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import useBasketStore from "@store/basket";
import "./quantity-selector.scss";

const QuantitySelector = ({ 
  defaultValue, 
  changeHandler, 
  isLocked, 
  maxValue = 0, 
  minValue = 0, 
  totalStock = 0, 
  showQuantityValidationModal = false, 
  disableInputChange = false,
  step =1
 }) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [showRemove] = useState(false);

  const setAvailableStockExceeded = useBasketStore((store) => store.setAvailableStockExceeded);
  const setAvailableStockQuantity = useBasketStore((store) => store.setAvailableStockQuantity);

  const inputRef = useRef();

  const updateTimeoutHandle = useRef();

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const onChange = (value) => {
    let oldValue = currentValue;

    value = getMinValue(getMaxValue(value));

    setCurrentValue(value);
    clearTimeout(updateTimeoutHandle.current);

    updateTimeoutHandle.current = setTimeout(() => {
      inputRef.current.blur();

      if (value.length === 0) {
        value = defaultValue;
      }
      if (!isNaN(value)) {
        changeHandler(Number(value), Number(oldValue));
      } else {
        setCurrentValue(defaultValue);
        changeHandler(defaultValue);
      }
    }, 1000);
  };

  const getMaxValue = (value) => {
    if (maxValue > 0 && value > maxValue) { 
      value = Number(maxValue);
      if(showQuantityValidationModal){
        let stock = totalStock;
        if(stock == 0){
          stock = value;
        }
        setAvailableStockExceeded(true);
        setAvailableStockQuantity(stock);
      }
    }
    return value;
  }
  const getMinValue = (value) => {
    if (minValue > 0 && value < minValue) {
      value = Number(minValue);
    }
    return value;
  }

  const onMinusHandler = () => {
    inputRef.current.blur();
    let newVal = Number(inputRef.current.value) - step;
    newVal = getMinValue(getMaxValue(newVal));
    changeHandler(newVal, Number(inputRef.current.value));
  };

  const onPlusHandler = () => {
    inputRef.current.blur();
    let newVal = Number(inputRef.current.value) + step;
    newVal = getMinValue(getMaxValue(newVal));
    changeHandler(newVal, Number(inputRef.current.value));
  };

  const classList = classnames({
    "quantity-selector": true,
    "disable-input-change": disableInputChange,
    "is-locked": isLocked,
  });

  return (
    <div className={classList}>
      {showRemove && (
        <button
          onClick={() => changeHandler(0)}
          className="mini-basket-product__link quantity-selector__remove-button"
        >
          Remove product?{" "}
        </button>
      )}
      <div className="quantity-selector__inner">
        <button
          className="quantity-selector__minus"
          onClick={({ target: { value } }) => onMinusHandler(value)}
          type="button"
        >
          －
        </button>

        <input
          ref={inputRef}
          type="tel"
          disabled={isLocked}
          value={currentValue}
          onChange={({ target: { value } }) => onChange(value) }
          onInput={({ target: { value } }) => onChange(value) }
        />

        <button
          className="quantity-selector__plus"
          onClick={({ target: { value } }) => onPlusHandler(value)}
          type="button"
        >
          ＋
        </button>
      </div>
    </div>
  );
};

QuantitySelector.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  changeHandler: PropTypes.func,
};

export default QuantitySelector;
