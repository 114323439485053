import React, { useState } from "react";
import classnames from "classnames";

import "./textarea.scss";

const Textarea = ({
  register,
  name,
  label = "default label",
  value,
  placeHolder,
  disabled,
  required,
  autoComplete,
  error,
  id,
  isInverted,
  ariaDescription,
  changeHandler,
  blurHandler,
}) => {
  const [val, setVal] = useState(value);

  const onChangeHandler = (target) => {
    setVal(target.value);
    if (changeHandler) changeHandler(target);
  };
  const onBlurHandler = (target) => {
    if (blurHandler) blurHandler(target.value);
    setVal(target.value);
  };

  const classList = classnames({
    textarea: true,
    "is-valid": val && !error,
    "textarea--inverted": isInverted,
    "is-invalid": error,
    "is-disabled": disabled,
  });

  return (
    <div className={classList}>
      <textarea
        className={`textarea__area ${val ? "" : "placeholder-shown"}`}
        name={name}
        disabled={disabled}
        required={required}
        placeholder={placeHolder}
        aria-describedby={ariaDescription}
        id={id}
        ref={register}
        onChange={({ target }) => onChangeHandler(target)}
        onBlur={({ target }) => onBlurHandler(target)}
        autoComplete={autoComplete}
        value={val}
      ></textarea>
      {label && (
        <label className="textarea__label" htmlFor={id}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default Textarea;
