import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import produce from "immer";

import globalErrorHandler from "@assets/js/global-error-handler";
import path from "@assets/js/browser-path";
import { STATUS } from "@constants";

const store = create(
  devtools(
    (set) => ({
      loadStatus: STATUS.LOADING_IDLE,

      stockList: {},

      fetchStockData: async (marketCountryCode, id) => {
        set({ loadStatus: STATUS.LOADING });

        if (!id || !marketCountryCode) return null;

        const response = await axios
          .get(
            path.join(process.env.REACT_APP_ROOTAPI, "Stockapi", "webstock", marketCountryCode, id)
          )
          .catch((error) => globalErrorHandler(error, "STOCK CHECK"));

        if (response) {
          set((state) => ({
            loadStatus: STATUS.LOADING_IDLE,
            stockList: produce(state.stockList, (tmpStockList) => {
              if (response.data) {
                if (response.data.length > 0) {
                  tmpStockList[id] = response.data[0];
                } else {
                  tmpStockList[id] = response.data;
                }
              }
            }),
          }));
        } else {
          set((state) => ({ loadStatus: STATUS.LOADING_IDLE }));
        }
      },

      clearStockList: () => {
        set((state) => ({ loadStatus: STATUS.LOADING_IDLE, stockList: {} }));
      }
    }),
    "Stock"
  )
);

export default store;
