import { INTERNATIONAL } from "@constants";
import { log } from "@assets/js/utils";

export default function formattedPrice(language, commerceMarket, currency, amount, withCurrency = true) {
  if (!language || !commerceMarket || (withCurrency && !currency) || isNaN(amount) ) {
    return "–";
  } else {
    language=language.split('-')[0];
    let locale = commerceMarket.toLowerCase() === INTERNATIONAL ? process.env.REACT_APP_COMMERCE_FALLBACK_LOCALE :`${language}-${commerceMarket.toUpperCase()}`;
    if(withCurrency){
      const nfWithCurrency = new Intl.NumberFormat(locale, 
      {
        style: "currency",
        currency: currency,
        currencyDisplay: "code",
        maximumFractionDigits: 2
      });
      let tp = nfWithCurrency.format(amount);
      return tp;
    }
    else
    {
      const nfWithoutCurrency = new Intl.NumberFormat(locale, { maximumFractionDigits: 0 });
      let tp = nfWithoutCurrency.format(amount);
      return tp;
    }
  }
}
