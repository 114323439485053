/* eslint-disable */
const contentApiHost = process.env.REACT_APP_CONTENTAPI.substr(-1) === '/' ? process.env.REACT_APP_CONTENTAPI.slice(0, -1) : process.env.REACT_APP_CONTENTAPI

module.exports = {
  "sitecoreApiKey": process.env.REACT_APP_SITECORE_API_KEY,
  "sitecoreApiHost": process.env.REACT_APP_SITECORE_API_HOST,
  "contentApiHost": contentApiHost,
  "jssAppName": process.env.REACT_APP_SITECORE_APP_NAME,
  "defaultLanguage": process.env.REACT_APP_SITECORE_DEFAULT_LANGUAGE,
  // "graphQLEndpoint": https://contentapi.fritzhansen.com/api/ConsumersAndProfessionals?sc_apikey={B55C32E4-DA2E-4AE1-83D3-200B56F23564},
  "graphQLEndpoint": `${contentApiHost}/api/${process.env.REACT_APP_SITECORE_APP_NAME}?sc_apikey=${process.env.REACT_APP_SITECORE_API_KEY}`,
  "graphQLEndpointPath": process.env.REACT_APP_SITECORE_GRAPHQL_ENDPOINT_PATH,
  "dictionaryEndpoint": process.env.REACT_APP_SITECORE_DICTIONARY_ENDPOINT,
};