import React from "react";
import "./icons.scss";

const mapMarker = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--map-marker ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="12"
    height="19"
    viewBox="0 0 12 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 5C6.55 5 7 5.45 7 6C7 6.55 6.55 7 6 7C5.45 7 5 6.55 5 6C5 5.45 5.45 5 6 5ZM6 4C4.9 4 4 4.9 4 6C4 7.1 4.9 8 6 8C7.1 8 8 7.1 8 6C8 4.9 7.1 4 6 4Z" />
    <path d="M6.00004 0C1.62004 0 -1.27996 4.53 0.570044 8.49L5.50004 19H6.50004L11.43 8.49C13.28 4.53 10.38 0 6.00004 0ZM10.49 8.08L6.00004 17.64L1.51004 8.08C0.770044 6.48 0.910044 4.66 1.88004 3.2C2.82004 1.8 4.32004 1 6.00004 1C7.68004 1 9.18004 1.8 10.12 3.2C11.09 4.66 11.23 6.48 10.49 8.08Z" />
  </svg>
);
export default mapMarker;
