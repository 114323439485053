import ViewportSizes from "./viewport-sizes";

function mediaChange(callback) {
  let mqls = [];
  if (process.browser) {
    Object.keys(ViewportSizes).forEach((name) => {
      let viewport = ViewportSizes[name];
      let boundLow = viewport.boundLow ? viewport.boundLow : 0;
      let boundHigh = viewport.boundHigh ? viewport.boundHigh : 100000;

      let mql = window.matchMedia(
        `(min-width: ${boundLow}px) and (max-width: ${boundHigh}px)`
      );

      mqls.push(mql);

      mql.addListener((e) => {
        if (e.matches) {
          try {
            callback(viewport);
          } catch {
            console.warn("NO CALLBACK");
          }
        }
      });
    });
  }
}
export default mediaChange;
