import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import classnames from "classnames";

import "./tag-list-button.scss";

const TagListButton = ({ fields = {}, tagUpdateHandler }) => {
  const listClasses = classnames({
    "tag-list-button": true,
    "is-active": fields.active,
  });

  return (
    <>
      {fields && (
        <button
          className={listClasses}
          onClick={() => {
            if (tagUpdateHandler) tagUpdateHandler(fields.id);
          }}
        >
          <span>
            <Text
              field={
                fields?.title?.field || fields?.title || fields?.fields?.title
              }
            />
          </span>
        </button>
      )}
    </>
  );
};

export default TagListButton;
