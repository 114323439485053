export const defaultTransition = {
  ease: [0.25, 0.01, 0.25, 1],
  duration: 1.1,
};
export const opacityVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

export const collapseVariant = {
  hidden: {
    height: 0,
    transition: {
      duration: 0.6,
      when: "afterChildren",
    },
  },
  visible: {
    height: "auto",

    transition: {
      duration: 0.6,
      ease: "easeOut",
      when: "afterChildren",
    },
  },
};

export const containerMotion = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const itemMotion = {
  initial: { x: 20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: { x: 20, opacity: 0 },
};
export const itemUpMotion = {
  initial: { y: 10, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: { y: 10, opacity: 0 },
};

export const containerMotionDelayed = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

export const itemMotionDelayed = {
  initial: { x: 20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
  },
};

export const defaultReveal = {
  initial: { opacity: 0, x: -40 },
  exit: { opacity: 0, x: 40 },
  enter: { opacity: 1, x: 0 },
  transition: defaultTransition,
};

export const revealFromTop = {
  initial: { opacity: 0, y: -40 },
  exit: { opacity: 0, y: 40 },
  enter: { opacity: 1, y: 0 },
  transition: defaultTransition,
};
export const revealFromRight = {
  initial: { opacity: 0, x: 40 },
  exit: { opacity: 0, x: -40 },
  enter: {
    opacity: 1,
    x: 0,
  },
  transition: defaultTransition,
};
export const defaultOpacity = {
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  enter: { opacity: 1 },
  transition: defaultTransition,
};
