import React, { useEffect, useState } from "react";
import {
  Placeholder,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import shallow from "zustand/shallow";
import { motion } from "framer-motion";
import { NOTIFICATION, SITE_MODE, OVERLAY } from "@constants";

import ValidateBasketModal from "./components/checkout/ValidateBasketModal";
import MarketChangeModal from "./components/MarketChangeModal";
import OutOfStockModal from "./components/OutOfStockModal";
import ImageModal from "./components/ImageModal";
import QRCodeModal from "./components/QRCodeModal";
import MyFritzHansenProductModal from "./components/MyFritzHansenProductModal";
import MyFritzHansenInvoiceModal from "./components/MyFritzHansenInvoiceModal";
import MyFritzHansenInfoModal from "./components/MyFritzHansenInfoModal";
import MyFritzHansenRegisterProductModal from "./components/MyFritzHansenRegisterProductModal";
import UniqueMaterialUnavailableModal from "./components/UniqueMaterialUnavailableModal";
import VideoPlayerModal from "./components/VideoPlayerModal";
import PresentationModal from "./components/PresentationModal";
import ContentModal from "./components/ContentModal";
import AccountDetailsOptOutModal from "./components/AccountDetailsMarketingOptOutModal";

import SiteHeader from "./components/SiteHeader";
import ModeSwitch from "./components/ModeSwitch";
import MessageBlock from "./components/MessageBlock";
import ActionButton from "./components/ActionButton";

import NotificationGeolocation from "./components/notifications/NotificationGeolocation";
import NotificationsController from "./components/notifications/NotificationsController";

import { useMenuOverlayActive } from "@store/header";
import { 
  useCurrentSiteMode, 
  useDeliveryTextsFallback, 
  useNotInCommerceMarketText, 
  useUserAuthenticated,
  useCommerceMarket 
} from "@store/global";
import useNotificationStore from "@store/notifications";

import { placeholderReplace } from "@assets/js/utils";
import isCheckoutLayout from "@assets/js/is-checkout-layout";
import loginWindow from "@assets/js/login-window";
import { log } from "@assets/js/utils";

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, context }) => {
  const { t } = useTranslation();
  const [menuOverlayActive] = useMenuOverlayActive();
  const [currentSiteMode, setCurrentSiteMode] = useCurrentSiteMode();
  const [commerceMarket] = useCommerceMarket();
  const [setActiveNotification] = useNotificationStore(
    (state) => [state.setActiveNotification],
    shallow
  );

  const [, setDeliveryTextsFallback] = useDeliveryTextsFallback();
  const [userAuthenticated, setUserAuthenticated] = useUserAuthenticated();
  const [restriced, setRestriced] = useState(false);
  const [, setNotInCommerceMarketText] = useNotInCommerceMarketText();

  const texts = {
    restrictedTrumpet: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Trumpet"
    ),
    restrictedButton: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Button"
    ),
    restrictedHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Headline"
    ),
    restrictedBody: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Body"
    ),
    login: t("FritzHansen_Project_ConsumersAndProfessionals_Account_Login"),
  };
  const classNames = classnames({
    layout__container: true,
    "is-inactive": menuOverlayActive !== OVERLAY.NONE ? true : false,
  });

  const residentialClasses = classnames({
    "mode-container": true,
    "is-inactive": currentSiteMode === SITE_MODE.PROFESSIONAL,
  });

  const professionalClasses = classnames({
    "mode-container": true,
    "is-inactive": currentSiteMode === SITE_MODE.RESIDENTIAL,
  });

  setNotInCommerceMarketText(context?.notInEcomMarket);
  /**
   * open the login window and set the userAuth status in the state
   */
  const promtForLogin = async () => {
    setRestriced(true);
    try {
      const jwtCookie = await loginWindow({
        url: placeholderReplace(process.env.REACT_APP_IDENTITY_LINK, {
          locale: context.language,
          redirectUri: process.env.REACT_APP_IDENTITY_REDIRECT,
        }),
        title: "xtf",
        w: "470",
        h: "675",
      });
      setRestriced(false);
      setUserAuthenticated(true, jwtCookie);
    } catch (err) {
      console.warn(err);
    }
  };
  useEffect(() => {
    // restricted page
    if (route?.fields?.pageIsRestricted?.value && !userAuthenticated) {
      setRestriced(true);
      // promtForLogin();
    } else {
      setRestriced(false);
    }
    // switch to proff/residential
    if (route.fields?.switchToProfessionalMode?.value) {
      setCurrentSiteMode(SITE_MODE.PROFESSIONAL);
      setActiveNotification({ type: NOTIFICATION.SITE_MODE });
    }
    try {
      document.body.classList.toggle(
        "no-modeswitch",
        isCheckoutLayout(route.layoutId)
      );
      if (window?.location?.hash?.length > 0) {
        //DO NOTHING! EXPECTED INPAGE ANCHOR!
        log("HASH | skip scroll to 0");
      } else {
        // SCROLL TO TOP
        log("HASH | no hash, scroll to top");
        window.scrollTo(0, 0);
      }
    } catch (err) { }
  }, [route, setActiveNotification, setCurrentSiteMode, userAuthenticated]);

  useEffect(() => {
    setDeliveryTextsFallback(context.deliveryTextsFallback);
  }, [context])
  useEffect(() => {
    // setIsOffset();
  }, [menuOverlayActive]);
  
  return (
    <>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            route.displayName ||
            route.name}
        </title>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      {/*
          VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
          If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
          For XM (CMS-only) apps, this should be removed.

          VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
          */}
      <VisitorIdentification />
      <NotificationsController />
      <MarketChangeModal />
      <ValidateBasketModal />
      <OutOfStockModal />
      <ImageModal />
      <QRCodeModal />
      {/* <UniqueMaterialUnavailableModal /> */}
      <VideoPlayerModal />
      <PresentationModal />
      <MyFritzHansenProductModal/>
      <MyFritzHansenInvoiceModal/>
      <MyFritzHansenInfoModal/> 
      <MyFritzHansenRegisterProductModal/>
      <AccountDetailsOptOutModal />
      <ContentModal fields={context?.contentModal} />

      {route && route.layoutId && !isCheckoutLayout(route.layoutId) && (
        <ModeSwitch />
      )}

      {route && route.layoutId && (
        <SiteHeader
          fields={route.fields}
          layoutId={route.layoutId}
          mode={currentSiteMode}
          basketNotification={context?.basketNotification}
          basketNotificationDisabledMarkets={context?.basketNotificationDisabledMarkets}
        />
      )}
      
      {!restriced ? (
        <>
          <motion.div
            className="layout"
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            {/* root placeholder for the app, which we add components to using route data */}

            {/* <VisionSlider fields={dummyData.VisionSlider.default.fields} /> */}

            <div className={classNames}>
              {currentSiteMode === SITE_MODE.RESIDENTIAL && (
                <div className={residentialClasses}>
                  <Placeholder
                    name="main-residential"
                    rendering={route}
                    {...context}
                    pageFields={route.fields}
                  />
                </div>
              )}
              {currentSiteMode === SITE_MODE.PROFESSIONAL && (
                <div className={professionalClasses}>
                  <Placeholder
                    name="main-professional"
                    rendering={route}
                    {...context}
                    pageFields={route.fields}
                  />
                </div>
              )}
            </div>
          </motion.div>
          {currentSiteMode === SITE_MODE.RESIDENTIAL && (
            <Placeholder
              name="main-residential-footer"
              rendering={route}
              {...context}
              pageFields={route.fields}
            />
          )}
          {currentSiteMode === SITE_MODE.PROFESSIONAL && (
            <Placeholder
              name="main-professional-footer"
              rendering={route}
              {...context}
              pageFields={route.fields}
            />
          )}
        </>
      ) : (
        <div className="layout">
          <MessageBlock
            trumpet={texts.restrictedTrumpet}
            headline={route.fields.pageTitle.value || texts.restrictedHeadline}
            bodyText={texts.restrictedBody}
            buttonText={texts.restrictedButton}
            buttonHref="/"
            ActionButton={
              <ActionButton
                text={`${texts.login} ${route.fields.pageTitle.value || texts.restrictedHeadline
                  }`}
                buttonType="ghost"
                customClass="nav-button ml2"
                clickHandler={promtForLogin}
              />
            }
          />
        </div>
      )}
      <NotificationGeolocation position={NOTIFICATION.GEO_BOTTOM} />
    </>
  );
};

export default Layout;
