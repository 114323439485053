function setImage(src, options) {
  if (!src || typeof src === "undefined" || src.length < 1) src = "";
  if (src.length && src.length > 0 && src.indexOf("?") > -1) {
    const searchParams = new URLSearchParams(src.slice(src.indexOf("?")));
    const url = src.slice(0, src.indexOf("?"));
    if (options.width) {
      searchParams.set("w", options.width);
    }
    if (options.height) {
      searchParams.set("h", options.height);
    } else {
      searchParams.delete("h");
    }
    return `${url}?${searchParams.toString()}`;
  }
  return src;
}
export default setImage;
