import axios from "axios";
import globalErrorHandler from "@assets/js/global-error-handler";
import globalStore from "@store/global";
import refreshTokenHandler from "./refresh-token-handler";
import Cookies from "universal-cookie";
import { COOKIES } from "@constants";
import { log } from "@assets/js/utils";

async function checkTokenHelper() {
    const cookies = new Cookies();
    const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_IDENTITY_HOST}/api/user/checktoken`,
        headers: {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
        },            
    }).catch((error) => {
        refreshTokenHandler();
    });

    
}

export default checkTokenHelper;
