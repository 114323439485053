import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { ArrowSmallSvg } from "@assets/js/icons";
import path from "@assets/js/browser-path";

import useHeaderStore, { useMenuOverlayMediaQueue } from "@store/header";
/**
 * @prop item { Object } - root object containing field and fields
 * @prop clickHandler { fn } - clickhandler function
 */
const MenuLink = ({ item, jss, clickHandler, active = false, className }) => {
  const closeMainMenu = useHeaderStore((store) => store.closeMainMenu);
  const setMobileNavigationActive = useHeaderStore(
    (store) => store.setMobileNavigationActive
  );

  const [, , clearMenuOverlayMediaQueue] = useMenuOverlayMediaQueue();

  const linkItem = jss ? jss : item.fields.find((x) => x.name === "Link");
  const link = linkItem?.jss?.value?.href;
  const linkType = linkItem?.jss?.value?.linktype;

  const showSubItems = item?.fields[item.fields.findIndex((x) => x.name === "Show SubItems")]?.value === "1";

  const name =
    linkItem?.jss?.value?.title ||
    linkItem?.jss?.value?.text ||
    item?.fields[item.fields.findIndex((x) => x.name === "Title")]?.value ||
    item?.name;

  const onClick = (e) => {
    e.preventDefault();
    if (clickHandler) {
      clickHandler(item.id);
    }
  };
  const [css, setCss] = useState({ [className]: true, "is-active": false });

  useEffect(() => {
    const pathname = link === window.location.pathname;
    setCss({ ...css, "is-active": pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {linkType?.toUpperCase() === "INTERNAL" && !showSubItems && (
        <NavLink
          to={link}
          className={classnames(css)}
          onClick={() => {
            setMobileNavigationActive(false);
            clearMenuOverlayMediaQueue();
            if(clickHandler) clickHandler(item.id, name, path.join(process.env.REACT_APP_ROOTAPI, link));
            closeMainMenu();
          }}
        >
          <span>{name}</span>
          <ArrowSmallSvg />
        </NavLink>
      )}
      {linkType?.toUpperCase() === "EXTERNAL" && !showSubItems && (
        <a className={classnames(css)} href={link} target={linkItem?.jss?.value?.target} onClick={() => {
          if (clickHandler) {
            setMobileNavigationActive(false);
            clearMenuOverlayMediaQueue();
            let url = link;
            if (url && !url.startsWith('http')) {
              url = path.join(process.env.REACT_APP_ROOTAPI, url);
            }
            if(clickHandler) clickHandler(item.id, name, url);
            closeMainMenu();
          } 
        }}>
          <span>{name}</span>
          <ArrowSmallSvg />
        </a>
      )}
      {showSubItems && (
        <a className={classnames(css)} href={link} onClick={onClick}>
          <span>{name}</span>
          <ArrowSmallSvg />
        </a>
      )}
    </>
  );
};
export default MenuLink;
