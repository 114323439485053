import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import useHeaderStore from "@store/header";

import MobileMegaMenu from "./MobileMegaMenu";
import DesktopMegaMenu from "./DesktopMegaMenu";

import "./mega-menu.scss";

const MegaMenu = ({ menu }) => {
  const currentMainMenuItem = useHeaderStore((store) => store.currentMainMenuItem);
  const mobileNavigationActive = useHeaderStore((store) => store.mobileNavigationActive);

  return (
    <>
      {(currentMainMenuItem || mobileNavigationActive) && (
        <AnimatePresence>
          <motion.div className="mega-menu" transition={{ duration: 0.3 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {mobileNavigationActive && <MobileMegaMenu menu={menu.mobile} />}
            {currentMainMenuItem && <DesktopMegaMenu menu={menu.desktop} />}
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default MegaMenu;
