import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
// import useScrollLock from "@assets/js/scrolllock";
import { disableScroll, enableScroll } from "@assets/js/scrolllock";
import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE } from "@constants";

import { placeholderReplace } from "@assets/js/utils";
import useBasketStore from "@store/basket";

import "./out-of-stock-modal.scss";

const OutOfStockModal = (props) => {
  const { t } = useTranslation();

  const texts = {
    quantitySetToMaxAvailable: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_QuantitySetToMaxAvailable"
    ),
    okButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_Ok"),
  };

  const availableStockExceeded = useBasketStore(
    (store) => store.availableStockExceeded
  );
  const setAvailableStockExceeded = useBasketStore(
    (store) => store.setAvailableStockExceeded
  );
  const [modalActive, setModalActive] = useState(false);

  const allowedQuantity = useBasketStore(
    (store) => store.availableStockQuantity
  );
  const setAllowedQuantity = useBasketStore(
    (store) => store.setAvailableStockQuantity
  );

  // const [disableScroll, enableScroll] = useScrollLock();

  useEffect(() => {
    if (availableStockExceeded && document) {
      setModalActive(true);
      disableScroll();
    }
  }, [availableStockExceeded]);

  const okButtonHandler = () => {
    setModalActive(false);
    setAvailableStockExceeded(false);
    setAllowedQuantity(-1);
    enableScroll();
  };

  return (
    <ModalOverlay
      className="out-of-stock-modal"
      openState={modalActive}
      closeHandler={okButtonHandler}
      content={
        <>
          <p>
            {placeholderReplace(texts.quantitySetToMaxAvailable, {
              allowedQuantity,
            })}
          </p>

          <ActionButton
            buttonType={BUTTONSTYLE.SOLID}
            isInverted={true}
            clickHandler={okButtonHandler}
            text={texts.okButton}
          />
        </>
      }
    />
  );
};

export default OutOfStockModal;
