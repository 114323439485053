export const getDiscountValue = (campaigns, commerceMarket, taxonomies, price, vat = 0) => {
    if (!campaigns) {
        return 0;
    }

    const marketCampaigns = campaigns[commerceMarket];
    if (!marketCampaigns) {
        return 0;
    }

    const activeTaxonomy = taxonomies.find(x => !!marketCampaigns.taxonomies[x])
    if (!activeTaxonomy) {
        return 0;
    }

    const campaignItem = marketCampaigns.taxonomies[activeTaxonomy];
    if (!campaignItem) {
        return 0;
    }

    //TODO: handle multiple awards
    const award = campaignItem.awards[0];
    if (!award) {
        return 0;
    }

    switch(award.type){
        case "PercentageOff":
            let percentage = award.amount / 100;
            let priceDifference = price * percentage;
            return price - priceDifference;
        case "AmountOff":           
            return price - (award.amount * (1 + vat));
        default:
            return 0;
    }
}