import React from "react";
import "./icons.scss";

const logo = ({ className, isInverted }) => (
  <svg
    viewBox="0 0 259 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon svg-icon--logo ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
  >
    <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h258.283v25H0V0z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.991 4V.645H0v23.71h3.605V14h12.226v-3.226H3.605V4h13.386zm23.48 14.097c-.438-2.775-1.504-4.29-3.291-5.033 2.383-.774 3.981-2.677 3.981-5.709 0-4.161-2.915-6.71-8.245-6.71H21.411v23.71h3.606v-9.903h7.335c2.822 0 4.076 1.483 4.514 4.612l.753 5.29h3.824l-.971-6.257zm-15.454-6.71V3.871h7.68c3.104 0 4.703 1.226 4.703 3.774 0 2.517-1.6 3.742-4.703 3.742h-7.68zm21.348 12.968h3.605V.645h-3.605v23.71zM74.015.645H53.167v3.387h8.622v20.323h3.605V4.032h8.62V.645zm7.304 20.42l13.01-17.84V.646h-17.65v3.29h12.728l-13.042 17.84v2.58h18.277v-3.29H81.319zM130.16.644v9.806h-13.386V.646h-3.605v23.71h3.605V13.87h13.386v10.484h3.605V.645h-3.605zm26.678 23.71h3.824L151.603.645h-4.766l-9.059 23.71h3.667l2.822-7.678h9.749l2.822 7.678zm-11.348-11l3.668-9.87 3.636 9.87h-7.304zM182.011.645l.063 20.258h-.22L170.349.645h-5.674v23.71h3.511l-.063-20.258h.22l11.505 20.258h5.674V.645h-3.511zM201.071 25c6.019 0 9.969-2.807 9.969-7.097 0-10.193-16.427-4.484-16.427-10.968 0-2.096 1.944-3.71 5.8-3.71 4.326 0 6.019 2.323 6.646 5.355l3.605-1.387C209.943 3.355 206.683 0 200.476 0c-5.581 0-9.531 2.806-9.531 7.097 0 10.193 16.427 4.484 16.427 10.967 0 2.097-1.943 3.71-6.238 3.71-4.922 0-6.834-2.355-7.461-5.42l-3.605 1.388c.721 3.903 3.981 7.258 11.003 7.258zm18.12-3.936v-7.322h12.226V10.58h-12.226V3.936h13.386V.646h-16.991v23.709h17.117v-3.29h-13.512zM254.772.645l.063 20.258h-.22L243.11.645h-5.674v23.71h3.511l-.063-20.258h.22l11.505 20.258h5.674V.645h-3.511z"
        fill="#000"
      />
    </g>
  </svg>
);
export default logo;
