/**
 * Emulates Node's `path` module. This module contains utilities for handling and
 * transforming file paths. **All** of these methods perform only string
 * transformations. The file system is not consulted to check whether paths are
 * valid.
 * @see http://nodejs.org/api/path.html
 * @class
 */
class path {
  /**
   * Normalize a string path, taking care of '..' and '.' parts.
   *
   * When multiple slashes are found, they're replaced by a single one; when the path contains a trailing slash, it is preserved. On Windows backslashes are used.
   * @example Usage example
   *   path.normalize('/foo/bar//baz/asdf/quux/..')
   *   // returns
   *   '/foo/bar/baz/asdf'
   * @param {String} p The path to normalize.
   * @return {String}
   */
  static normalize(p) {
    // Special case: '' -> '.'
    if (p === "") {
      p = ".";
    }
    // It's very important to know if the path is relative or not, since it
    // changes how we process .. and reconstruct the split string.
    var absolute = p.charAt(0) === path.sep;
    // Remove repeated //s
    p = path._removeDuplicateSeps(p);
    // Try to remove as many '../' as possible, and remove '.' completely.
    var components = p.split(path.sep);
    var goodComponents = [];
    for (var idx = 0; idx < components.length; idx++) {
      var c = components[idx];
      if (c === ".") {
        continue;
      } else if (
        c === ".." &&
        (absolute ||
          (!absolute &&
            goodComponents.length > 0 &&
            goodComponents[0] !== ".."))
      ) {
        // In the absolute case: Path is relative to root, so we may pop even if
        // goodComponents is empty (e.g. /../ => /)
        // In the relative case: We're getting rid of a directory that preceded
        // it (e.g. /foo/../bar -> /bar)
        goodComponents.pop();
      } else {
        goodComponents.push(c);
      }
    }

    // Add in '.' when it's a relative path with no other nonempty components.
    // Possible results: '.' and './' (input: [''] or [])
    // @todo Can probably simplify this logic.
    if (!absolute && goodComponents.length < 2) {
      switch (goodComponents.length) {
        case 1:
          if (goodComponents[0] === "") {
            goodComponents.unshift(".");
          }
          break;
        default:
          goodComponents.push(".");
      }
    }
    p = goodComponents.join(path.sep);
    if (absolute && p.charAt(0) !== path.sep) {
      p = path.sep + p;
    }
    return p;
  }

  /**
   * Join all arguments together and normalize the resulting path.
   *
   * Arguments must be strings.
   * @example Usage
   *   path.join('/foo', 'bar', 'baz/asdf', 'quux', '..')
   *   // returns
   *   '/foo/bar/baz/asdf'
   *
   *   path.join('foo', {}, 'bar')
   *   // throws exception
   *   TypeError: Arguments to path.join must be strings
   * @param [String,...] paths Each component of the path
   * @return [String]
   */
  static join(...paths) {
    // Required: Prune any non-strings from the path. I also prune empty segments
    // so we can do a simple join of the array.
    var processed = [];
    for (var i = 0; i < paths.length; i++) {
      var segment = paths[i];
      if (typeof segment !== "string") {
        throw new Error(
          "Invalid argument type to path.join: " + typeof segment
        );
      } else if (segment !== "") {
        processed.push(segment);
      }
    }
    return path.normalize(processed.join(path.sep));
  }

  /**
   * Checks if the given path is an absolute path.
   *
   * Despite not being documented, this is a tested part of Node's path API.
   * @param [String] p
   * @return [Boolean] True if the path appears to be an absolute path.
   */
  static isAbsolute(p) {
    return p.length > 0 && p.charAt(0) === path.sep;
  }

  /**
   * Unknown. Undocumented.
   */
  static _makeLong(p) {
    return p;
  }

  // The platform-specific file separator. BrowserFS uses `/`.
  static sep = "/";

  static _replaceRegex = new RegExp("//+", "g");

  static _removeDuplicateSeps(p) {
    p = p.replace(/([^:]\/)\/+/g, "$1");
    return p;
  }

  // The platform-specific path delimiter. BrowserFS uses `:`.
  static delimiter = ":";
}

export default path;
