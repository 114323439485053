import React from "react";
import Modal from "react-modal";
import { motion } from "framer-motion";
import { DEFAULT_TRANSITION } from "@constants";

import "./modal-overlay.scss";

const ModalOverlay = ({ content, className, openState, closeHandler, secondClassName, containerClassName = "" }) => {
  Modal.setAppElement("#root");
  return (
    <Modal
      overlayClassName={`modal-overlay__container ${containerClassName}`}
      className={`modal-overlay__box ${className} ${secondClassName}`}
      isOpen={openState}
      onRequestClose={closeHandler}
      contentLabel="Example Modal"
    >
      <motion.div
        className={`modal-overlay__content ${className}__content`}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: DEFAULT_TRANSITION,
        }}
        exit={{ opacity: 0, transition: DEFAULT_TRANSITION }}
      >
        {content}
      </motion.div>
    </Modal>
  );
};

export default ModalOverlay;
