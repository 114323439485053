import React from "react";
import "./icons.scss";

const burger = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--burger ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="24"
    height="10"
    viewBox="0 0 24 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v1H0zM0 9h24v1H0z" />
  </svg>
);
export default burger;
