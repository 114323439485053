import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

import globalErrorHandler from "@assets/js/global-error-handler";
import path from "@assets/js/browser-path";
import persist from "@assets/js/persist";
import { log } from "@assets/js/utils";
import { STATUS } from "@constants";

const activeCampaignsUrl = (commerceMarket) => path.join(process.env.REACT_APP_PRODUCTSAPI, "/CampaignDocument/campaigndocument", commerceMarket);

const expirationTimeMinutes = 60;

const timeStampHasExpired = (dt1, dt2) => {
  var diff = (dt2 - dt1) / 1000;
  diff /= 60;
  log(
    "CAMPAIGNS | in minutes: elapsed=" +
      Math.round(diff * 1000) / 1000 +
      ", expiration=" +
      expirationTimeMinutes
  );
  return diff > expirationTimeMinutes;
};

const store = create(
  devtools(
    persist(
      {
        key: "campaigns",
      },
      (set, get) => ({
        loadStatus: STATUS.READY,

        timeStamp: null,

        campaigns: {},

        fetchCampaigns: async (commerceMarket) => {
          if (commerceMarket && commerceMarket.length > 0) {
            let storeTS = get().timeStamp;
            const campaigns = get().campaigns;
            let todayTS = new Date().getTime();
            let updatedCampaigns;
            let campaignsResponse;

            if (storeTS && campaigns && campaigns.hasOwnProperty(commerceMarket.toLowerCase())) {
              let expired = timeStampHasExpired(storeTS, todayTS);

              if (expired) {
                log("CAMPAIGNS | timeStamp exists | too old – fetch");
                storeTS = todayTS;

                //if local data is old, load new
                campaignsResponse = await axios
                  .get(activeCampaignsUrl(commerceMarket))
                  .catch((error) => globalErrorHandler(error, "CAMPAIGNS GET, UPDATE"));

                if (campaignsResponse) {
                  var campaignsDictionary = {};
                  campaignsDictionary[commerceMarket] = campaignsResponse.data;
                  updatedCampaigns = campaignsDictionary;
                }
              } else {
                updatedCampaigns = get().campaigns;
              }
            } else {
              campaignsResponse = await axios
                .get(activeCampaignsUrl(commerceMarket))
                .catch((error) => globalErrorHandler(error, "CAMPAIGNS GET, NEW"));

              if (campaignsResponse) {
                storeTS = todayTS;

                var campaignsDictionary = {};
                campaignsDictionary[commerceMarket] = campaignsResponse.data;
                updatedCampaigns = campaignsDictionary;
              }
            }
            set({ campaigns: updatedCampaigns, timeStamp: storeTS });
          } else {
            globalErrorHandler("No commerce market value", "CAMPAIGNS GET, NEW");
          }
        },
      })
    ),
    "Campaigns"
  )
);

export default store;
