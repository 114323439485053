import React, { Fragment } from "react";
import CountrySelectButton from "../../../CountrySelectButton";
import { motion } from "framer-motion";
import { containerMotion, itemMotion } from "@assets/js/animation-variations";

import "./mobile-mega-menu-footer.scss";

const MobileMegaMenuFooter = ({ secondaryMenu, socialMenu, clickHandler }) => {
  return (
    <>
      <motion.nav
        className="mega-menu__footer-block"
        variants={containerMotion}
        initial="initial"
        animate="animate"
      >
        <div className="mega-menu__footer-block__navlist mb8">
          {secondaryMenu &&
            secondaryMenu.targetItems.map((item) => {
              return (
                <Fragment key={item.id}>
                  {item.link?.jss && (
                    <motion.a
                      href={item.link.jss.value.href}
                      variants={itemMotion}
                    >
                      <h2 className="mega-menu__footer-block__link-item">
                        {item.link.jss.value.text}
                      </h2>
                    </motion.a>
                  )}
                </Fragment>
              );
            })}
        </div>
        {socialMenu && (
          <div className="mega-menu__footer-block__so-me-group mb8">
            {socialMenu.targetItems.map((item) => {
              return (
                <Fragment key={item.id}>
                  {item.link?.jss && (
                    <motion.a
                      href={item.link.jss.value.href}
                      variants={itemMotion}
                    >
                      <span className="mega-menu__footer-block__so-me-link">
                        {item.link.jss.value.text}
                      </span>
                    </motion.a>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}

        <CountrySelectButton clickHandler={clickHandler} />
      </motion.nav>
    </>
  );
};

export default MobileMegaMenuFooter;
