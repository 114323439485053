import React from "react";
import "./icons.scss";

const webar = ({ className = "", isInverted }) => (
  <svg
    viewBox="0 0 22 21"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5.5H5v1H1.5V4h-1V.5zM21.5 20.5H17v-1h3.5V17h1v3.5zM21.5.5V4h-1V1.5H17v-1h4.5zM.5 20.5V17h1v2.5H5v1H.5zM11 1.921l7.5 4.37v7.983L11 19.067l-7.5-4.793V6.292l7.5-4.37zM4.5 6.866v6.86L11 17.88l6.5-4.154v-6.86L11 3.08 4.5 6.866z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 10.424L4.248 6.566l-.496.868L11 11.576l7.248-4.142-.496-.868L11 10.424z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18v-7h1v7h-1z" />
  </svg>
);
export default webar;
