import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { disableScroll, enableScroll } from "@assets/js/scrolllock";
import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE } from "@constants";
import { useCommerceMarket } from "@store/global";
import useBasketStore from "@store/basket";

import "./market-change-modal.scss";

const MarketChangeModal = (props) => {
  const { t } = useTranslation();
  const texts = {
    removalNeeded: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_RemovalNeeded"
    ),
    productsNeedingRemoval: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_ProductsNeedingRemoval"
    ),
    okButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_Ok"),
  };

  const [commerceMarket] = useCommerceMarket();
  const pendingRemovalProducts = useBasketStore(
    (store) => store.pendingRemovalProducts
  );
  const basketId = useBasketStore((store) => store.basketId);
  const resetBasket = useBasketStore((store) => store.resetBasket);
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (pendingRemovalProducts.length > 0 && document) {
      setModalActive(true);
      disableScroll();
    }
  }, [pendingRemovalProducts]);

  const getProductsList = () => {
    let str = "";
    pendingRemovalProducts.forEach((item) => {
      str += "• " + item;
    });
    return str;
  };

  const acceptBasketResetHandler = () => {
    resetBasket(commerceMarket, basketId);
    setModalActive(false);
    enableScroll();
  };

  return (
    <ModalOverlay
      className="market-change-modal"
      openState={modalActive}
      closeHandler={acceptBasketResetHandler}
      content={
        <>
          <h3>{texts.removalNeeded}</h3>
          <p>
            {texts.productsNeedingRemoval}
            <br />
            <br />
            {getProductsList()}
          </p>

          <ActionButton
            buttonType={BUTTONSTYLE.SOLID}
            isInverted={true}
            clickHandler={acceptBasketResetHandler}
            text={texts.okButton}
          />
        </>
      }
    />
  );
};
MarketChangeModal.propTypes = {};

export default MarketChangeModal;
