import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: NavButton.link,
      isButtonEnabled: true,
      isInverted: false,
      // contact: ContactLinksGroup.default,
      // phone: "+45 22 33 44 55",
      // email: "sdfsdfdf@dsfsdfds.com",
    },
    interactive: true,
    contactInfoAvailable: false,
    link: "#loremipsum",
  },
  hasButton: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: NavButton.outlined,
      isButtonEnabled: true,
      isInverted: false,
      // contact: ContactLinksGroup.default,
      // phone: "+45 22 33 44 55",
      // email: "sdfsdfdf@dsfsdfds.com",
    },
    interactive: true,
    contactInfoAvailable: false,
    link: "#loremipsum",
  },
  hasContact: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: {},
      isButtonEnabled: false,
      isInverted: false,
      // contact: ContactLinksGroup.default,
      phone: "+45 22 33 44 55",
      email: "sdfsdfdf@dsfsdfds.com",
    },
    interactive: false,
    contactInfoAvailable: true,
    link: "#loremipsum",
  },
};
