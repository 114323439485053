import React from "react";
import "./icons.scss";

const anniversary = ({ className, isInverted, fillHex, strokeHex }) => (
  <svg 
    className={`svg-icon svg-icon--anniversary ${className || ""} ${
        isInverted ? "is-inverted" : ""
    }`}
    width="82" height="81" viewBox="0 0 82 81" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.6">
<path d="M25.2517 33.7312V38.7393H34.6563V41.124H25.2517V48.7811H22.4768V31.2492H35.5491V33.7312H25.2517Z" fill={fillHex}/>
<path d="M57.4935 31.2492V48.7811H54.7205V41.0304H44.4231V48.7811H41.6501V31.2492H44.4231V38.5006H54.7205V31.2492H57.4935Z" fill={fillHex}/>
<path d="M38.3908 45.728H35.267V48.8177H38.3908V45.728Z" fill={fillHex}/>
<path d="M63.9229 45.728H60.7991V48.8177H63.9229V45.728Z" fill={fillHex}/>
<path d="M7.21265 22.7034C5.83545 21.8479 5.37143 20.2324 6.28462 18.8096C7.0159 17.6586 8.11284 17.3098 9.15223 17.609L8.96663 18.492C8.1481 18.2332 7.43537 18.459 6.93795 19.241C6.2939 20.2544 6.68181 21.3283 7.68037 21.9525C8.67894 22.5767 9.83712 22.4353 10.4645 21.4422C10.9582 20.6638 10.8357 19.9735 10.2399 19.2557L10.9823 18.705C11.7247 19.6229 11.8454 20.7409 11.1327 21.8607C10.2343 23.2725 8.58242 23.5552 7.21265 22.7034Z" fill={fillHex}/>
<path d="M16.8251 13.7686L17.3615 14.2973L14.5013 17.1373L10.6296 13.3354L13.4694 10.5064L14.0058 11.0333L11.7674 13.2638L12.8531 14.3285L14.8985 12.3L15.4145 12.8067L13.3636 14.8371L14.5589 16.0101L16.8251 13.7686Z" fill={fillHex}/>
<path d="M22.5622 9.58474L22.9984 10.2236L19.9155 12.2834L16.8623 7.81135L17.5694 7.34322L20.1865 11.1764L22.5622 9.58474Z" fill={fillHex}/>
<path d="M28.8358 6.70433L29.1327 7.39459L25.3965 8.96971L23.2564 4.01304L26.9686 2.44893L27.2655 3.13736L24.3404 4.37102L24.9399 5.76256L27.6107 4.63721L27.8966 5.2981L25.2239 6.42529L25.8846 7.95818L28.8358 6.70433Z" fill={fillHex}/>
<path d="M36.7889 4.03872C36.9615 4.95662 36.3899 5.69094 35.2206 5.9094L32.4105 6.42526L31.4175 1.11612L34.1849 0.609436C35.3523 0.396483 36.0873 0.888478 36.2265 1.68338C36.2823 1.93895 36.2523 2.20552 36.1411 2.4427C36.0299 2.67989 35.8436 2.87477 35.6103 2.99782C36.2377 3.07125 36.672 3.41087 36.7889 4.03872ZM32.3938 1.69072L32.6777 3.2071L34.5338 2.86748C35.2113 2.74264 35.4618 2.4122 35.3783 1.93489C35.2948 1.45758 34.9292 1.22627 34.2498 1.3511L32.3938 1.69072ZM35.9203 4.16172C35.8145 3.59078 35.3635 3.41822 34.7454 3.53204L32.8114 3.88635L33.1213 5.53857L35.0554 5.18426C35.6679 5.07228 36.0224 4.74734 35.9129 4.16172H35.9203Z" fill={fillHex}/>
<path d="M45.132 5.59002L44.2207 5.55147L44.0926 4.34168C44.0202 3.62571 43.7381 3.27508 43.0699 3.2402L41.3345 3.1686L41.238 5.42113L40.386 5.38441L40.6143 0L43.3372 0.11382C44.5974 0.165222 45.2638 0.772874 45.2229 1.72015C45.2289 2.0115 45.133 2.29595 44.9514 2.52541C44.7698 2.75488 44.5136 2.91528 44.2262 2.97951C44.642 3.16309 44.8796 3.52107 44.9557 4.15626L45.132 5.59002ZM43.1813 2.5536C43.9237 2.58481 44.3135 2.32229 44.3376 1.74952C44.3617 1.17675 43.9887 0.875679 43.2462 0.84447L41.4291 0.769202L41.3568 2.47834L43.1813 2.5536Z" fill={fillHex}/>
<path d="M51.5169 5.16588L49.2896 4.59311L48.1927 6.11683L47.3519 5.9002L50.7986 1.21156L51.8992 1.49427L52.6027 7.25136L51.7266 7.02555L51.5169 5.16588ZM51.4297 4.3618L51.1642 1.97525L49.7629 3.92855L51.4297 4.3618Z" fill={fillHex}/>
<path d="M60.322 5.61208L58.466 4.75293L56.4874 8.9404L55.7134 8.57324L57.7013 4.38577L55.8452 3.52661L56.1774 2.829L60.6468 4.90346L60.322 5.61208Z" fill={fillHex}/>
<path d="M63.7279 6.83479L64.435 7.31026L61.3614 11.7694L60.6561 11.294L63.7279 6.83479Z" fill={fillHex}/>
<path d="M71.6013 13.486L67.7649 17.3228L66.783 16.3627L68.1602 11.2224L68.1082 11.1692L64.8416 14.459L64.2495 13.8807L68.086 10.0439L69.0678 11.015L67.6906 16.1553L67.7426 16.2085L71.0111 12.9187L71.6013 13.486Z" fill={fillHex}/>
<path d="M74.7639 22.6372L72.4995 24.0489L72.0856 23.4046L72.7817 22.9676C72.0392 22.9842 71.4007 22.6005 70.9516 21.9065C70.0458 20.493 70.4857 18.9289 71.8796 18.0514C73.2735 17.1738 74.9291 17.4712 75.8534 18.9179C76.5736 20.0414 76.4232 21.152 75.7309 21.9506L75.0052 21.3999C75.5787 20.7885 75.6623 20.0671 75.1685 19.2979C74.5171 18.2808 73.3663 18.1578 72.3566 18.7912C71.3469 19.4245 70.9609 20.482 71.6402 21.5449C72.0986 22.259 72.9951 22.74 73.6967 22.3251L72.7687 20.8803L73.396 20.4875L74.7639 22.6372Z" fill={fillHex}/>
<path d="M5.54406 41.7408L0.0129924 41.829L0 40.9845L4.17243 40.9184C3.87731 40.6577 3.75296 40.2557 3.7344 39.5507L4.53622 39.5397C4.55107 40.5072 4.84804 40.9735 5.53292 41.0432L5.54406 41.7408Z" fill={fillHex}/>
<path d="M2.7675 49.9209C1.70769 50.1173 0.775947 49.3444 0.514242 47.9143C0.263674 46.5412 0.727689 45.6526 1.54436 45.2836L1.99909 46.0382C1.41072 46.33 1.09333 46.8643 1.25667 47.8024C1.42 48.7405 1.96011 49.1774 2.58932 49.0654C3.21853 48.9534 3.638 48.4449 3.46724 47.5123C3.33731 46.8037 3.12015 46.3576 2.72481 46.0767L2.69883 45.3571L5.6908 45.1368L6.38126 48.9094L5.63883 49.0415L5.08201 46.0216L3.57675 46.1263C3.91086 46.5055 4.12512 46.9734 4.19296 47.4719C4.46394 48.8855 3.87 49.7244 2.7675 49.9209Z" fill={fillHex}/>
<path d="M4.52312 52.346C6.04138 51.7512 7.51323 52.0743 8.09047 53.5136C8.67884 54.9822 7.81763 56.1406 6.29195 56.7391C4.76626 57.3376 3.30183 57.0108 2.72459 55.5715C2.13622 54.1029 2.99743 52.9445 4.52312 52.346ZM5.97085 55.957C7.14945 55.4944 7.73596 54.7032 7.36289 53.7761C6.98982 52.849 6.01725 52.6746 4.84422 53.1281C3.67118 53.5815 3.0791 54.3819 3.45031 55.309C3.82153 56.2361 4.79596 56.4178 5.97085 55.957Z" fill={fillHex}/>
<path d="M10.3735 64.2954L8.68637 65.5713L8.16668 64.9012L9.85198 63.6253L11.205 59.9537L11.7916 60.7119L10.6928 63.5684L13.7683 63.2655L14.3102 63.965L10.3735 64.2954Z" fill={fillHex}/>
<path d="M15.5779 71.5395L15.0694 72.0903L12.0644 69.3935L15.7338 65.3987L18.7165 68.0809L18.208 68.6316L15.8563 66.5204L14.8281 67.6403L16.9755 69.577L16.4874 70.1094L14.3381 68.1726L13.204 69.4063L15.5779 71.5395Z" fill={fillHex}/>
<path d="M21.6954 74.4161L19.7261 73.2375L18.2413 74.3867L17.4988 73.9424L22.1594 70.4232L23.132 71.0051L22.1427 76.7218L21.3669 76.2574L21.6954 74.4161ZM21.842 73.6212L22.2726 71.264L20.3627 72.7326L21.842 73.6212Z" fill={fillHex}/>
<path d="M29.619 79.674L28.7689 79.3637L29.012 78.1705C29.1587 77.4655 28.9935 77.047 28.3643 76.8248L26.7272 76.2447L25.9569 78.3669L25.1533 78.0823L26.9963 73.0008L29.5614 73.9187C30.7493 74.341 31.2022 75.1175 30.8792 76.0097C30.7971 76.29 30.6196 76.5336 30.3768 76.6992C30.1339 76.8648 29.8406 76.9423 29.5466 76.9184C29.8881 77.2177 30.0069 77.6271 29.8899 78.2568L29.619 79.674ZM28.6705 76.2006C29.3702 76.4485 29.8213 76.3126 30.0162 75.7729C30.211 75.2332 29.953 74.8366 29.2552 74.5906L27.542 73.983L26.9574 75.593L28.6705 76.2006Z" fill={fillHex}/>
<path d="M33.2105 78.9799L34.0995 78.7816C34.1589 79.4939 34.5357 80.085 35.6902 80.2392C36.698 80.3751 37.1973 80.0703 37.2623 79.5985C37.4627 78.1299 33.4313 78.9064 33.745 76.6062C33.8787 75.6387 34.8921 75.1375 36.2099 75.3064C37.6669 75.501 38.3276 76.362 38.3778 77.2505L37.4924 77.4488C37.433 76.7439 37.1063 76.1638 36.0911 76.0316C35.1853 75.9104 34.6879 76.2152 34.6229 76.687C34.4225 78.1556 38.4538 77.3791 38.1383 79.6775C38.0065 80.645 36.9857 81.1461 35.5732 80.9625C33.9213 80.7441 33.2587 79.8849 33.2105 78.9799Z" fill={fillHex}/>
<path d="M50.7502 80.2248C50.3475 80.2982 49.7795 80.1991 49.1633 79.9751C48.6661 80.4389 48.0371 80.7407 47.3611 80.8398C46.099 81.0693 45.2507 80.6562 45.0874 79.7677C44.9241 78.8792 45.4586 78.378 46.1157 77.9833C45.8294 77.6819 45.6278 77.3117 45.531 76.9093C45.3788 76.0943 45.828 75.3397 46.9026 75.1451C47.9773 74.9505 48.5731 75.5215 48.703 76.2301C48.8775 77.1682 48.0552 77.6161 47.2497 78.0384C47.7647 78.4699 48.3321 78.8362 48.9387 79.1288C49.2802 78.5781 49.3563 77.8438 49.2728 77.0213L50.0561 76.9314C50.1433 77.8126 50.0357 78.6974 49.6106 79.4097C49.9322 79.5439 50.2815 79.6005 50.6296 79.5749C51.0008 79.507 51.0602 79.2078 50.6797 78.1779L51.5057 78.3192C51.8936 79.3601 51.7135 80.0578 50.7502 80.2248ZM48.4599 79.6741C47.7721 79.3475 47.1332 78.9287 46.5611 78.4294C46.0934 78.7231 45.7723 79.0646 45.8707 79.5915C45.9523 80.0321 46.4071 80.3258 47.2534 80.1697C47.6891 80.1066 48.103 79.9403 48.4599 79.6851V79.6741ZM46.7987 77.6014C47.4669 77.2563 48.0423 76.9351 47.9402 76.3861C47.8622 75.9676 47.5504 75.7106 47.0029 75.8097C46.4553 75.9088 46.2289 76.2723 46.318 76.7551C46.3991 77.0778 46.565 77.3735 46.7987 77.6125V77.6014Z" fill={fillHex}/>
<path d="M62.2893 73.5662C62.7478 74.3904 62.436 75.2643 61.3947 75.8297L58.8909 77.1937L56.259 72.4647L58.7257 71.1227C59.7651 70.5554 60.6189 70.7959 61.0142 71.5027C61.1483 71.7279 61.2047 71.99 61.1749 72.2497C61.1451 72.5093 61.0307 72.7523 60.849 72.942C61.4578 72.8208 61.9775 73.0062 62.2893 73.5662ZM57.3689 72.707L58.1206 74.0582L59.7799 73.1549C60.385 72.8263 60.5224 72.4335 60.2867 72.0076C60.0509 71.5816 59.6333 71.4752 59.0282 71.8038L57.3689 72.707ZM61.4968 73.9554C61.2147 73.4468 60.734 73.423 60.1809 73.724L58.4659 74.664L59.2844 76.1326L61.0087 75.1945C61.5543 74.9008 61.7864 74.4767 61.4968 73.9554Z" fill={fillHex}/>
<path d="M68.7727 69.5384L69.2645 70.1093L66.1705 72.7106L62.6347 68.5984L65.7046 66.0155L66.1946 66.5864L63.7817 68.6223L64.7729 69.7752L66.9779 67.9247L67.4493 68.4754L65.2387 70.3351L66.3301 71.6037L68.7727 69.5384Z" fill={fillHex}/>
<path d="M71.4584 64.5779L73.1048 65.9071L72.5646 66.5606L70.9016 65.2315L66.9723 64.7799L67.583 64.0455L70.6548 64.4366L69.6469 61.547L70.2037 60.8641L71.4584 64.5779Z" fill={fillHex}/>
<path d="M73.5466 60.8184C72.1119 60.062 71.5513 58.4447 72.3197 57.0201C73.1012 55.568 74.7326 55.1751 76.1544 55.9186C77.5761 56.6621 78.1515 58.285 77.3812 59.7169C76.611 61.1488 74.9851 61.571 73.5466 60.8184ZM75.746 56.7025C74.7029 56.1517 73.5577 56.3739 73.0065 57.3983C72.4552 58.4226 72.91 59.4801 73.9586 60.0308C75.0073 60.5816 76.1451 60.3594 76.6926 59.3424C77.2402 58.3253 76.7947 57.2551 75.746 56.7025Z" fill={fillHex}/>
<path d="M76.0169 47.4097L81.2955 48.814L80.9243 50.1321L75.7273 51.4851L75.7088 51.5549L80.2134 52.7408L79.9963 53.5357L74.7306 52.1368L75.0907 50.8187L80.2877 49.4658L80.3062 49.3942L75.8016 48.2082L76.0169 47.4097Z" fill={fillHex}/>
<path d="M79.3634 39.8002C81.1897 39.881 82.0732 41.0449 81.9953 42.7375L81.9062 44.6743L76.4549 44.432L76.544 42.4658C76.6257 40.6851 77.5834 39.7213 79.3634 39.8002ZM79.3634 40.6594C78.0363 40.6006 77.3885 41.2927 77.3217 42.5392L77.2716 43.6315L81.1693 43.8151L81.2194 42.7136C81.2732 41.4653 80.6904 40.72 79.3541 40.6594H79.3634Z" fill={fillHex}/>
<path d="M33.5019 17.7503V23.221H32.6481V19.0923C32.3809 19.3787 31.9725 19.4962 31.2598 19.5035V18.7105C32.2398 18.7105 32.7149 18.4241 32.7966 17.7485L33.5019 17.7503Z" fill={fillHex}/>
<path d="M39.9387 21.7086C39.9387 22.5824 39.1591 23.3608 37.4961 23.3608C35.833 23.3608 35.0683 22.5897 35.0683 21.7086C35.0683 21.077 35.5138 20.5924 36.323 20.3648C35.5955 20.1591 35.2094 19.7332 35.2094 19.1678C35.2094 18.3747 35.9889 17.6624 37.5035 17.6624C39.0477 17.6624 39.7827 18.3601 39.7827 19.1531C39.7827 19.7039 39.4115 20.1371 38.6691 20.3574C39.5006 20.5924 39.9387 21.0697 39.9387 21.7086ZM39.0849 21.6865C39.0849 21.1358 38.5652 20.7246 37.5035 20.7246C36.4418 20.7246 35.9221 21.1431 35.9221 21.6865C35.9221 22.2299 36.4418 22.6412 37.5109 22.6412C38.58 22.6412 39.0849 22.2299 39.0849 21.6865ZM37.5035 20.049C38.4315 20.049 38.9364 19.7112 38.9364 19.2266C38.9364 18.7419 38.4167 18.4041 37.4961 18.4041C36.5755 18.4041 36.0706 18.7346 36.0706 19.2266C36.0706 19.7185 36.5903 20.049 37.5035 20.049Z" fill={fillHex}/>
<path d="M45.4476 18.4113C44.3488 19.5495 43.6509 21.0622 43.2575 23.2211H42.3443C42.7155 21.165 43.4208 19.689 44.4379 18.5728H41.8023V19.7624H40.9856V17.8238H45.4402L45.4476 18.4113Z" fill={fillHex}/>
<path d="M50.3625 22.4499V23.2209H45.908V22.6188L48.0758 20.9666C49.1078 20.1661 49.2934 19.8137 49.2934 19.4098C49.2934 18.859 48.8628 18.4552 48.1204 18.4552C47.1775 18.4552 46.7692 18.9839 46.6875 19.7549L45.856 19.4465C45.9896 18.4919 46.7395 17.6768 48.1352 17.6768C49.4493 17.6768 50.1992 18.3817 50.1992 19.3731C50.1992 19.9826 49.9839 20.4746 48.7663 21.3925L47.3705 22.4572L50.3625 22.4499Z" fill={fillHex}/>
<path d="M35.2541 62.3274V63.0984H30.7995V62.4963L32.9674 60.844C33.9994 60.0436 34.185 59.6911 34.185 59.2873C34.185 58.7365 33.7544 58.3326 33.012 58.3326C32.0691 58.3326 31.6607 58.8614 31.5791 59.6324L30.7476 59.324C30.8812 58.3694 31.631 57.5543 33.0268 57.5543C34.3409 57.5543 35.0907 58.2592 35.0907 59.2505C35.0907 59.86 34.8754 60.352 33.6579 61.2699L32.2621 62.3329L35.2541 62.3274Z" fill={fillHex}/>
<path d="M36.1376 60.3981C36.1376 58.7825 36.9839 57.5489 38.5505 57.5489C40.1467 57.5489 40.9188 58.7679 40.9188 60.3907C40.9188 62.0136 40.0724 63.238 38.5059 63.238C36.9097 63.2454 36.1376 62.0282 36.1376 60.3981ZM40.065 60.3981C40.065 59.1424 39.5379 58.3126 38.5282 58.3126C37.5185 58.3126 36.9914 59.1497 36.9914 60.3981C36.9914 61.6464 37.5185 62.4817 38.5282 62.4817C39.5379 62.4817 40.065 61.6519 40.065 60.3981Z" fill={fillHex}/>
<path d="M46.1603 62.3274V63.0984H41.7057V62.4963L43.8736 60.844C44.9056 60.0436 45.0912 59.6911 45.0912 59.2873C45.0912 58.7365 44.6606 58.3326 43.9181 58.3326C42.9753 58.3326 42.5669 58.8614 42.4853 59.6324L41.6537 59.324C41.7874 58.3694 42.5372 57.5543 43.933 57.5543C45.2471 57.5543 45.9969 58.2592 45.9969 59.2505C45.9969 59.86 45.7816 60.352 44.564 61.2699L43.1683 62.3329L46.1603 62.3274Z" fill={fillHex}/>
<path d="M51.5578 62.3275V63.0985H47.1032V62.4964L49.2711 60.8442C50.2994 60.0456 50.485 59.6931 50.485 59.2966C50.485 58.7458 50.0544 58.342 49.312 58.342C48.3691 58.342 47.9607 58.8707 47.8791 59.6417L47.0476 59.3333C47.1812 58.3787 47.9311 57.5636 49.3268 57.5636C50.6409 57.5636 51.3908 58.2685 51.3908 59.2599C51.3908 59.8694 51.1754 60.3614 49.9579 61.2793L48.5621 62.3422L51.5578 62.3275Z" fill={fillHex}/>
</g>

</svg>
);
export default anniversary;

