import React, { useState } from "react";
import * as icons from "@assets/js/icons";
import classnames from "classnames";
import getUniqueId from "@assets/js/get-unique-id";
// import { ArrowSmallSvg } from "@assets/js/icons";

import "./action-menu.scss";

const ActionMenu = ({ actionList, isActive, isDownload }) => {
  const classList = classnames({
    "action-menu": true,
    "is-active": isActive,
    "is-download": isDownload === true
  });

  return (
    <>
      {actionList.length > 0 && (
        <div className={classList}>
          {actionList.map((item) => {
            return <ActionMenuItem key={getUniqueId()} data={item} />;
          })}
        </div>
      )}
    </>
  );
};

const ActionMenuItem = ({ data }) => {
  const Icon = data.icon ? icons[data.icon] : null;
  const [showPrompt, setShowPrompt] = useState(false);

  const promptConfirmHandler = () => {
    data.callback();
    setShowPrompt(false);
  };
  const promptCancelHandler = () => {
    setShowPrompt(false);
  };
  const clickHandler = () => {
    if (data.prompt) {
      setShowPrompt(true);
    } else {
      data.callback();
    }
  };
  const classList = classnames({
    "action-menu__item": true,
    "is-prompting": showPrompt,
  });

  return (
    <div className={classList}>
      <div className="action-menu__item__inner">
        {data.href ? (
          <a className="action" href={data.href} onClick={(e) => {
            e.preventDefault();
            if(clickHandler) clickHandler();
            window.location = data.href;
          }}>
            {Icon && (
              <span className="icon">
                <Icon />
              </span>
            )}
            <span className="label">{data.label}</span>
          </a>
        ) : (
          <button className="action" onClick={clickHandler}>
            {Icon && (
              <span className="icon">
                <Icon />
              </span>
            )}
            <span className="label">{data.label}</span>
          </button>
        )}

        {data.prompt && (
          <div className="prompt">
            <button className="confirm" onClick={promptConfirmHandler}>
              {data.prompt.labelConfirm}
            </button>
            <button className="cancel" onClick={promptCancelHandler}>
              {data.prompt.labelCancel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ActionMenu.propTypes = {};

export default ActionMenu;
