import React, { useEffect, useState } from "react";
import ModalOverlay from "../ModalOverlay";
import { enableScroll, disableScroll } from "@assets/js/scrolllock";
import ActionButton from "../ActionButton";
import { useProduct, useMyFritzHansenProductModal } from "@store/myfritzhansen";
import { useTranslation } from "react-i18next";

const MyFritzHansenProductModal = () =>{
    const [modalActive, setModalActive] = useState(false);
    const [showMyFritzHansenModal, setShowMyFritzHansenModal] = useMyFritzHansenProductModal();
    const [deleteProduct,,productToBeDeleted,setproductToBeDelted] = useProduct();

    const { t } = useTranslation();

    const texts = {
        yes: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Yes"),
        no: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_No"),
        title: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_DeleteProductModal_Title"),
        text: t("FritzHansen_Project_ConsumersAndProfessionals_MyFritzHansen_DeleteProductModal_Text"),
      };    
   
    const cancelButtonHandler = () => {
        setShowMyFritzHansenModal(false);
        setModalActive(false);
        enableScroll();
        setproductToBeDelted("");
    };

    const deleteButtonHandler = () =>{
        deleteProduct(true,productToBeDeleted);
        setModalActive(false);
        enableScroll();
        setproductToBeDelted("");
        setShowMyFritzHansenModal(false);
    }

    useEffect(() => {
        if (showMyFritzHansenModal) {
            setModalActive(true);
            disableScroll();
        }
    }, [showMyFritzHansenModal]);

    return (
        <ModalOverlay
            className="qrcode-modal"
            openState={modalActive}
            closeHandler={cancelButtonHandler}
            content={
                <>
                    <h3>{texts.title}</h3>
                    <p className="text">{texts.text}</p>
                    <div className="button-modal">
                        <ActionButton
                            text={texts.yes}
                            buttonType="ghost"
                            isDisabled={false}
                            clickHandler={deleteButtonHandler}
                        />
                        <ActionButton
                            text={texts.no}
                            buttonType="ghost"
                            isDisabled={false}
                            clickHandler={cancelButtonHandler}
                        />
                    </div>
                </>
                
            }
        />
    );
}

export default MyFritzHansenProductModal