import React, { useEffect, useState } from "react";
import ModalOverlay from "../ModalOverlay";
import { enableScroll, disableScroll } from "@assets/js/scrolllock";
import ActionButton from "../ActionButton";
import store from "@store/myfritzhansen";
import { useTranslation } from "react-i18next";

import "./account-details-optout-modal.scss";

const AccountDetailsMarketingOptOutModal = () =>{
    const [modalActive, setModalActive] = useState(false);
    const showMarketingOptOutModal = store((state) => state.showMarketingOptOutModal);
    const setShowMarketingOptOutModal = store((state) => state.setShowMarketingOptOutModal);

    const { t } = useTranslation();
    const texts = {
        ok: t("FritzHansen_Project_ConsumersAndProfessionals_Account_MarketingOptOutModal_Ok"),
        title: t("FritzHansen_Project_ConsumersAndProfessionals_Account_MarketingOptOutModal_Title"),
        text: t("FritzHansen_Project_ConsumersAndProfessionals_Account_MarketingOptOutModal_Text")
    };    
   
    const closeModalHandler = () => {
        setShowMarketingOptOutModal(false);
        setModalActive(false);
        enableScroll();
    }

    useEffect(() => {
        if (showMarketingOptOutModal) {
            setModalActive(true);
            disableScroll();
        }
    }, [showMarketingOptOutModal]);

    return (
        <ModalOverlay
            className="unsubscribe-modal"
            openState={modalActive}
            closeHandler={closeModalHandler}
            content={
                <>
                    <h3>{texts.title}</h3>
                    <p className="text">{texts.text}</p>
                    <div className="button-modal">
                        <ActionButton
                            text={texts.ok}
                            buttonType="ghost"
                            isDisabled={false}
                            clickHandler={closeModalHandler}
                        />
                    </div>
                </>
            }
        />
    );
}

export default AccountDetailsMarketingOptOutModal