import React from "react";
import "./icons.scss";

const magnifier = ({ className, isInverted, width = 16, height = 19 }) => (
  <svg
    className={`svg-icon svg-icon--magnifier ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width={width}
    height={height}
    viewBox="0 0 16 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 18.35L11.21 12.58C12.9 11.3 14 9.28 14 7C14 3.14 10.86 0 7 0C3.14 0 0 3.14 0 7C0 10.86 3.14 14 7 14C8.22 14 9.37 13.68 10.37 13.13L15.23 19L16 18.35ZM1 7C1 3.69 3.69 1 7 1C10.31 1 13 3.69 13 7C13 10.31 10.31 13 7 13C3.69 13 1 10.31 1 7Z" />
  </svg>
);
export default magnifier;
