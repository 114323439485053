import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import globalErrorHandler from "@assets/js/global-error-handler";
import refreshTokenHandler from "@assets/js/refresh-token-handler";
import Cookies from "universal-cookie";
import { STATUS,MYFH_CREATEPRODUCT_STATUS, COOKIES } from "@constants";
import { produce } from "immer";

const getInvoicesUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}invoice/getall`;
const deleteInvoiceUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}invoice/delete/`;
const createInvoice = `${process.env.REACT_APP_MYFRITZHANSENAPI}invoice/createinvoice/`;
const getProductsUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/getall`;
const deleteProductUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/deleteregistration/`;
const createProductUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/createregistration/`;
const updateCommentUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/updateComment/`;
const reportStolenUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/reportstolen/`;
const reportFoundUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/reportfound/`;
const claimByNewOwnerUrl = `${process.env.REACT_APP_MYFRITZHANSENAPI}products/claimbynewowner/`;
const getModelGroupsUrl = `${process.env.REACT_APP_PRODUCTSAPI}product`;
const getModelsUrl = `${process.env.REACT_APP_PRODUCTSAPI}product`;

const cookies = new Cookies();

const store = create(
  devtools(
    (set, get) => ({
      tempBearerToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg5MTc5RDQ5QzQ0ODg2M0RENUIzOTFDQ0EwRkUzNzY0IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2OTgyMzcyMTAsImV4cCI6MTY5ODI0MDgxMCwiaXNzIjoiaHR0cHM6Ly90ZXN0LWlkZW50aXR5LmZyaXR6aGFuc2VuLmNvbSIsImNsaWVudF9pZCI6Im12YyIsInN1YiI6IjgyNzEyYTRlLTBiOWUtNDE4Zi1iODc1LWZhZmRkMGU2OWI3NyIsImF1dGhfdGltZSI6MTY5ODIzNzIxMCwiaWRwIjoibG9jYWwiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjgyNzEyYTRlLTBiOWUtNDE4Zi1iODc1LWZhZmRkMGU2OWI3NyIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJ0bmZAYWxwaGEtc29sdXRpb25zLmRrIiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiJOR1ZQVzVVTkRBQ1NON080RFZUTVZNV0RHNDNKVDVGUSIsIlVzZXJJZCI6Impvc29vTVNOd25BMlpGRWVnRnlCUldCYUpXeEM3NUZlWXY5eXlXWDZUSms9IiwiRW1haWwiOiJ0bmZAYWxwaGEtc29sdXRpb25zLmRrIiwiTmFtZSI6IiIsIlJvbGVRVCI6IkFkbWluIiwiUm9sZVBQIjoiRmluYW5jaWFsIiwiQWNjb3VudE51bWJlciI6IkZBTENLQU5ERlJFRCIsIkN1cnJlbmN5IjoiREtLIiwiRnVybml0dXJlIjoiWWVzIiwiUEsiOiJZZXMiLCJMaWdodG5pbmciOiJZZXMiLCJBY2Nlc3NvcmllcyI6IlllcyIsIk91dGRvb3IiOiJZZXMiLCJTbWFsbEZ1cm5pdHVyZSI6IiIsIlNLSW5kb29yIjoiWWVzIiwiUHJlTGF1bmNoIjoiWWVzIiwiQ29tcGFueSI6ImZoZGsiLCJTdG9wcGVkIjoiTm8iLCJBY2NvdW50TmFtZSI6IlRFU1QgYWNjb3VudCIsIkNvdW50cnkiOiJESyIsIkdyb3VwIjoiUnNkbnRsIiwiTGFuZ3VhZ2UiOiJzdiIsIkRpc2NvdW50R3JvdXAiOiIiLCJqdGkiOiJGNTZEQzAyQjkyOTUzMjBFMUE3NzM5OEJFNkM1NkZBQiIsInNpZCI6IjJFQTJDRjJERDhGM0VBRjU0RkE0QUE0NUEyMThFMTIyIiwiaWF0IjoxNjk4MjM3MjEwLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdfQ.Radmyc4n_EpiW5L8sIBbWfovX6cFxZubbrL2tjYsLTaA05nzTLufWqFNAc-odek0uyJjqT3hxYXTSVX4yPpZ8tl4eibNtdotp512gaf143XyZ43p34ry45jFGbGJfo3tdrTIhB0ex4BprHCFA6xKy6ZUvo6f5jwInYUXrw6WnfBn1iDWr0hqYFUCePIFq-iA3-t-oaKASCiupCPh5yi1v1OgwVA0Bs4gYvWhNAtgAsOKQCIT-OQWa2X99-wLkbUzkfN4tqrk9ZkrT_Uqco2jY8Uy7ISA2VfEPSFskelVuspBpCU3k-XCwPB-cdUV8ubJrs5XxzcK1qgPQ7UAVxemBA",
      loadStatus: STATUS.LOADING_IDLE,
      createProductStatus: MYFH_CREATEPRODUCT_STATUS.IDLE,
      setCreateProductStatus: (data) => set({createProductStatus: data}),
      invoiceList: [],
      setinvoiceList: (data) => set({ invoiceList: data }),

      productsList: [],
      setProductsList: (data) => set({ productsList: data }),

      failedProductsList: [],
      setfailedProductsList: (data) => set({ failedProductsList: data }),

      modelGroups: [],
      setModelGroups: (data) => set({ modelGroups: data }),

      models: [],
      setModels: (data) => set({ models: data }),

      ownedProductsUrl: null,
      setOwnedProductsUrl: (data) => set({ownedProductsUrl: data}),

      activeProductData: null,
      setActiveProductData: (data) => set({ activeProductData: data }),

      showMyFritzHansenModal: false,
      setShowMyFritzHansenModal: (value) => set({ showMyFritzHansenModal: value }),

      showMyFritzHansenInvoiceModal: false,
      setShowMyFritzHansenInvoiceModal: (value) => set({ showMyFritzHansenInvoiceModal: value }),

      showMyFritzHansenInfoModal: false,
      setShowMyFritzHansenInfoModal: (value) => set({ showMyFritzHansenInfoModal: value }),

      showMyFritzHansenRegisterProductModal: false,
      setShowMyFritzHansenRegisterProductModal: (value) => set({ showMyFritzHansenRegisterProductModal: value }),

      showMarketingOptOutModal: false,
      setShowMarketingOptOutModal: (value) => set({ showMarketingOptOutModal: value }),

      productToBeDelted: "",
      setproductToBeDelted: (value) => set({ productToBeDelted: value }),

      invoiceToBeDeleted: "",
      setInvoiceToBeDeleted: (value) => set({ invoiceToBeDeleted: value }),


      fetchModelGroups: async (userAuthenticated, language) => {
        if (userAuthenticated) {
          set({ loadStatus: STATUS.LOADING });
          let fetchUrl = `${getModelGroupsUrl}/${language}/modelgroups`;

          const response = await axios({
            method: "POST",
            url: fetchUrl,
          }).catch((error) => {

            globalErrorHandler(error, "ModelGroups");

          });

          if (response) {

            let list = response?.data?.response?.docs?.filter(x => { return x?.displayName_t })?.sort((a, b) => {
              let fa = a?.displayName_t,
                fb = b?.displayName_t;

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            set({
              loadStatus: STATUS.READY,
              modelGroups: list,
            });
          }
        } else {
          globalErrorHandler("ModelGroups");
        }
      },

      fetchModels: async (userAuthenticated, language, modelGroupId) => {
        if (userAuthenticated) {
          set({ loadStatus: STATUS.LOADING });
          let fetchUrl = `${getModelsUrl}/${language}/model/${modelGroupId}/?fields=displayName_t,modelId_s,imageUrl_s`;

          const response = await axios({
            method: "POST",
            url: fetchUrl,
          }).catch((error) => {

            globalErrorHandler(error, "Models");

          });

          if (response) {

            let list = response?.data?.response?.docs?.filter(x => { return x?.displayName_t })?.sort((a, b) => {
              let fa = a?.displayName_t,
                fb = b?.displayName_t;

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            set({
              loadStatus: STATUS.READY,
              models: list,
            });
          }
        } else {
          globalErrorHandler("Models");
        }
      },

      fetchInvoiceList: async (userAuthenticated) => {

        if (userAuthenticated) {
          set({ loadStatus: STATUS.LOADING });
          let fetchUrl = getInvoicesUrl;

          const response = await axios({
            method: "GET",
            url: fetchUrl,
            headers: {
              Authorization: userAuthenticated ? `Bearer ${cookies.get(COOKIES.jwt.name)}` : "",
              //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            },
          }).catch((error) => {
            if (error?.response?.status === 401) {
              //use refresh-token to get a new jwt token.
              refreshTokenHandler();
            } else {
              globalErrorHandler(error, "INVOICES LIST");
            }
          });

          if (response) {

            let list = response.data;

            set({
              loadStatus: STATUS.READY,
              invoiceList: list,
            });
          }
        } else {
          globalErrorHandler("User is unauthenticated", "INVOICES LIST");
        }
      },
      deleteInvoice: async (userAuthenticated, invoiceId) => {
        set({ loadStatus: STATUS.LOADING });
        let fetchUrl = `${deleteInvoiceUrl}${invoiceId}`;
        const config = {
          url: fetchUrl,
          method: "delete",
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "Invoice DELETE")
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            invoiceList: produce(state.invoiceList, (draft) => {
              const index = draft.findIndex((p) => p.id === invoiceId);
              draft.splice(index, 1);
            }),
          }));
        }
      },
      createInvoice: async (
        userAuthenticated, files
      ) => {
        if (!files)
          return;
        set({ loadStatus: STATUS.LOADING });
        var formData = new FormData();
        Array.prototype.forEach.call(files, function (file) { formData.append('invoiceContents', file); });

        let fetchUrl = `${createInvoice}`;
        const config = {
          method: "post",
          url: fetchUrl,
          data: formData,
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            'Content-Type': 'multipart/form-data'
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "invoice CREATE");
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            invoiceList: produce(state.invoiceList, (draft) => {
              response.data.forEach(element => {
                draft.push(element);
              });
            }),
          }));
        }
      },
      fetchProductsList: async (userAuthenticated) => {

        if (userAuthenticated) {
          set({ loadStatus: STATUS.LOADING });
          let fetchUrl = getProductsUrl;

          const response = await axios({
            method: "GET",
            url: fetchUrl,
            headers: {
              Authorization: userAuthenticated ? `Bearer ${cookies.get(COOKIES.jwt.name)}` : "",
              //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            },
          }).catch((error) => {
            if (error?.response?.status === 401) {
              //use refresh-token to get a new jwt token.
              refreshTokenHandler();
            } else {
              globalErrorHandler(error, "PRODUCTS LIST");
            }
          });

          if (response) {
            var list = [];
            if (response.data) {
              list = response.data;
            }

            set({
              loadStatus: STATUS.READY,
              productsList: list,
            });
          }
        } else {
          globalErrorHandler("User is unauthenticated", "PRODUCTS LIST");
        }
      },
      deleteProduct: async (userAuthenticated, registrationId) => {
        set({ loadStatus: STATUS.LOADING });
        let deleteUrl = `${deleteProductUrl}${registrationId}`;
        const config = {
          url: deleteUrl,
          method: "delete",
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "Invoice DELETE")
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            productsList: produce(state.productsList, (draft) => {
              const index = draft.findIndex((p) => p.registeredProductId === registrationId);
              draft.splice(index, 1);
            }),
          }));
        }
      },
      createProduct: async (
        userAuthenticated, product
      ) => {
        if (!product)
          return;
        set({ createProductStatus: MYFH_CREATEPRODUCT_STATUS.CREATING});
        var formData = new FormData();
        if (product?.Invoices) {
          Array.prototype.forEach.call(product?.Invoices, function (file) { formData.append('Invoices', file); });
        } else {
          formData.append('Invoices', null);
        }
        var modelGroup = get()?.modelGroups?.find(element => element?.modelGroupId_s === product?.ModelGroupName);
        formData.append('ModelGroupName', modelGroup?.displayName_t);
        formData.append('Note', product?.Note);
        formData.append('ModelImageUrl', modelGroup?.imageUrl_s);
        if (product?.ProductIdNumbers) {
          Array.prototype.forEach.call(product?.ProductIdNumbers, function (number) { formData.append('ProductIdentificationNumbers', number); });
        }

        const config = {
          method: "post",
          url: `${createProductUrl}`,
          data: formData,
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            'Content-Type': 'multipart/form-data'
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
            set({ createProductStatus: MYFH_CREATEPRODUCT_STATUS.FAILED});  
          }else{
            set({ createProductStatus: MYFH_CREATEPRODUCT_STATUS.FAILED});      
          }
        });  
        if (response) {
          set((state) => ({
            createProductStatus: MYFH_CREATEPRODUCT_STATUS.COMPLETE,
            failedProductsList: [],
            productsList: produce(state.productsList, (draft) => {
              response?.data?.registeredProducts?.forEach(element => {
                if(draft)draft.push(element);
              });
            }),
            failedProductsList: produce(state.failedProductsList, (draft) => {
              response?.data?.failedIds?.forEach(element => {
                if(draft)draft.push(element);
              });
            }),
          }));
        }  
      },
      updateComment: async( userAuthenticated, registrationId, comment ) => {
        if(!registrationId)
          return;

        const config = {
          method: "post",
          url: `${updateCommentUrl}?productId=${registrationId}`,
          data: JSON.stringify(comment),
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            "Content-Type": "application/json; charset=utf-8"
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "invoice updateComment");
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            productsList: produce(state.productsList, (tempProductList) => {      
              const index = tempProductList.findIndex((p) => p.registeredProductId === registrationId);
              if(index > -1)
                tempProductList[index] = response.data;
            })
          }));
        }
      },
      reportStolen: async( userAuthenticated, id ) => {
        if(!id)
          return;

        const config = {
          method: "post",
          url: `${reportStolenUrl}?id=${id}`
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            "Content-Type": "application/json; charset=utf-8"
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "invoice reportStolen");
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            productsList: produce(state.productsList, (tempProductList) => {
              const index = tempProductList.findIndex((p) => p.id === id);
              if(index > -1)
                tempProductList[index] = response.data;
            }),
            activeProductData: response.data
          }));
        }
      },
      reportFound: async( userAuthenticated, id ) => {
        if(!id)
          return;

        const config = {
          method: "post",
          url: `${reportFoundUrl}?id=${id}`
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            "Content-Type": "application/json; charset=utf-8"
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "invoice reportFound");
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            productsList: produce(state.productsList, (tempProductList) => {
              const index = tempProductList.findIndex((p) => p.id === id);
              if(index > -1)
                tempProductList[index] = response.data;
            }),
            activeProductData: response.data
          }));
        }
      },
      claimByNewOwner: async( userAuthenticated, acceptClaim, registeredProductId ) => {
        if(!registeredProductId)
          return;

        const config = {
          method: "post",
          url: `${claimByNewOwnerUrl}?registeredProductId=${registeredProductId}&allowedToClaim=${acceptClaim}`
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            //Authorization: userAuthenticated ? `Bearer ${get().tempBearerToken}` : "",
            "Content-Type": "application/json; charset=utf-8"
          };
        }

        const response = await axios(config).catch((error) => {
          if (error?.response?.status === 401) {
            //use refresh-token to get a new jwt token.
            refreshTokenHandler();
          } else {
            globalErrorHandler(error, "invoice reportFound");
          }
        });

        if (response) {
          set((state) => ({
            loadStatus: STATUS.READY,
            productsList: produce(state.productsList, (tempProductList) => {
              const index = tempProductList.findIndex((p) => p.registeredProductId === registeredProductId);
              if (index > -1) {
                if (acceptClaim) {
                  tempProductList.splice(index, 1);
                } else {
                  tempProductList[index] = response.data;
                }
              }
            })
          }));
        }
      }
    }),
    "MyFritzHansen"
  )
);

// export const useTempToken = () => [
//   store((store) => store.tempBearerToken),
// ]

export const useInvoiceList = () => [
  store((store) => store.invoiceList),
  store((store) => store.fetchInvoiceList),
];

export const useInvoice = () => [
  store((store) => store.deleteInvoice),
  store((store) => store.createInvoice),
  store((store) => store.invoiceToBeDeleted),
  store((store) => store.setInvoiceToBeDeleted),
];

export const useProductsList = () => [
  store((store) => store.productsList),
  store((store) => store.fetchProductsList),
];

export const useProduct = () => [
  store((store) => store.deleteProduct),
  store((store) => store.createProduct),
  store((store) => store.productToBeDelted),
  store((store) => store.setproductToBeDelted),
];


export const useActiveProductData = () => [
  store((store) => store.activeProductData),
  store((store) => store.setActiveProductData),
];

export const useModelGroups = () => [
  store((store) => store.modelGroups),
  store((store) => store.fetchModelGroups),
];

export const useModels = () => [
  store((store) => store.models),
  store((store) => store.fetchModels),
];

export const useMyFritzHansenProductModal = () => [
  store((store) => store.showMyFritzHansenModal),
  store((store) => store.setShowMyFritzHansenModal),
];

export const useMyFritzHansenInvoiceModal = () => [
  store((store) => store.showMyFritzHansenInvoiceModal),
  store((store) => store.setShowMyFritzHansenInvoiceModal),
];

export const useMyFritzHansenInfoModal = () => [
  store((store) => store.showMyFritzHansenInfoModal),
  store((store) => store.setShowMyFritzHansenInfoModal),
];

export const useMyFritzHansenRegisterProductModal = () => [
  store((store) => store.showMyFritzHansenRegisterProductModal),
  store((store) => store.setShowMyFritzHansenRegisterProductModal),
];

export const useCreateProductStatus = () => [
  store((store) => store.createProductStatus),
  store((store) => store.setCreateProductStatus),
];

export const useOwnedProductsUrl = () => [
  store((store) => store.ownedProductsUrl),
  store((store) => store.setOwnedProductsUrl),
];

export const useFailedProductsList = () => [
  store((store) => store.failedProductsList),
  store((store) => store.setfailedProductsList),
];

export default store;