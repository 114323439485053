export const getField = (name, map) => {
  const r = map.find((x) => x.name === name);
  return (r && r.value) || false;
};

export const placeholderReplace = (str, obj, interpolation = "{$}") => {
  const interpol = interpolation.split("$");
  const rx = new RegExp(`${interpol[0]}(.*?)${interpol[1]}`, "g");
  const placeholders = str.match(rx);

  if (placeholders && placeholders.length) {
    placeholders.forEach((placeholder) => {
      //Placeholder - $Name$
      var phText = placeholder.substring(
        interpol[0].length,
        placeholder.length - interpol[0].length
      );
      if (obj.hasOwnProperty(phText) || (obj.has && obj.has(phText))) {
        str = str.replace(
          placeholder,
          obj.constructor.name === "Map" ? obj.get(phText) : obj[phText]
        );
      }
    });
  }
  return str;
};

export function testWebP() {
  return new Promise((res) => {
    const webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    webP.onload = webP.onerror = () => {
      res(webP.height === 2);
    };
  });
}

export const rafInterval = (callback, delay) => {
  var dateNow = Date.now,
    requestAnimation = window.requestAnimationFrame,
    start = dateNow(),
    stop,
    intervalFunc = function () {
      if (dateNow() - start < delay) {
      } else {
        start += delay;
        callback();
      }
      if (stop) {
      } else {
        requestAnimation(intervalFunc);
      }
    };
  requestAnimation(intervalFunc);
  return {
    clear: function () {
      stop = 1;
    },
  };
};
/** log that only console in develop */
export const log =
  process.env.NODE_ENV === "development"
    ? console.log.bind(window.console, "[FH]")
    : function () {};
