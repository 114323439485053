import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import path from "@assets/js/browser-path";

import shallow from "zustand/shallow";

import useGlobalStore, { useCommerceMarket } from "@store/global";
import { PRICE, INTERNATIONAL } from "@constants";
import { usePLPPrices } from "@store/price";
import useCampaignStore from "@store/campaign";

import { getPLPPrice } from "../ProductListController/plp-helpers"

import { log } from "@assets/js/utils";
import { getDiscountValue } from "@assets/js/campaign-helper";

import "./product-price.scss";

const pricesConfigurationUrl = () =>
  path.join(
    process.env.REACT_APP_PRICESAPI,
    "PriceService",
    "getconfigurationrsp"
  );

const ProductPrice = ({
  price = null,
  priceCurrency = null,
  modelTypeId = null,
  configurationId = null,
  modifier = "",
  taxonomies = [],
  onResolved = null,
  format = PRICE.FROM,
}) => {
  log(
    "ProductPrice | modelTypeId=" +
      modelTypeId +
      ", configurationId=" +
      configurationId
  );

  const { t } = useTranslation();
  const texts = {
    noPriceFound: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_NoPriceFound"
    ),
    from: t("FritzHansen_Project_ConsumersAndProfessionals_General_From"),
  };

  const [priceData, setPriceData] = useState(null);
  const [plpPrices] = usePLPPrices();
  const campaigns = useCampaignStore((state) => state.campaigns);

  const [commerceMarket] = useCommerceMarket();
  const [language] = useGlobalStore((state) => [state.language], shallow);

  //for pdp, product configuration endpoint prices
  useEffect(() => {
    async function loadPrice() {
      var quantity = 1;
      if(commerceMarket?.length && plpPrices && plpPrices[commerceMarket] && plpPrices[commerceMarket][configurationId]){
        if(plpPrices[commerceMarket][configurationId].minimumQuantity > 0){
          quantity = plpPrices[commerceMarket][configurationId].minimumQuantity;
        }
      }

      const postData = [
        {
          configurationId: configurationId,
          marketCountry: commerceMarket?.toLowerCase(),
          quantity: quantity,
        },
      ];

      const response = await axios.post(pricesConfigurationUrl(), postData);

      let price = { unitPrice: 0 };
      if(response.data[0]){
        price = response.data[0];
      }

      let priceWithoutDiscount = 0;
      let discountedPrice = getDiscountValue(campaigns, commerceMarket, taxonomies, price.unitPrice, price.vatRate);
      if (discountedPrice > 0) {
        priceWithoutDiscount = price.unitPrice * quantity;
        price.unitPrice = discountedPrice;
      }

      price.unitPriceWithoutQuantity = price.unitPrice;
      price.unitPrice = price.unitPrice * quantity;
      if (!price.currency) {
        price.currency = "DKK";
      }

      setPriceData(price);

      if (onResolved) onResolved(price, configurationId, priceWithoutDiscount);
    }

    if (configurationId && commerceMarket && (!campaigns || campaigns[commerceMarket])) {
      log(
        "ProductPrice | get configuration endpoint price for " + configurationId
      );
      loadPrice();
    }
    // eslint-disable-next-line
  }, [configurationId, commerceMarket, campaigns /*, onResolved */ ]);

  //for plp, product table modeltype lookup prices
  useEffect(() => {
    if (modelTypeId) {
      log("ProductPrice | price table lookup for " + modelTypeId);

      let price = getPLPPrice(commerceMarket, modelTypeId, plpPrices);
      setPriceData(price);

      if (onResolved) onResolved(price);
    }
  }, [plpPrices, commerceMarket, modelTypeId, onResolved]);

  const getFormattedPrice = () => {
    let returnVal;

    if(!isNaN(price) && priceCurrency){
      if (price === 0) {
        //if fromPrice is 0 return N/A text
        returnVal = texts.noPriceFound;
      } else {
        returnVal = new Intl.NumberFormat(
          commerceMarket.toLowerCase() === INTERNATIONAL
            ? process.env.REACT_APP_COMMERCE_FALLBACK_LOCALE
            : `${language}-${commerceMarket.toUpperCase()}`,
          {
            style: "currency",
            currency: priceCurrency,
            currencyDisplay: "code",
            maximumFractionDigits: 2
          }
        ).format(price);
      }
    }
    else if (priceData) {
      //prepare number formats
      const nfWithoutCurrency = new Intl.NumberFormat(
        commerceMarket.toLowerCase() === INTERNATIONAL
          ? process.env.REACT_APP_COMMERCE_FALLBACK_LOCALE
          : `${language}-${commerceMarket.toUpperCase()}`,
          {
            maximumFractionDigits: 0
          }
      );
      const nfWithCurrency = new Intl.NumberFormat(
        commerceMarket.toLowerCase() === INTERNATIONAL
          ? process.env.REACT_APP_COMMERCE_FALLBACK_LOCALE
          : `${language}-${commerceMarket.toUpperCase()}`,
        {
          style: "currency",
          currency: priceData.currency,
          currencyDisplay: "code",
          maximumFractionDigits: 2
        }
      );

      log(
        "ProductPrice",
        priceData?.unitPrice,
        isNaN(priceData?.fromPrice),
        isNaN(priceData?.toPrice)
      );

      if (!isNaN(priceData?.fromPrice) && !isNaN(priceData?.toPrice)) {
        if (priceData.fromPrice === 0 && priceData.toPrice === 0) {
          //if both prices are 0, immediately use N/A text
          return texts.noPriceFound;
        }

        // ...otherwise prepare formats
        let noCurrencyFromPrice = nfWithoutCurrency.format(
          priceData?.fromPrice
        );
        let withCurrencyFromPrice = nfWithCurrency.format(priceData?.fromPrice);
        let withCurrencyToPrice = nfWithCurrency.format(priceData?.toPrice);

        log(
          "ProductPrice | noCurrencyFromPrice=" +
            noCurrencyFromPrice +
            ", withCurrencyToPrice=" +
            withCurrencyToPrice
        );

        if (format === PRICE.FROM) {
          if (priceData.fromPrice === 0) {
            //if fromPrice is 0 return N/A text
            returnVal = texts.noPriceFound;
          } else {
            //if prices diverge, show fromPrice
            returnVal = `${
              priceData.fromPrice !== priceData.toPrice ? texts.from + " " : ""
            }${withCurrencyFromPrice}`;
          }
        } else if (format === PRICE.EXACT) {
          if (priceData.fromPrice === 0) {
            //if fromPrice is 0 return N/A text
            returnVal = texts.noPriceFound;
          } else {
            returnVal = `${withCurrencyFromPrice}`;
          }
        } else if (format === PRICE.RANGE) {
          returnVal = `${noCurrencyFromPrice}–${withCurrencyToPrice}`;
        }
      } else if (!isNaN(priceData?.unitPrice)) {
        //if no from or to price? try to return a unit price
        if (priceData.unitPrice === 0) {
          //if fromPrice is 0 return N/A text
          returnVal = texts.noPriceFound;
        } else {
          returnVal = nfWithCurrency.format(priceData?.unitPrice);
        }
      } else {
        //if all else fails, return N/A text
        returnVal = texts.noPriceFound;
      }
    } else {
      //if all else fails, return N/A text
      returnVal = texts.noPriceFound;
    }
    log("ProductPrice | returnVal=" + returnVal);
    return returnVal;
  };
  
  return (
    <div className={`product-price ${modifier} animate`}>
      {commerceMarket?.length && plpPrices && plpPrices[commerceMarket] ? (
        <span>{getFormattedPrice()}</span>
      ) : (
        <span>{texts.noPriceFound}</span>
      )}
    </div>
  );
};

export default ProductPrice;
