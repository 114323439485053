import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player'
import { disableScroll, enableScroll } from "@assets/js/scrolllock";

import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE } from "@constants";
import { useVideoType, useVideoId } from "@store/video";
import globalStore from "@store/global";
import classnames from "classnames";

import "./video-player-modal.scss";

const VideoPlayerModal = (props) => {
  const [videoType, setVideoType] = useVideoType();
  const [videoId, setVideoId] = useVideoId();

  const [modalActive, setModalActive] = useState(false);
  const cookieStatisticEnabled = globalStore.getState().cookieStatisticEnabled;
  const { t } = useTranslation();
  const texts = {
    okButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_Ok"),
    noThanksButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_nothanks"),
    updateCookieConsent: t("FritzHansen_Project_ConsumersAndProfessionals_General_UpdateCookieConsent"),
    videoConsentMissing: t("FritzHansen_Project_ConsumersAndProfessionals_Notifications_VideoConsentMissing")
  };

  useEffect(() => {
    if (videoType && videoType !== "" && videoId && videoId !== "") {
      setModalActive(true);
      disableScroll();
    }
  }, [videoId, videoType]);

  const closePlayer = () => {
    setVideoType(null);
    setVideoId(null);
    setModalActive(false);
    enableScroll();
  };

  const openCookieModal = () => {
    closePlayer();
    if(typeof window.CookieConsent.show === 'function'){
      window.CookieConsent.show();
    }
  }

  const classNames = classnames({
    "video-player-modal": cookieStatisticEnabled ? true : false,
    "video-player-consent-placeholder": !cookieStatisticEnabled ? true : false,
  });

  return (
    <ModalOverlay
      className={classNames}
      openState={modalActive}
      closeHandler={closePlayer}
      content={
        <>
          <ActionButton
            customClass="video-player-modal__close"
            buttonType={BUTTONSTYLE.NONE}
            icon="TimesSvg"
            clickHandler={closePlayer}
          />
          {videoType && videoId && (
            <>          
              {cookieStatisticEnabled ?
                <>
                  <h3>{`Show ${videoType} video with ID ${videoId}`}</h3>
                  <ReactPlayer
                    url={`${process.env["REACT_APP_VIDEO_DOMAIN_" + videoType.toUpperCase()]}${videoId}`}
                    playing={true}
                    controls={true}
                    volume={0.5}
                    width={"100%"}
                    height={"100%"}
                    config={{
                      vimeo: {
                        playerOptions: {
                          dnt: true
                        }
                      }
                    }}
                  />
                </> :
                <>
                  <p>{texts.videoConsentMissing}</p>
                  <div className="button-container">
                    <ActionButton
                      buttonType={BUTTONSTYLE.SOLID}
                      isInverted={true}
                      clickHandler={openCookieModal}
                      text={texts.updateCookieConsent}
                    />
                    <ActionButton
                      buttonType={BUTTONSTYLE.SOLID}
                      isInverted={true}
                      clickHandler={closePlayer}
                      text={texts.noThanksButton}
                    />
                  </div>
                </>
              }
            </>)}
        </>
      }
    />
  );
};
VideoPlayerModal.propTypes = {};

export default VideoPlayerModal;
