import create from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";
import axios from "axios";

import path from "@assets/js/browser-path";
import { log } from "@assets/js/utils";
import globalErrorHandler from "@assets/js/global-error-handler";
import { STATUS } from "@constants";

import globalStore from "@store/global";
/* searchCriteriaModel
@method: setActiveSortingCriterion
{
  facetId,
  facetFieldId,
  orCriteria: true|false
}
*/

const createFacets = (data, enabledFacetList) =>
  enabledFacetList.map((facet) => ({
    name: facet.name,
    facetId: facet.id,
    facetFields: data.facets.facetFields[facet.id]
      ? Object.keys(data.facets.facetFields[facet.id]).map((key) => ({
          id: key,
          name: key,
          count: data.facets.facetFields[facet.id][key],
          active: false,
        }))
      : null,
  }));

/**
 *
 * @param {*} data
 * @param {*} facetList
 * @param {*} filters
 */
const updateFacets = (data, facetList, filters) => {
  return facetList.map((item) => {
    if (data.facets.facetFields.hasOwnProperty(item.facetId)) {
      item.facetFields.forEach((field) => {
        field.count = 0;
        field.active = false;
      });
      Object.keys(data.facets.facetFields[item.facetId]).forEach((k) => {
        const index = item.facetFields.findIndex((field) => field.id === k);
        if (index > -1) {
          item.facetFields[index].count =
            data.facets.facetFields[item.facetId][k];

          item.facetFields[index].active =
            filters[item.facetId] &&
            filters[item.facetId].findIndex((item) => item === k) !== -1;
        }
      });
    }
    return item;
  });
};

const createReqParams = ({
  taxonomies,
  facets,
  market,
  modelIds,
  docTypes = ["model*"],
  count = 1000,
  start,
  sort,
}) => {
  log(
    "CREATE REQ PARAMS",
    taxonomies,
    facets,
    market,
    modelIds,
    docTypes,
    count,
    start,
    sort
  );
  const reqParams = {
    count,
    docTypes: docTypes,
    taxonomies: taxonomies,
    trimFields: true,
    sort: sort,
    market: market,
  };
  if (!isNaN(start)) reqParams["start"] = start;
  if (facets) {
    reqParams["orFilters"] = { ...facets };
  }
  if (modelIds?.length) {
    reqParams["modelIds"] = modelIds.map((model) =>
      model.replace("model_", "")
    );
  }
  return reqParams;
};

const fetchProducts = (body, lang) => {
  return axios.post(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "ProductListPage",
      lang,
      "category"
    ),
    body
  );
};

const store = create(
  devtools(
    (set, get) => ({
      // REPLACE WITH API DATA
      status: STATUS.LOADING_IDLE,
      // Filters
      facetList: [],
      pendingFacetList: [],
      paging: {},
      setPaging: (paging) =>
        set((state) => {
          return { paging: Object.assign(state.paging, paging) };
        }),
      // facetList from SC
      enabledFacetList: [],
      setEnabledFacetList: (list) =>
        set({
          enabledFacetList: list.map((item) => ({
            name: item.facetValue.value,
            id: item.facetKey.value,
          })),
        }),
      // current taxonomies will reflect product shown on lise
      currentTaxonomies: null,

      activeFacetId: null,
      setActiveFacetId: (id) => set({ activeFacetId: id }),
      // active visual filters
      activeModels: [],
      setActiveModel: (model) => {
        set((state) => {
          const index = state.activeModels.findIndex((item) => item === model);
          if (index > -1) {
            // remove item
            return {
              activeModels: produce(state.activeModels, (draft) => {
                draft.splice(index, 1);
              }),
            };
          }
          return {
            activeModels: [...state.activeModels, model],
          };
        });
      },

      sortingCriteria: [],
      setSortingCriteria: (crit) => {
        //may be replaced with just a string?
        const sorting = crit.split("|");
        if (sorting[0] === "weight" || sorting[0] === "price") {
          set((state) => ({
            productList: produce(state.productList, (draft) =>
              draft.sort((a, b) => {
                const valA = parseFloat(a[sorting[0]]);
                const valB = parseFloat(b[sorting[0]]);
                return sorting[1] === "desc" ? valB - valA : valA - valB;
              })
            ),
          }));
          return;
        }

        set((state) => {
          return {
            productList: produce(state.productList, (draft) =>
              draft.sort((a, b) => {
                const valA = a[sorting[0]] ? a[sorting[0]].toUpperCase() : "";
                const valB = b[sorting[0]] ? b[sorting[0]].toUpperCase() : "";
                if (valA > valB) {
                  return 1;
                }
                if (valA < valB) {
                  return -1;
                }
                return 0;
              })
            ),
          };
        });
      },

      savePriceInfo: (id, price) => {
        set((state) => {
          return {
            productList: produce(state.productList, (draft) => {
              const index = draft.findIndex((item) => item.id === id);
              draft[index].price = price;
            }),
          };
        });
      },

      currentFilters: {},
      setCurrentFilters: (crit, add) => {
        let tempCurrentFilters = {};
        if (add) {
          tempCurrentFilters = produce(get().currentFilters, (draft) => {
            if (draft.hasOwnProperty(crit.facetId)) {
              draft[crit.facetId].push(crit.facetFieldId);
            } else {
              draft[crit.facetId] = [crit.facetFieldId];
            }
          })
        } else {
          tempCurrentFilters = produce(get().currentFilters, (draft) => {
            const index = draft[crit.facetId].findIndex(
              (item) => crit.facetFieldId === item
            );
            if (index !== -1) draft[crit.facetId].splice(index, 1);
            if (!draft[crit.facetId].length) {
              delete draft[crit.facetId];
            }
          })
        }
        set({ currentFilters: tempCurrentFilters });
        return tempCurrentFilters;
      },

      // container to cache product list results
      pendingProductList: [],
      productList: [],
      getProductList: async (payload = {}) => {
        log({ payload });
        const { taxonomies, appliedFilters, models } = payload;

        const market = globalStore.getState().commerceMarket;
        const getFacetsResult = Object.keys(appliedFilters).length > 0;
        // const facets = appliedFilters || get().currentFilters;
        const paging = get().paging;
        const sc_lang = globalStore.getState().sc_lang;
        if (!taxonomies) return;

        set({ status: STATUS.LOADING });
        try {
          const facetsParams = createReqParams({
            count: 1000,
            docTypes: ["modeltype", "modelvariationgroup", "model"],
            taxonomies: taxonomies,
            trimFields: true,
            market: market,
          });

          // load first respnse to get all facets
          const facetsResponse = await fetchProducts(facetsParams, sc_lang);

          log({ appliedFilters });
          let parsedFacets = createFacets(
            facetsResponse.data,
            get().enabledFacetList
          );

          let facetKeys = appliedFilters;
          log({ facetKeys });

          let parsedAppliedFilters = {};
          for (const [key, value] of Object.entries(appliedFilters)) {
            log(key, value);
            let foundFacet = parsedFacets.find((item) => key === item.facetId);
            if (foundFacet) parsedAppliedFilters[key] = value;
          }
          log({ parsedAppliedFilters });

          if (getFacetsResult) {
            const filteredParams = createReqParams({
              taxonomies: taxonomies,
              facets: parsedAppliedFilters,
              modelIds: models,
              docTypes: ["modeltype", "modelvariationgroup"],
              count: paging.count,
              market: market,
              start: 0,
            });
            // if filters are applied from url, include in call
            const filteredTilesResponse = await fetchProducts(
              filteredParams,
              sc_lang
            );
            set((state) => {
              return {
                currentTaxonomies: taxonomies,
                cachedData: facetsResponse.data,
                models: facetsResponse.data.models.filter(
                  (m) => m.displayName_t
                ),
                productList: filteredTilesResponse.data.productTiles.map(
                  (item, index) => ({
                    ...item,
                    weight: index,
                  })
                ),
                currentFilters: appliedFilters,
                facetList: updateFacets(
                  filteredTilesResponse.data,
                  parsedFacets,
                  appliedFilters
                ),
                count: filteredTilesResponse.data.facets.count,
                loading: STATUS.LOADING_IDLE,
              };
            });
          } else {
            const unfilteredParams = createReqParams({
              taxonomies: taxonomies,
              modelIds: models,
              docTypes: ["modeltype", "modelvariationgroup"],
              count: paging.count,
              market: market,
              start: 0,
            });

            /*
              sorting can be implemented with ex
              sort: "cp_chairs_sort_i desc"
              sort: "fullName_t asc"
              sort: "displayName_t asc"
              */

            // get unfiltered tiles
            const unfilteredTilesResponse = await fetchProducts(
              unfilteredParams,
              sc_lang
            );
            set((state) => {
              return {
                currentTaxonomies: taxonomies,
                currentFilters: appliedFilters,

                models: facetsResponse.data.models.filter(
                  (m) => m.displayName_t
                ),
                facetList: parsedFacets,
                cachedData: facetsResponse.data,

                productList: unfilteredTilesResponse.data.productTiles.map(
                  (item, index) => ({
                    ...item,
                    weight: index,
                  })
                ),
                count: unfilteredTilesResponse.data.facets.count,

                loading: STATUS.LOADING_IDLE,
              };
            });
          }
        } catch (err) {
          // TODO Handle error in som kind of way
          console.error("SERVER ERROR", err);
          set({ status: STATUS.LOADING_IDLE });
        }
      },
      getAppliedFilters: async () => {
        // filter pending fetch
        const taxonomies = get().currentTaxonomies;
        const facets = get().currentFilters;
        const modelIds = get().activeModels;
        const sc_lang = globalStore.getState().sc_lang;
        const market = globalStore.getState().commerceMarket;
        if (!taxonomies) return;
        const reqParams = createReqParams({
          taxonomies,
          facets,
          modelIds,
          docTypes: ["model*"],
          count: 1000,
          market: market,
        });
        // log("PLP | FILTER | reqParams", reqParams);
        try {
          const response = await fetchProducts(reqParams, sc_lang);

          set((state) => ({
            // pendingProductList: response.data.productTiles.map(
            //   (item, index) => ({ ...item, weight: index })
            // ),
            facetList: updateFacets(
              response.data,
              state.facetList,
              state.currentFilters
            ),
            count: response.data.facets.count,
            loading: STATUS.LOADING_IDLE,
          }));
        } catch (err) {
          // TODO Handle error in som kind of way
          console.error("SERVER ERROR", err);
          set({ status: STATUS.LOADING_IDLE });
        }
      },
      refreshProductList: async (resetList) => {
        // model search etc.
        let paging = get().paging;
        const taxonomies = get().currentTaxonomies;
        const facets = get().currentFilters;
        const modelIds = get().activeModels;
        const market = globalStore.getState().commerceMarket;

        const sc_lang = globalStore.getState().sc_lang;
        if (!taxonomies) return;

        if (!paging && resetList) {
          paging.start = 0;
          paging.count = 15;
          paging.numberOfProducts = 15;
        }

        const reqParams = createReqParams({
          taxonomies,
          facets,
          modelIds,
          docTypes: ["modeltype"],
          count: paging.count - paging.start,
          start: paging.start,
          market: market,
        });
        log("PLP | REFRESH | reqParams", reqParams);
        log("paging", paging);
        try {
          const response = await fetchProducts(reqParams, sc_lang);

          let newTiles = response.data.productTiles.map((item, index) => ({
            ...item,
            weight: index,
          }));

          set((state) => ({
            productList: resetList
              ? newTiles
              : [...get().productList, ...newTiles],
            facetList: updateFacets(
              response.data,
              state.facetList,
              state.currentFilters
            ),
            count: response.data.facets.count,
            loading: STATUS.LOADING_IDLE,
            paging,
          }));
        } catch (err) {
          // TODO Handle error in som kind of way

          globalErrorHandler(err, "REFRESH PLP ERROR");
          console.error("SERVER ERROR", err);
          set({ status: STATUS.LOADING_IDLE });
        }
      },
      saveSearch: () => {
        set((state) => {
          return {
            productList: state.pendingProductList,
          };
        });
      },
      clearSearch: async () => {
        const taxonomies = get().currentTaxonomies;
        const modelIds = get().activeModels;
        const paging = get().paging;
        const market = globalStore.getState().commerceMarket;

        const sc_lang = globalStore.getState().sc_lang;
        const unfilteredParams = createReqParams({
          taxonomies: taxonomies,
          modelIds: modelIds,
          docTypes: ["modeltype", "modelvariationgroup"],
          count: paging.count,
          market: market,
          start: 0,
        });
        // get unfiltered tiles
        const unfilteredTilesResponse = await fetchProducts(
          unfilteredParams,
          sc_lang
        );

        set((state) => {
          return {
            facetList: createFacets(state.cachedData, state.enabledFacetList),
            currentFilters: {},
            currentTaxonomies: taxonomies,
            productList: unfilteredTilesResponse.data.productTiles.map(
              (item, index) => ({
                ...item,
                weight: index,
              })
            ),
            count: unfilteredTilesResponse.data.facets.count,
            loading: STATUS.LOADING_IDLE,
          };
        });
      },
    }),
    "PLP"
  )
);

// Snippet Public
// EnabledFacitList
export const useEnabledFacetList = () => [
  store((store) => store.enabledFacetList),
  store((store) => store.setEnabledFacetList),
];

// ProductList
export const useProductList = () => [
  store((store) => store.productList),
  store((store) => store.getProductList),
];
// all ProductList freches
export const useGetProducts = () => ({
  getProductList: store((store) => store.getProductList),
  getAppliedFilters: store((store) => store.getAppliedFilters),
  refreshProductList: store((store) => store.refreshProductList),
});

// FacitList
export const useFacetList = () => [
  store((store) => store.facetList),
  store((store) => store.setFacetList),
];

// ActiveFacetId
export const useActiveFacetId = () => [
  store((store) => store.activeFacetId),
  store((store) => store.setActiveFacetId),
];

// SortingCriteria
export const useSortingCriteria = () => [
  store((store) => store.sortingCriteria),
  store((store) => store.setSortingCriteria),
];

// Paging
export const usePaging = () => [
  store((store) => store.paging),
  store((store) => store.setPaging),
];

export default store;
