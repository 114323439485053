import React from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useHistory } from 'react-router-dom';
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import { ArrowSmallSvg, MagnifierSvg } from "@assets/js/icons";
import Collapsible from "../../Collapsible/";
import useHeaderStore from "@store/header";

import MobileMegaMenuFooter from "./MobileMegaMenuFooter/index";
import NotificationGeolocation from "../../notifications/NotificationGeolocation";
import useGlobalStore from "@store/global";
import shallow from "zustand/shallow";

import { useActiveMobileRootItem, useMenuOverlayInstant } from "@store/header";

import getUniqueId from "@assets/js/get-unique-id";
import MenuLink from "../MenuLink";
import SubMenuItem from "./SubMenuItem";
import { getField } from "@assets/js/utils";
import { OVERLAY, NOTIFICATION } from "@assets/js/constants";
import MyFritzMenu from "../MyFritzMenu";

// d-n-vp5
const menuMenuQuery = loader("./megaMenuQuery.graphql");

const containerMotion = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0 },
  },
};

const showSubMenu = (item) => {
  return item.field.targetItems?.length > 0;
};
function MobileMegaMenu({ menu }) {
  const { t } = useTranslation();
  const texts = {
    search: t("FritzHansen_Project_ConsumersAndProfessionals_Search_MobileMenuSearch"),
  };
  
  const currentMegaMenuCategoryItem = useHeaderStore(
    (store) => store.currentMegaMenuCategoryItem
  );

  const setCurrentMegaMenuCategoryItem = useHeaderStore(
    (store) => store.setCurrentMegaMenuCategoryItem
  );

  const setMobileNavigationActive = useHeaderStore(
    (store) => store.setMobileNavigationActive
  );

  const [, setMenuOverlayInstant] = useMenuOverlayInstant();
  const history = useHistory();

  const activateSearchHandler = () => {  
    if (menu?.searchIconLink?.jss?.value?.href) {
      setMenuOverlayActive(OVERLAY.NONE);
      setMobileNavigationActive(false);
      history.push(menu?.searchIconLink?.jss?.value?.href);
    }
    else {
      setMenuOverlayActive(OVERLAY.SEARCH);
      setMenuOverlayInstant(true);
    } 
  };

  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );

  const [setShowNotificationGeolocation] = useGlobalStore(
    (state) => [state.setShowNotificationGeolocation],
    shallow
  );

  return (
    <>
      <div className="mega-menu__container">
        <div className="mega-menu__mobile-search-item" onClick={activateSearchHandler}>
          <MagnifierSvg /><span>{texts.search}</span>
        </div>

        {menu.field.targetItems && !currentMegaMenuCategoryItem && (
          <AnimatePresence>
            <motion.nav
              key={getUniqueId()}
              className="mega-menu__inner"
              variants={containerMotion}
              initial="initial"
              animate={!currentMegaMenuCategoryItem ? "animate" : "exit"}
            >
              <ul className="mega-menu__mobile-root">
                {menu.field.targetItems.map((item) => (
                  <li key={item.id} className="mega-menu__mobile-root-item">
                    <MegaMenuMobileListRootItem
                      item={item}
                      className="mega-menu__mobile-root"
                    />
                  </li>
                ))}
              </ul>
            </motion.nav>
          </AnimatePresence>
        )}
        {currentMegaMenuCategoryItem && (
          <>
            <button
              className="mega-menu__back-link"
              onClick={() => setCurrentMegaMenuCategoryItem(null)}
            >
              <ArrowSmallSvg /> Back
            </button>
            <motion.nav
              key={getUniqueId()}
              className="mega-menu__inner"
              variants={containerMotion}
              initial="initial"
              animate={currentMegaMenuCategoryItem ? "animate" : "exit"}
            >
              <MegaMenuMobileListContainer
                container={true}
                menuId={currentMegaMenuCategoryItem}
              />
            </motion.nav>
          </>
        )}
      </div>
      <div className="mega-menu__footer">
        <div className="mega-menu__container">
          <MyFritzMenu type="footer" />
          <MobileMegaMenuFooter
            secondaryMenu={menu.secondaryMenu}
            socialMenu={menu.socialMenu}
            clickHandler={() =>
              setShowNotificationGeolocation(NOTIFICATION.GEO_MENUBOTTOM)
            }
          />
        </div>
      </div>
      <NotificationGeolocation position={NOTIFICATION.GEO_MENUBOTTOM} />
    </>
  );
}

const MegaMenuMobileListContainer = ({ menuId }) => {
  const { data, loading } = useQuery(menuMenuQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });
  return <>{!loading && data && <MegaMenuMobileList item={data.subMenu} />}</>;
};

// level 1 and 2
const MegaMenuMobileList = ({ item, loading = false }) => {
  return (
    <>
      {!loading && item && (
        <motion.ul
          variants={containerMotion}
          exit="initial"
          className="mega-menu__list"
        >
          {item.field.targetItems.map((item, index) => (
            <SubMenuItem
              key={`mobileListItem${index}`}
              num={index}
              item={item}
              tag="li"
            />
          ))}
        </motion.ul>
      )}
    </>
  );
};

// root level
const MegaMenuMobileListRootItem = ({ item, loading = false }) => {
  const [activeMenuItems, setActiveMenuItems] = useActiveMobileRootItem();

  return (
    <>
      {!loading && item && (
        <>
          {!showSubMenu(item) && (
            <MenuLink item={item} className="mega-menu__button" />
          )}

          {showSubMenu(item) && (
            <Collapsible
              triggerClassName="mega-menu__button"
              preExpanded={activeMenuItems.findIndex((i) => item.id === i) > -1}
              triggerComponent={
                <>
                  <h4>{getField("Title", item.fields)}</h4>
                  <ArrowSmallSvg />
                </>
              }
              toggleHandler={() => setActiveMenuItems(item.id)}
              contentComponent={<MegaMenuMobileList item={item} />}
            />
          )}
        </>
      )}
    </>
  );
};

export default MobileMegaMenu;
