import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { disableScroll, enableScroll } from "@assets/js/scrolllock";
import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE } from "@constants";

import { placeholderReplace } from "@assets/js/utils";
import useBasketStore from "@store/basket";
import { useActiveUniqueMaterial } from "@store/pdp";
import { log } from "@assets/js/utils";

import "./unique-material-unavailable-modal.scss";

const UniqueMaterialUnavailableModal = () => {
  const { t } = useTranslation();

  const [activeUniqueMaterial] = useActiveUniqueMaterial();

  const texts = {
    uniqueMaterialUnavailable: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_UniqueMaterialUnavailable"
    ),
    okButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_Ok"),
  };

  const uniqueMaterialUnavailable = useBasketStore(
    (store) => store.uniqueMaterialUnavailable
  );
  const setUniqueMaterialUnavailable = useBasketStore(
    (store) => store.setUniqueMaterialUnavailable
  );
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (uniqueMaterialUnavailable && document) {
      setModalActive(true);
      disableScroll();
    }
  }, [uniqueMaterialUnavailable]);

  const okButtonHandler = () => {
    setModalActive(false);
    setUniqueMaterialUnavailable(null);
    enableScroll();
  };

  const uniqueMaterial = activeUniqueMaterial?.id ?? uniqueMaterialUnavailable;
  log("UniqueMaterialUnavailableModal.useActiveUniqueMaterial", uniqueMaterial);
  return (
    <ModalOverlay
      className="unique-material-uavailable-modal"
      openState={modalActive}
      closeHandler={okButtonHandler}
      content={
        <>
          <p>
            {placeholderReplace(texts.uniqueMaterialUnavailable, { uniqueMaterial })}
          </p>

          <ActionButton
            buttonType={BUTTONSTYLE.SOLID}
            isInverted={true}
            clickHandler={okButtonHandler}
            text={texts.okButton}
          />
        </>
      }
    />
  );
};

export default UniqueMaterialUnavailableModal;
