import { configurePersist } from "zustand-persist";
// https://github.com/pmndrs/zustand/pull/246/commits/a187e3812f4003a604a9be08ecc656a93752f171
// create minimal dummy storage for server side rendering
const dummyStorageApi = {
  getItem: () => null,
  setItem: () => {},
}
const { persist, purge } = (typeof localStorage === 'undefined') ?
configurePersist({
  storage: dummyStorageApi,
  root: "FH",
})
:
 configurePersist({
  storage: localStorage,
  root: "FH",
});

export default persist;
export { purge };
