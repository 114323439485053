import React from "react";
import "./icons.scss";

const arrowSquare = ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--arrow-square ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="22"
    height="15"
    viewBox="0 0 22 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.28 11h10V1h-20v10h5.5" fill="none" stroke="black" />
    <path
      d="M14.065 14.57l-3.784-3.785L14.064 7"
      fill="none"
      stroke="#000"
      strokeLinejoin="bevel"
    />
  </svg>
);
export default arrowSquare;
