import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import dataLayerPush from "@assets/js/gtm";

import { useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import useGlobalStore, { useUserAuthenticated } from "@store/global";
import { OVERLAY, COOKIES } from "@constants";
import { ArrowSmallSvg } from "@assets/js/icons";
import loginWindow from "@assets/js/login-window";
import { log } from "@assets/js/utils";
import useHeaderStore from "@store/header";
import "./login-link.scss";

const loginLinkQuery = loader("./loginlink.graphql");

const LoginLink = ({ popupUrl, linkClass, linkText, action, showArrow = true }) => {
  const { data, loading } = useQuery(loginLinkQuery, {
    variables: { menuPath: process.env.REACT_APP_QUERY_VARIABLES_MYFRITZ_ID },
    errorPolicy: "all",
  });
  const history = useHistory();
  // const { t } = useTranslation();
  const cookies = new Cookies();
  const setMenuOverlayActive = useHeaderStore(
    (state) => state.setMenuOverlayActive
  );
  const [userAuthenticated, setUserAuthenticated] = useUserAuthenticated();
  const setUserKey = useGlobalStore((state) => state.setUserKey);
  const [redirectLink, setRedirectLink] = useState(null);
  useEffect(() => {
    if (data?.item?.loginSuccessRedirectLink?.link?.value.href) {
      setRedirectLink(data.item?.loginSuccessRedirectLink?.link?.value.href);
    }
  }, [data]);
  const handleLoginClick = (e) => {
    e.preventDefault();

    async function completeLogout() {
      const foundUserKey = cookies.get(COOKIES.user.name);
      setUserAuthenticated(false, null);
      if (foundUserKey) {
        setUserKey(foundUserKey);
      }

      const trackingUserId = cookies.get(COOKIES.trackingUserId.name);
      dataLayerPush({
        event: 'trackLogin',
        loginAction: 'log out',
        userId: `'${trackingUserId}'`
      });

      window.location = process.env.REACT_APP_IDENTITY_LOGOUT;
      // const response = await axios.get(process.env.REACT_APP_IDENTITY_LOGOUT).catch((error) => {
      //   setUserAuthenticated(true, null);
      //   globalErrorHandler(error, "LOGOUT");
      // });
    }

    async function completeLogin(d) {
      try {
        const jwtCookie = await loginWindow({
          url: popupUrl,
          title: "xtf",
          w: "470",
          h: "675",
        });
        setUserAuthenticated(true, jwtCookie);
        if (redirectLink) {
          //   setMenuOverlayActive(OVERLAY.NONE);
          history.push(redirectLink);
        }
      } catch (err) {
        console.warn(err);
      }
    }

    if (action === OVERLAY.LOGIN && !userAuthenticated) {
      // openPopup(popupUrl, "xtf", "470", "675");
      completeLogin();
    }
    if (action === OVERLAY.LOGOUT && userAuthenticated) {
      completeLogout(data);
    }
  };

  return (
    <>
      {!loading && (
        <button className={linkClass} onClick={handleLoginClick}>
          {linkText}
          {showArrow && <ArrowSmallSvg />}
        </button>
      )}
    </>
  );
};
LoginLink.propTypes = {
  action: PropTypes.string,
  popupUrl: PropTypes.string,
  linkText: PropTypes.string,
  linkClass: PropTypes.string,
};

export default LoginLink;
