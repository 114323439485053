import TagManager from "react-gtm-module";
import useGlobalStore from "@store/global";
import textTile from "../storybook/text-tile";
import formatPriceToInternational from "@assets/js/get-gtm-formatted-price";

const gtm = (obj) => {
  if (useGlobalStore.getState().cookieStatisticEnabled || true) {
    TagManager.dataLayer({ dataLayer: obj });
  }
};
/**
 *
 * @param {*} event
 * @param {*} ecommerce
 */
export const dataLayerCommercePush = (event = null, ecommerce) => {
  if(useGlobalStore.getState().cookieStatisticEnabled || true){
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event,
        ecommerce,
      },
    });
  }
};

/**
 *
 * @param {Obj} params currencyCode|stepNum|basketLineItems
 * @returns GTM tracking event signature
 */
export const checkoutSignature = ({
  currencyCode,
  stepNum,
  basketLineItems
}, priceFormatting) => ({
  currencyCode,
  checkout: {
    actionField: {
      step: stepNum + 1,
    },
    products: basketLineItems.map((p) =>
      Object.assign(
        {},
        {
          name: p.ModelGroupName,
          id: p.FriendlyName,
          price: priceFormatting ? formatPriceToInternational(priceFormatting(p.Price)) : '',
          brand: p.DesignerName,
          category: `${p.ModelTypeGroup}/${p.ModelTypeProductType}`,
          variant: p.ConfiguratorModelTypeDefinitionName,
          quantity: p.Quantity,
        },
        ...(p.Materials?.length
          ? p.Materials.map((mat, index) => ({
              [`dimension${index + 1}`]: mat.Id,
            }))
          : [{ dimension1: "not set" }])
      )
    ),
  },
});
/**
 *
 * @param {*} productData
 * @returns GTM product signature
 */
export const productSignature = (productData, price) =>
  Object.assign(
    {},
    {
      name: productData?.dataLayerProductName,
      id: productData?.model?.configurationId,
      price: formatPriceToInternational(price),
      brand: productData?.dataLayerProductBrand,
      category: productData?.dataLayerProductCategory,
      variant: productData?.dataLayerProductVariant,
    },
    ...(productData?.materials
      ? productData?.materials.map((mat, index) => ({
          [`dimension${index + 1}`]: mat.activeMaterial.materialId,
        }))
      : [{ dimension1: "not set" }])
  );

export default gtm;
