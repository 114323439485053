import React from "react";
import "./icons.scss";
const plus = ({ className, isInverted, width = 9, height = 9 }) => (
  <svg
    className={`svg-icon svg-icon--plus ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width={width}
    height={height}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.125 0 h0.75 v9 h-0.75 z" />
    <path d="M9 4.125 v0.75 H0 v-0.75 z" />
  </svg>
);
export default plus;
