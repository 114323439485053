import create from "zustand";
import { devtools } from "zustand/middleware";

const store = create(
  devtools((set) => ({
    showImageModal: false,
    setShowImageModal: (value) => set({ showImageModal: value }),

    imageModalContent: null,
    setImageModalContent: (value) => set({ imageModalContent: value }),

    showQRCodeModal: false,
    setShowQRCodeModal: (value) => set({ showQRCodeModal: value })
  }),
    "Content"
  )
);

export default store;
