import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enableScroll, disableScroll } from "@assets/js/scrolllock";
import ModalOverlay from "../ModalOverlay";
import ActionButton from "../ActionButton";
import { log } from "@assets/js/utils"

import { BUTTONSTYLE } from "@constants";

import useContentStore from "@store/content";

import "./image-modal.scss";

const ImageModal = (props) => {
    const { t } = useTranslation();

    const texts = {
        quantitySetToMaxAvailable: t(
            "FritzHansen_Project_ConsumersAndProfessionals_Cart_QuantitySetToMaxAvailable"
        ),
        okButton: t("FritzHansen_Project_ConsumersAndProfessionals_General_Ok"),
    };

    const [showImageModal, setShowImageModal, imageModalContent, setImageModalContent] = useContentStore(
        (store) => [store.showImageModal, store.setShowImageModal, store.imageModalContent, store.setImageModalContent]
    );

    const [modalActive, setModalActive] = useState(false);

    const okButtonHandler = () => {
        setShowImageModal(false);
        setImageModalContent(null);
        setModalActive(false);
        enableScroll();
    };

    useEffect(() => {
        if (showImageModal && document) {
            setModalActive(true);
            disableScroll();
        }
    }, [showImageModal]);

    return (
        <ModalOverlay
            className="image-modal"
            openState={modalActive}
            closeHandler={okButtonHandler}
            content={
                <p><img src={imageModalContent} /></p>
            }
        />
    );
};

export default ImageModal;
