import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";

import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import useHeaderStore from "@store/header";
import { SITECORE_TYPE } from "@constants";
import getUniqueId from "@assets/js/get-unique-id";
import { getField } from "@assets/js/utils";
import SubMenuItem from "./SubMenuItem";

import {
  itemMotion,
  itemMotionDelayed,
  containerMotion,
  containerMotionDelayed,
} from "@assets/js/animation-variations";

const menuLoader = loader("./megaMenuQuery.graphql");

function DesktopMegaMenu({ menu }) {
  console.log("menu", menu);
  const currentMegaMenuCategoryItem = useHeaderStore(
    (store) => store.currentMegaMenuCategoryItem
  );
  const currentMainMenuItem = useHeaderStore(
    (store) => store.currentMainMenuItem
  );

  const getMenuItems = (menu, currentMainMenuItem) => {
    const menuItems = menu.field.targetItems.find(
      (x) => x.id === currentMainMenuItem
    );
    if (
      menuItems.field.targetItems.length &&
      menuItems.field.targetItems[0].__typename === SITECORE_TYPE.MENU_ITEM
    ) {
      return [menuItems];
    }
    return menuItems.field.targetItems;
  };

  return (
    <>
      <div className="mega-menu__container d-f-vp5">
        {/* LEVEL 1 */}
        {currentMainMenuItem && !currentMegaMenuCategoryItem && (
          <motion.nav
            key={getUniqueId()}
            className="mega-menu__inner"
            variants={containerMotionDelayed}
            initial="initial"
            animate={currentMegaMenuCategoryItem ? "exit" : "animate"}
          >
            {getMenuItems(menu, currentMainMenuItem).map((item, index) => {
              return (
                <motion.div
                  className="mega-menu__list"
                  variants={itemMotionDelayed}
                  key={item.id}
                >
                  <MegaMenuDesktopList item={item} />
                </motion.div>
              );
            })}
          </motion.nav>
        )}

        {currentMegaMenuCategoryItem && (
          <motion.nav
            className="mega-menu__inner"
            variants={containerMotion}
            initial="initial"
            animate={currentMegaMenuCategoryItem ? "animate" : "exit"}
          >
            <motion.div className="mega-menu__list" variants={itemMotion}>
              <DesktopListContainer menuId={currentMegaMenuCategoryItem} />
            </motion.div>
          </motion.nav>
        )}
      </div>
    </>
  );
}

const DesktopListContainer = ({ menuId }) => {
  const { data, loading } = useQuery(menuLoader, {
    variables: { menuId },
    errorPolicy: "all",
  });
  return (
    <>
      {data?.subMenu && (
        <AnimatePresence>
          {!loading && data.subMenu.field && (
            <MegaMenuDesktopList item={data.subMenu} />
          )}
        </AnimatePresence>
      )}
    </>
  );
};

const MegaMenuDesktopList = (props) => {
  const names = props?.item?.field?.value?.split("|");
  const [itemIndex, setItemIndex] = useState(-1);

  const clearMenuOverlayMediaQueue = useHeaderStore(
    (store) => store.clearMenuOverlayMediaQueue
  );

  const mouseEnterHandler = (index) => {
    setItemIndex(1);
  };

  const mouseLeaveHandler = () => {
    setItemIndex(-1);

    clearMenuOverlayMediaQueue();
  };

  const listClasses = classnames({
    "mega-menu__list": true,
    "is-active": itemIndex > -1,
  });

  return (
    <motion.div
      variants={containerMotion}
      initial="initial"
      animate="animate"
      className={listClasses}
      key={props.item.id}
      onMouseEnter={() => {
        mouseEnterHandler();
      }}
      onMouseLeave={() => {
        mouseLeaveHandler();
      }}
    >
      {props.item.__typename === SITECORE_TYPE.MENU_LABEL &&
        getField("Title", props.item.fields) && (
          <motion.h4 key={`${props.item.id}Title`} variants={itemMotion}>
            {getField("Title", props.item.fields) || props.item.name}
          </motion.h4>
        )}

      {props.item.field.value && (
        <ul key={props.item.id}>
          {names.map((subItem, index) => {
            return (
              <motion.li variants={itemMotion} key={subItem}>
                <SubMenuItemQuery itemNum={index} menuId={subItem} />
              </motion.li>
            );
          })}
        </ul>
      )}
      {props.item.field.targetItems && (
        <ul key={props.item.id}>
          {props.item.field.targetItems &&
            props.item.field.targetItems.map((item, index) => {
              return (
                <li key={item.id}>
                  <SubMenuItem item={item} />
                </li>
              );
            })}
        </ul>
      )}
    </motion.div>
  );
};

const SubMenuItemQuery = ({ menuId, itemNum }) => {
  const { data, loading } = useQuery(menuLoader, {
    variables: { menuId },
    errorPolicy: "all",
  });

  return (
    <>
      {!loading && data && (
        <SubMenuItem num={itemNum} item={data.subMenu} loading={loading} />
      )}
    </>
  );
};

export default DesktopMegaMenu;
